import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { RouteNames } from './names';
import { useDispatch } from 'react-redux';
import { updatePageTitle } from '../redux/page';
import { isMatchRoute } from '../utils/router';

export default function UpdatePageTitle() {
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    RouteNames.forEach(routeName => {
      if (isMatchRoute(routeName.route, pathname)) {
        dispatch(updatePageTitle(routeName.title));
      }
    });
  }, [dispatch, pathname]);

  return null;
}
