import React, {useState, useEffect} from 'react';
import moment from 'moment';
import {useQuery} from '@apollo/react-hooks';
import {useMutation} from "react-apollo";
import {useHistory} from 'react-router-dom';

import {LIST_VENDAS} from '../../graphql/queryes';
import {CRIAR_VENDA} from "../../graphql/mutations";

import {
  ArrowDropUp as ArrowDropUpIcon,
  ArrowDropDown as ArrowDropDownIcon,
  ArrowBack,
} from "@material-ui/icons";
import {IconButton, Grid} from '@material-ui/core';

import Box from '../../components/box';
import Search from './components/search';
import List from '../../components/list/list';
import Number from '../../utils/number';
import ButtonFloat from '../../components/button/ButtonFloat';
import {VISUALIZAR_VENDA} from '../../router/names';
import string from "../../utils/string";
import canCriarVenda from '../../services/authority-service/can-criar-venda';
import ModalSlider from "../../components/modal-slider/modal-slider";
import Plano from "./components/criar-venda/plano";
import StepperCriarVenda from "./components/criar-venda/stepperCriarVenda";
import Notification from "../../components/notification/Notification";
import Flex from "../../components/flex";
import styled from "@emotion/styled";
import Colors from "../../theme/Colors";

const notificationDefault = {
  variant: '',
  message: '',
  isOpen: false,
};

const ListVendas = () => {
  const history = useHistory();
  const [searchDTO, setSearchDTO] = useState({ });

  const [isCriarVendaOpen, setIsCriarVendaOpen] = useState(false);

  const [order, setOrder] = useState({ field: 'dataLancamento', asc: false });

  const orderDataLancamento = order.field === 'dataLancamento';
  const orderDataVencimento = order.field === 'unidade.dataVencimento';

  const [criarVenda] = useMutation(CRIAR_VENDA);

  const [notification, setNotification] = useState({
    ...notificationDefault,
  });

  const [pageableDTO, setPageableDTO] = useState({
    pageNumber: 0,
    pageSize: 20,
    sortField: "dataLancamento",
    sortDir: "Desc"
  });

  const [plano, setPlano] = useState({
    id: null
  });

  const {data, loading, error, refetch, fetchMore} = useQuery(LIST_VENDAS, {
    variables: {
      searchDTO,
      pageableDTO,
    },
    fetchPolicy: 'no-cache'
  });

  const [vendas, setVendas] = useState([]);

  const [formData, setFormData] = useState({
    responsavelNome: '',
    responsavelCargo: '',
    responsavelCpf: '',
    nomeFantasia: '',
    razaoSocial: '',
    cnpj: '',
    inscricaoEstadual: '',
    telefone: '',
    codigoCnes: '',
    email: '',
    endereco: {
      cep: '',
      tipoLogradouro: {
        id: ''
      },
      nomeLogradouro: '',
      numero: '',
      bairro: '',
      complemento: '',
      municipio: {
        id: ''
      },
      estado: {
        id: ''
      }
    },
    tempoFuncionamentoEmpresa: 0,
    numeroPacientes: 0,
    haveraMigracaoSistema: false,
    sistemaASerMigrado: '',
    especialidades: [],
  });

  const handleSubmit = async () => {
    try{
      const {data} = await criarVenda({
        variables: {
          planoId: plano.id,
          contratante: {
            ...formData,
            responsavelCpf: string.removeSpecialChars(formData.responsavelCpf),
            cnpj: formData.cnpj ? string.removeSpecialChars(formData.cnpj) : null,
            telefone: string.removeSpecialChars(formData.telefone),
            especialidades: formData.especialidades.map(e => ({ id: e.value})),
            endereco: {
              ...formData.endereco,
              cep: string.removeSpecialChars(formData.endereco.cep),
              tipoLogradouro: {
                id: formData.endereco.tipoLogradouro.value
              },
              estado: {
                id: formData.endereco?.estado?.value
              },
              municipio: {
                id: formData.endereco.municipio.value
              },
            }
          }
        }
      });

      const vendaId = data?.createVenda?.id;
      history.push(`${VISUALIZAR_VENDA.replace(':vendaId', vendaId)}`);

      if (data?.createVenda?.id) {
        setNotification({
          variant: 'success',
          message: 'Venda criada com sucesso!',
          isOpen: true
        });
      }
    } catch ({ graphQLErrors }) {
      setNotification({
        variant: 'error',
        message: 'Não foi possível salvar esta venda! Verifique os campos novamente.',
        isOpen: true
      });
    }
  };

  const handleChangeByDataLancamento = () => {
      setOrder({ field: 'dataLancamento', asc: orderDataLancamento ? !order.asc : false});
  };

  const handleChangeByDataVencimento = () => {
      setOrder({ field: 'unidade.dataVencimento', asc: orderDataVencimento? !order.asc : false });
  };

  const ArrowOrderDataLancamento = () => {
    if (orderDataLancamento) {
      return order.asc ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />;
    }
    return (
      <ArrowUpAndDownContent>
        <ArrowUpper />
        <ArrowDropDownIcon />
      </ArrowUpAndDownContent>
    );
  };

  const ArrowOrderDataVencimento = () => {
    if (orderDataVencimento) {
      return order.asc ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />;
    }
    return (
      <ArrowUpAndDownContent>
        <ArrowUpper />
        <ArrowDropDownIcon />
      </ArrowUpAndDownContent>
    );
  };

  const changeOrder = () => {
      setPageableDTO ({
        pageNumber: 0,
        pageSize: 20,
        sortField: order.field,
        sortDir: order.asc ? "Asc" : "Desc"
        })
  }

  const handleClickCriarVenda = () => {
    setIsCriarVendaOpen(true);
    setPlano({id:null})
  };

  const handleCloseCriarVenda = () => {
    setIsCriarVendaOpen(false);
  };

  const handleClickVenda = venda => {
    history.push(`${VISUALIZAR_VENDA.replace(':vendaId', venda.id)}`);
  };

  const atualizarDados = (dadosAtualizados) => {
    setFormData({
      ...formData,
      ...dadosAtualizados
    });
  };

  const atualizarPlano = (dadosAtualizados) => {
    setPlano(dadosAtualizados);
  };

  const handleLoadMore = () => {
    if (!data?.findAllVenda?.last) {
      const pageable = {
        ...pageableDTO,
        pageNumber: data.findAllVenda.number + 1,
      };
      fetchMore({
        variables: {
          pageableDTO: {
            ...pageable,
          },
          searchDTO: {
            ...searchDTO,
          },
        },
        updateQuery: (prev, {fetchMoreResult}) => {
          if (!fetchMoreResult) return prev;
          setVendas({
            ...vendas,
            ...fetchMoreResult.findAllVenda,
            content: [
              ...vendas.content,
              ...fetchMoreResult.findAllVenda.content
            ]
          });
        }
      });
    }
  };

  useEffect(() => {
		changeOrder();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [order]);

  useEffect(() => {
    setVendas(data?.findAllVenda);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Box>
      <Search onSearch={refetch} onDataSearch={s => setSearchDTO({...searchDTO, ...s})}/>
      <List
        columns={[
          {
            title: 'Status',
            value: (item) => string.capitalize(item.status?.toLowerCase().replace("_", " ") || ""),
          },
          {
            title: 'Unidade',
            value: (item) => item.unidade?.nome,
          },
          {
            title:
              <FilteredTitle>
                <IconButton aria-label="expand row" size="small" onClick={handleChangeByDataLancamento}>
                  <ArrowOrderDataLancamento/>
                </IconButton>
                Lançamento
            </FilteredTitle>,
            value: (item) => item.dataLancamento ? moment(item.dataLancamento).format('MM/YYYY') : '-',
          },
          {
            title: 'Valor',
            value: (item) => Number.currencyFormat(item.valor),
          },
          {
            title:
              <FilteredTitle>
                <IconButton aria-label="expand row" size="small" onClick={handleChangeByDataVencimento}>
                  <ArrowOrderDataVencimento/>
                </IconButton>
                Data de vencimento
              </FilteredTitle>,
            value: (item) => moment(item.unidade.dataVencimento).format("DD/MM/YYYY"),
          },
          {
            title: 'Vendedor',
            value: (item) => item.vendedor ? item.vendedor.nome : '-',
          },
        ]}
        items={vendas?.content}
        loading={loading}
        error={error}
        last={vendas?.last}
        onLoadMore={handleLoadMore}
        onClickItem={handleClickVenda}
      />
      {canCriarVenda() && <ButtonFloat title="Criar Venda" onClick={handleClickCriarVenda}/>}

      <ModalSlider
        isOpen={isCriarVendaOpen}
        shouldCloseOnOverlayClick={handleCloseCriarVenda}
        width="30%"
      >
        <Flex flexDirection="column">
          <Header>
            <ArrowBackStyled onClick={handleCloseCriarVenda}/>
            <HeaderTitle>Criar Venda</HeaderTitle>
          </Header>

            <FormGroup item xs={12}>
              {plano.id === null && <Plano dados={plano} handleAtualizarDados={atualizarPlano}/>}
              {plano.id !== null && <StepperCriarVenda dados={formData} handleAtualizarDados={atualizarDados} handle handleFinalizarVenda={handleSubmit}/>}
            </FormGroup>

          <Notification
            close={() => {
              setNotification({
                ...notificationDefault,
              });
            }}
            reset={() => {
              setNotification({
                ...notificationDefault,
              });
            }}
            isOpen={notification.isOpen}
            variant={notification.variant}
            message={notification.message}
          />
        </Flex>
      </ModalSlider>
    </Box>
  )
};

export default ListVendas;

const FormGroup = styled(Grid)`
	margin-bottom: 15px !important;
`;

const Header = styled.div`
  height: 45px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;
`;

const HeaderTitle = styled.div`
  font-size: 18px;
  text-align: left;
  color: ${Colors.primary.main};
  margin-top: 4px;
  margin-left: 10px;
`;

const ArrowUpAndDownContent = styled.span`
  display : flex;
  flex-direction: column;
  padding-top: 4px;
`;

const FilteredTitle = styled.div`
  font-size: 12px;
`;

const ArrowUpper = styled(ArrowDropUpIcon)`
  position: absolute;
  bottom: 10px
`;

const ArrowBackStyled = styled(ArrowBack)`
  color: ${Colors.primary.main};
  cursor: pointer;
`;
