import React from 'react';
import styled from '@emotion/styled';

import Menu from './components/menu';
import Header from './components/header';

const BaseLayout = ({ children, hasMenu }) => (
	<Container>
		<MenuContainer>
			<Menu hasMenu={hasMenu} />
		</MenuContainer>
		<ContentContainer>
			<HeaderContainer>
				{!!hasMenu && <Header />}
			</HeaderContainer>
			<Content>
				{children}
			</Content>
		</ContentContainer>
	</Container>
);

const Container = styled.div`
	display: flex;
	width: 100%;
	height: 100%;
`;
const MenuContainer = styled.div`
  width: 80px;
  min-width: 80px;
  height: 100vh;
	background-color: #00ACA9;
	box-sizing: border-box;
	flex-direction: column;
	display: flex;
`;
const ContentContainer = styled.div`
	width: 94.3%;
`;
const HeaderContainer = styled.div`
	width: 100%;
	height: 11.94vh;
`;
const Content = styled.div`
	width: 100%;
	box-sizing: border-box;
	padding: 0 3% 1.9% 2.3%;
	height: calc(100vh - 11.94vh);
	overflow: auto;
`;

export default BaseLayout;
