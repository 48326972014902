import React from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
import Box from '../box';
import { getMaskByType } from '../../utils/masks';

const Input = ({ id, mask, ...rest }) => {
	if (mask) {
		return (
			<MaskedInput
				id={id}
				mask={getMaskByType(mask, rest.value)}
				placeholderChar={'\u2000'}
				{...rest}
				render={(ref, props) => (
					<Box
						as="input"
						id={id}
						ref={input => ref(input)}
						{...props} 
					/>
				)}
			/>
		);
	}

	return (
		<Box
			as="input"
			id={id}
			{...rest} 
		/>
	);
};

Input.propTypes = {
	/**
	 * id is required to use on accessibility with label
	 */
	id: PropTypes.string.isRequired,
	value: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired
}

export default Input;