import { getAuthorities } from '.';
import {
  ROLE_SISTEMA,
  ROLE_EXECUTIVO,
  ROLE_INTERMEDIARIO,
  ROLE_PLATAFORMA,
} from './enum';

const canCriarEntidade = () => {
  const filterAuthorities = [
    ROLE_SISTEMA,
    ROLE_EXECUTIVO,
    ROLE_INTERMEDIARIO,
    ROLE_PLATAFORMA,
  ];
  return getAuthorities(filterAuthorities).length > 0;
};

export default canCriarEntidade;
