import React, { useState } from 'react';
import styled from '@emotion/styled';
import Grid from '@material-ui/core/Grid';
import ArrowBack from '@material-ui/icons/ArrowBack';
import Colors from '../../../theme/Colors';
import { useMutation } from 'react-apollo';
import { ADICIONAR_BOLETO } from '../../../graphql/mutations';
import Notification from '../../../components/notification/Notification';
import {SelectSearch} from "../../../components/select/SelectSearch";
import Typography from "@material-ui/core/Typography";

const notificationDefault = {
  variant: '',
  message: '',
  isOpen: false,
};

const MeioDeCobrancaBoleto = ({ venda, onClose, onCreate }) => {
  const [notification, setNotification] = useState({
    ...notificationDefault,
  });

  const [salvando, setSalvando] = useState(false);
  const [adicionarBoleto] = useMutation(ADICIONAR_BOLETO);

  const [quantidadeParcelas, setQuantidadeParcelas] = useState({value: 12, name: "1 Ano"});

  const opcaoParcela = [
    {value: 12, name: "1 Ano"},
    {value: 24, name: "2 Anos"},
    {value: 36, name: "3 Anos"},
    {value: 48, name: "4 Anos"},
    {value: 60, name: "5 Anos"},
    {value: 72, name: "6 Anos"},
    {value: 84, name: "7 Anos"},
    {value: 96, name: "8 Anos"},
  ]

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      setSalvando(true);

      await adicionarBoleto({
        variables: {
          vendaId: venda.id,
          quantidadeParcelas: quantidadeParcelas?.value
        }
      });

      onCreate && onCreate();
      handleClose();
    } catch ({graphQLErrors}) {
      setSalvando(false);
      if (graphQLErrors) {
        setNotification({
          variant: 'error',
          isOpen: true,
          message: graphQLErrors[0].message,
        });
      }
    }
  };

  const handleClose = () => {
    onClose && onClose();
  }

  return (
    <Container>
      <Header>
        <ArrowBackStyled onClick={handleClose} />
        <HeaderTitle>Boleto</HeaderTitle>
      </Header>
      <Form onSubmit={handleSubmit}>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={12}>
            <FormHeading>Selecione a quantidade de parcelas:</FormHeading>
          </Grid>
          <FormGroup item xs={12}>
            <SelectSearchStyled
              name="quantidade"
              placeholder=""
              value={quantidadeParcelas}
              elements={opcaoParcela}
              isClearable={false}
              onChange={(e) => setQuantidadeParcelas(e)}/>
          </FormGroup>
          <FormGroup item xs={12} sm={12} md={12} ls={12}>
            <Typography color="primary" component="label" style={{marginRight: 20}}>
              Ao clicar em salvar informe o responsável pelo financeiro do AppHealth para gerar os boletos!
            </Typography>
          </FormGroup>
          <FormGroup item xs={12} sm={12} md={12} ls={12}>
            <Typography color="primary" component="label" style={{marginRight: 20}}>
              Telefone: (44) 3013-6070
            </Typography>
          </FormGroup>
          <FormGroup item xs={12} sm={12} md={12} ls={12}>
            <Typography color="primary" component="label" style={{marginRight: 20}}>
              E-mail: financeiro@apphealth.com.br
            </Typography>
          </FormGroup>
          <FormGroup item xs={12}>
            <ButtonsContainer>
              <Button type="submit" disabled={salvando || (quantidadeParcelas === undefined || quantidadeParcelas === null)}>{salvando ? 'Salvando...' : 'Salvar'}</Button>
            </ButtonsContainer>
          </FormGroup>
        </Grid>
      </Form>
      <Notification
        close={() => {
          setNotification({
            ...notificationDefault,
          });
        }}
        reset={() => {
          setNotification({
            ...notificationDefault,
          });
        }}
        isOpen={notification.isOpen}
        variant={notification.variant}
        message={notification.message}
      />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  padding: 10px 20px;
`;

const Header = styled.div`
  height: 45px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;
`;

const HeaderTitle = styled.div`
  font-size: 18px;
  text-align: left;
  color: ${Colors.primary.main};
  margin-top: 4px;
  margin-left: 10px;
`;

const ArrowBackStyled = styled(ArrowBack)`
  color: ${Colors.primary.main};
  cursor: pointer;
`;

const Form = styled.form`
  width: 96%;
  display: flex;
`;

const SelectSearchStyled = styled(SelectSearch)`
	width: 80%;
`;

const FormHeading = styled.h3`
	color: ${Colors.commons.gray5};
	margin: 15px 0 0;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const Button = styled.button`
  border: 0;
  background-color: ${Colors.primary.main};
  color: #fff;
  padding: 5px 10px;
  box-sizing: border-box;
  height: 34px;
  min-width: 150px;
  border-radius: 6px;
  margin: 8px 0;
  cursor: pointer;
`;

const FormGroup = styled(Grid)`
	margin-bottom: 15px !important;
`;

export default MeioDeCobrancaBoleto;
