export const SIGNUP = "/signup";
export const RECUPERAR_SENHA = "/redefinir-senha";
export const DASHBOARD = "/dashboard";
export const SELECIONA_ENTIDADE = `/seleciona-entidade`;
export const FORM_UNIT = `${DASHBOARD}/unit/edit`;
export const EDIT_ENTITY = `${DASHBOARD}/entidade/editar/:idEntity/:tipoEntity`;
export const CREATE_ENTITY = `${DASHBOARD}/entidade/cadastrar`;
export const MOVIMENTACAO_FINANCEIRA = `${DASHBOARD}/financeiro`;
export const VENDAS = `${DASHBOARD}/vendas`;
export const CRIAR_VENDA = `${DASHBOARD}/vendas/criar`;
export const VISUALIZAR_VENDA = `${DASHBOARD}/vendas/:vendaId/detalhes`;
export const FECHAMENTO = `${DASHBOARD}/fechamento`;
export const PUBLIC_CREATE_ENTITY = "/criar-conta/:code";
export const PAGAMENTO_VENDA = "/pagamentos/:vendaId"

export const UNSAFE_ZONE = [
    PUBLIC_CREATE_ENTITY,
    RECUPERAR_SENHA,
    PAGAMENTO_VENDA
];

export const RouteNames = [
    {
        route: SIGNUP,
        title: '',
    },
    {
        route: DASHBOARD,
        title: 'Entidades',
    },
    {
        route: FORM_UNIT,
        title: 'Cadastrar unidade',
    },
    {
        route: EDIT_ENTITY,
        title: 'Editar entidade',
    },
    {
        route: CREATE_ENTITY,
        title: 'Criar entidade',
    },
    {
        route: MOVIMENTACAO_FINANCEIRA,
        title: 'Movimentação financeira',
    },
    {
        route: FECHAMENTO,
        title: 'Fechamento',
    },
    {
        route: VENDAS,
        title: 'Vendas',
    },
    {
        route: CRIAR_VENDA,
        title: 'Criar venda',
    },
    {
        route: VISUALIZAR_VENDA,
        title: 'Detalhes da venda',
    },
    {
      route: PAGAMENTO_VENDA,
      title: 'Pagamento da venda',
  },
];
