import React, {useState} from "react";
import styled from "@emotion/styled";
import ImagemFundoPlano from "../../../../assets/images/doutora@2x.png";
import Grid from "@material-ui/core/Grid";
import ArrowBack from '@material-ui/icons/ArrowBack';
import {useQuery, useMutation} from "@apollo/react-hooks";
import {LIST_PLANOS} from "../../../../graphql/queryes";
import {UPDATE_VENDA_PLANO} from "../../../../graphql/mutations";
import Colors from "../../../../theme/Colors";
import MobileStepper from "@material-ui/core/MobileStepper";

const Plano = ({dados, handleAtualizarDados, vendaId, onClose}) => {
  let dadosTemp = {
    plano: dados?.id
  };

  const [passoAtual, setPassoAtual] = useState(0);

  const [updateVendaPlano] = useMutation(UPDATE_VENDA_PLANO);

  let proximoPasso = () => {
    setPassoAtual((prevPassoAtual) => prevPassoAtual + 1)
  };

  let voltar = () => {
    setPassoAtual((prevPassoAtual) => prevPassoAtual - 1)
  };

  const {data: planos} = useQuery(LIST_PLANOS, {
    variables: {
      searchDTO: {
        pageSize: 15,
        pageNumber: 0
      }
    }
  });

  const possuiPlano = dados?.id === null;

  const handleClose = () => {
    onClose && onClose();
  };

  const handleChangePlano = async (value) => {
    dadosTemp.plano = value;
    possuiPlano
      ? handleAtualizarDados({ id: dadosTemp.plano })
      : (await updateVendaPlano({
        variables: {
          vendaId: vendaId,
          planoId: dadosTemp.plano
        },
      }));

    handleClose();
  };

  const renderButton = (label, handleFunction, disabled) => (
    <>
      <Button type="button" disabled={disabled} onClick={handleFunction}>{label}</Button>
    </>
  );

  return (
    <Container>

      <PageContainer container direction="column">
        {!possuiPlano && (
          <Header>
            <ArrowBackStyled onClick={handleClose}/>
            <HeaderTitle>Alterar Plano</HeaderTitle>
          </Header>
        )}
        {planos !== undefined ? planos.findAllPlanoAtivo.filter(plano => plano.ordem === (passoAtual + 1)).map(plano =>
          <div key={plano.id} >
            <PlanoOption key={plano.id} onClick={() => handleChangePlano(plano.id)}>
              <Grid container item xs={10} direction="column" alignItems={"center"}>
                <Grid item>
                  <ImageContainer >
                    <ImagemPlano src={ImagemFundoPlano} />
                  </ImageContainer>
                </Grid>
                <Grid item >
                  <Label>Descrição</Label>
                  {plano.nome}
                </Grid>
                <Grid item>
                  <Label>Valor</Label>
                  {plano.valor}
                </Grid>
              </Grid>
            </PlanoOption>
          </div>
        )
          : null}

        <FormGroup item xs={10}>
          <ButtonsContainer>
            <Guia
              variant="progress"
              steps={planos?.findAllPlanoAtivo?.length || 0}
              activeStep={passoAtual}
              position={'bottom'}
              backButton={
                renderButton("Voltar", voltar, (passoAtual - 1) < 0)
              }
              nextButton={
                renderButton("Proximo", proximoPasso, (passoAtual + 1) === planos?.findAllPlanoAtivo?.length)
              }
            />
          </ButtonsContainer>
        </FormGroup>
      </PageContainer>
    </Container>
  );

};

export default Plano;

const Container = styled.div`
  width: 100%;
  margin: 0 auto !important;
`;

const PageContainer = styled(Grid)`
  align-items: center;
`;

const PlanoOption = styled.div`
  border: 1px solid ${Colors.primary.dark};
  border-radius: 10px;
  cursor: pointer;
  width: 180px;
`;

const ImagemPlano = styled.img`
  width: 180px;
  height: 180px;
`;

const FormGroup = styled(Grid)`
	margin-bottom: 15px !important;
`;
const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const Label = styled.p`
  font-size: 12px;
  text-align: left;
  color: ${Colors.commons.fontColor};
  margin-bottom: 3px;
`;

const ImageContainer = styled.a`
  cursor: pointer;
`;

const Button = styled.button`
  border: 0;
  background-color: ${Colors.primary.main};
  color: #fff;
  padding: 5px 10px;
  box-sizing: border-box;
  height: 34px;
  min-width: 80px;
  border-radius: 6px;
  margin: 8px 0;
  cursor: pointer;
`;

const Header = styled.div`
  height: 45px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;
  align-self: flex-start;
  margin-left: 12px;
`;

const HeaderTitle = styled.div`
  font-size: 18px;
  text-align: left;
  color: ${Colors.primary.main};
  margin-top: 4px;
  margin-left: 10px;
`;

const ArrowBackStyled = styled(ArrowBack)`
  color: ${Colors.primary.main};
  cursor: pointer;
`;

const Guia = styled(MobileStepper)`
    width: 600;
    max-width: 800;
    flex-grow: 1;
    align-self: center;
    justify-content: center;
`;
