import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography";
import Close from '@material-ui/icons/Close';
import Colors from '../../../theme/Colors';
import TextFieldSearch from "../../../components/TextField/TextFieldSearch";
import { TextField } from "../../../components/TextField";
import { useMutation, useApolloClient, useLazyQuery } from 'react-apollo';
import { DROPDOWN_MUNICIPIOS, CONSULTA_CEP } from '../../../graphql/queryes';
import { UPDATE_UNIDADE } from '../../../graphql/mutations';
import Notification from '../../../components/notification/Notification';
import string from '../../../utils/string';

const notificationDefault = {
  variant: '',
  message: '',
  isOpen: false,
};

const EditarUnidade = ({ venda, onClose, onSave }) => {
  const [notification, setNotification] = useState({
    ...notificationDefault,
  });
  const [formData, setFormData] = useState({
    codigoCnes: venda.unidade?.codigoCnes,
    nome: venda.unidade?.nome,
    nomeFantasia: venda.unidade?.nomeFantasia,
    telefonePrincipal: venda.unidade?.telefonePrincipal,
    cep: venda.unidade?.endereco?.cep,
    bairro: venda.unidade?.endereco?.bairro,
    municipio: venda.unidade?.endereco?.municipio,
    numero: venda.unidade?.endereco?.numero,
    nomeLogradouro: venda.unidade?.endereco?.nomeLogradouro,
    complemento: venda.unidade?.endereco?.complemento,
  });
  const [salvando, setSalvando] = useState(false);
  const client = useApolloClient();
  const [updateUnidade] = useMutation(UPDATE_UNIDADE);

  const [getConsultaCep, {data: dadosConsultaCep}] = useLazyQuery(CONSULTA_CEP);

  const handleSubmit = async e => {
    e.preventDefault();
    if(isDadosUnidadeInvalidos()){
      setNotification({
        variant: 'error',
        isOpen: true,
        message: 'Verifique os dados novamente',
      });

      return null;
    }

    try {
      setSalvando(true);
      await updateUnidade({
        variables: {
          unidade: {
            id: venda.unidade?.id,
            codigoCnes: formData.codigoCnes,
            nome: formData.nome,
            nomeFantasia: formData.nomeFantasia,
            telefonePrincipal: string.removeSpecialChars(formData.telefonePrincipal),
            endereco: {
              cep: string.removeSpecialChars(formData.cep),
              bairro: formData.bairro,
              municipio: {
                id: formData.municipio.value,
              },
              numero: formData.numero,
              nomeLogradouro: formData.nomeLogradouro,
              complemento: formData.complemento,
            }
          }
        },
      });

      onSave && onSave();
      handleClose();
    } catch ({ graphQLErrors }) {
      setSalvando(false);
      if (graphQLErrors) {
        setNotification({
          variant: 'error',
          isOpen: true,
          message: graphQLErrors[0].message,
        });
      }
    }
  };

  const consultaCep = async (cep) => {
    await getConsultaCep({
      variables: {
        cep: cep
      }
    });
  };

  const isDadosUnidadeInvalidos = () => {
    return !formData.nome ||
           !string.validaTelefone(formData.telefonePrincipal) ||
           (formData.cep === undefined || string.removeSpecialChars(formData.cep).length < 8) ||
           !formData.bairro ||
           !formData.nomeLogradouro  ||
           !formData.municipio
  }

  const handleClose = () => {
    onClose && onClose();
  }

  const handleLoadMoreOptions = async ({ variables, query, responseObject }) => {
    const response = await client.query({
      query,
      variables
    });

    const pageNumber = variables?.searchDTO?.pageNumber || 0;

    let options = response?.data?.[responseObject]?.content || [];

    if (responseObject === 'findAllMunicipio') {
      options = options.map(o => ({
        ...o,
        label: `${o.label} - ${o.uf}`,
      }));
    }

    return {
      options,
      hasMore: !response?.data?.[responseObject]?.last,
      additional: {
        page: pageNumber + 1
      }
    };
  };

  const handleMunicipiosLoadOptions = async (search, loadedOptions, { page }) => {
    return handleLoadMoreOptions({
      loadedOptions,
      variables: {
        searchDTO: {
          pageSize: 10,
          pageNumber: page,
          search
        }
      },
      responseObject: 'findAllMunicipio',
      query: DROPDOWN_MUNICIPIOS
    });
  };

  const renderTextField = (label, field, required) => {
    const error = required && !formData[field];
    return (
      <>
        <Typography color="primary" component="label">
          {required ? label + '*' : label}
        </Typography>
        <TextField
          value={formData[field]}
          onChange={e =>
              setFormData({
                ...formData,
                [field]: e.target.value,
              })
          }
          error={error}
          helperText={error && "Campo obrigatório"}
        />
      </>
    )
  };

  const renderPhoneField = (label, field, required) => {
  const error = required && (!string.validaTelefone(formData[field]));
      return (
        <>
          <Typography color="primary" component="label">
            {required ? label + '*' : label}
          </Typography>
          <TextField
            value={formData[field]}
            mask={'phone'}
            onChange={e =>
                setFormData({
                  ...formData,
                  [field]: e.target.value,
                })
            }
            error={error}
            helperText={error && "Telefone inválido"}
          />
        </>
      )
    };

    const renderCEPField = (label, field, required) => {

          const error = required && string.removeSpecialChars(formData.cep).length < 8;

          const handleChangeCep = (e) => {
            const cpfSemMascara = string.removeSpecialChars(e.target.value);
            setFormData({
              ...formData,
              [field]: e.target.value,
            })

            if(cpfSemMascara.length === 8){
              consultaCep(cpfSemMascara);
            }
          }

          return (
            <>
              <Typography color="primary" component="label">
                {required ? label + '*' : label}
              </Typography>
              <TextField
                value={formData[field]}
                mask={'cep'}
                onChange={e => handleChangeCep(e)}
                error={error}
                helperText={error && "CEP inválido"}
              />
            </>
          )
        };

  const renderTextFieldSearch = (label, field, loadOptions) => (
    <>
      <Typography color="primary" component="label">
        {label}
      </Typography>
      <TextFieldSearch
        placeholder=""
        loadOptions={loadOptions}
        withPaginate
        value={formData[field]}
        onChange={e =>
          setFormData({
            ...formData,
            [field]: e,
          })
        }
        debounceTimeout={300}
        additional={{
          page: 0
        }}
      />
    </>
  );

  useEffect(() => {
      if (dadosConsultaCep?.consultaDeCep) {
        setFormData({
          ...formData,
          nomeLogradouro: dadosConsultaCep?.consultaDeCep?.logradouro,
          bairro: dadosConsultaCep?.consultaDeCep?.bairro,
          municipio: {...dadosConsultaCep.consultaDeCep?.municipio, label: `${dadosConsultaCep.consultaDeCep?.municipio?.label || '-'} - ${dadosConsultaCep.consultaDeCep?.municipio?.uf || '-'}`},
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dadosConsultaCep]);

  return (
    <Container>
      <Header>
        <CloseStyled onClick={handleClose} />
        <HeaderTitle>Alterar Unidade</HeaderTitle>
      </Header>
      <Form onSubmit={handleSubmit}>
        <Grid container direction="row" spacing={2}>
          <FormGroup item xs={6}>
            {renderTextField('Nome', 'nome', true)}
          </FormGroup>
          <FormGroup item xs={6}>
            {renderTextField('Nome Fantasia', 'nomeFantasia', false)}
          </FormGroup>
          <FormGroup item xs={6}>
            {renderTextField('Código CNES', 'codigoCnes', false)}
          </FormGroup>
          <FormGroup item xs={6}>
            {renderPhoneField('Telefone Principal', 'telefonePrincipal', true)}
          </FormGroup>
          <FormGroup item xs={6}>
            {renderCEPField('CEP', 'cep', true)}
          </FormGroup>
          <FormGroup item xs={6}>
            {renderTextField('Logradouro', 'nomeLogradouro', true)}
          </FormGroup>
          <FormGroup item xs={6}>
            {renderTextField('Número', 'numero', true)}
          </FormGroup>
          <FormGroup item xs={6}>
            {renderTextField('Complemento', 'complemento', false)}
          </FormGroup>
          <FormGroup item xs={6}>
            {renderTextField('Bairro', 'bairro', true)}
          </FormGroup>
          <FormGroup item xs={6}>
            {renderTextFieldSearch('Município*', 'municipio', handleMunicipiosLoadOptions)}
          </FormGroup>
          <FormGroup item xs={12}>
            <ButtonsContainer>
              <Button type="submit" disabled={salvando || isDadosUnidadeInvalidos()}>{salvando ? 'Salvando...' : 'Salvar'}</Button>
            </ButtonsContainer>
          </FormGroup>
        </Grid>
      </Form>
      <Notification
        close={() => {
          setNotification({
            ...notificationDefault,
          });
        }}
        reset={() => {
          setNotification({
            ...notificationDefault,
          });
        }}
        isOpen={notification.isOpen}
        variant={notification.variant}
        message={notification.message}
      />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  padding: 10px 10px;
`;

const Header = styled.div`
  height: 45px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;
`;

const HeaderTitle = styled.div`
  font-size: 18px;
  text-align: left;
  color: ${Colors.primary.main};
  margin-top: 4px;
  margin-left: 10px;
`;

const CloseStyled = styled(Close)`
  color: ${Colors.primary.main};
  cursor: pointer;
`;

const Form = styled.form`
  width: 96%;
  display: flex;
`;

const FormGroup = styled(Grid)`
	margin-bottom: 15px !important;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const Button = styled.button`
  border: 0;
  background-color: ${Colors.primary.main};
  color: #fff;
  padding: 5px 10px;
  box-sizing: border-box;
  height: 34px;
  min-width: 150px;
  border-radius: 6px;
  margin: 8px 0;
  cursor: pointer;

  &:disabled {
    background-color: ${Colors.commons.gray};
  }
`;

export default EditarUnidade;
