import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid"
import RenderTextField from "../../../../components/TextField/RenderTextField";
import styled from "@emotion/styled";
import string from "../../../../utils/string";

const DadosBasicosContratante = ({ dados, handleAtualizarDados, dadosErrors, handleAtualizarDadosErrors, handleDadosInvalidos, disabled }) => {

  const [dadosTemp, setDadosTemp] = useState({
    razaoSocial: dados.razaoSocial || '',
    nomeFantasia: dados.nomeFantasia || '',
    inscricaoEstadual: dados.inscricaoEstadual || '',
    telefone: dados.telefone || '',
    codigoCnes: dados.codigoCnes || '',
    email: dados.email || ''
  });

  const handleChangeField = (campo, value) => {
    setDadosTemp({
      ...dadosTemp,
      [campo]: value
    });

    handleAtualizarDadosErrors({
      [campo]: !value
    });

    handleAtualizarDados({
      [campo]: value
    });

  };

  const handleInscricaoEstadualField = (value) => {
    setDadosTemp({
      ...dadosTemp,
      inscricaoEstadual: value
    });

    handleAtualizarDados({
      inscricaoEstadual: value
    });
  };

  const handleTelefoneField = (value) => {
    setDadosTemp({
      ...dadosTemp,
      telefone: value
    });

    handleAtualizarDadosErrors({
      telefone: !string.validaTelefone(value)
    });

    handleAtualizarDados({
      telefone: value
    });
  };

    const handleEmailField = (value) => {
      setDadosTemp({
        ...dadosTemp,
        email: value
      });

      handleAtualizarDadosErrors({
        email: !value || !string.validaEmail(value)
      });

      handleAtualizarDados({
        email: value
      });

    };

    const isCamposPreenchidos = () => {
      return dadosTemp.razaoSocial === '' ||
        dadosTemp.nomeFantasia === '' ||
        !string.validaEmail(dadosTemp.email) ||
        !string.validaTelefone(dadosTemp.telefone);
    };

    useEffect(() => {
      handleDadosInvalidos(isCamposPreenchidos());
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handleAtualizarDados]);

    return (
      <Container>
        <Grid container direction="row" spacing={2}>
          <FormGroup item xs={12} sm={12} md={12} ls={12}>
            <RenderTextField label={"Razão Social *"}
              field={dadosTemp.razaoSocial}
              error={dadosErrors?.razaoSocial}
              errorText={"Este campo é obrigatório."}
              handleChange={(e) => handleChangeField('razaoSocial', e.target.value)}
              disabled={disabled} />
          </FormGroup>
          <FormGroup item xs={12} sm={12} md={12} ls={12}>
            <RenderTextField label={"Nome Fantasia *"}
              field={dadosTemp.nomeFantasia}
              error={dadosErrors?.nomeFantasia}
              errorText={"Este campo é obrigatório."}
              handleChange={(e) => handleChangeField('nomeFantasia', e.target.value)}
              disabled={disabled} />
          </FormGroup>

          <FormGroup item xs={12} sm={12} md={12} ls={12}>
            <RenderTextField label={"Inscrição Estadual"}
              field={dadosTemp.inscricaoEstadual}
              mask={'inscricaoEstadual'}
              handleChange={(e) => handleInscricaoEstadualField(e.target.value)}
              disabled={disabled} />
          </FormGroup>
          <FormGroup item xs={12} sm={12} md={12} ls={12}>
            <RenderTextField label={"Telefone *"}
              field={dadosTemp.telefone}
              mask={string.removeSpecialChars(dadosTemp.telefone).length <= 10 ? 'phone' : 'cellPhone'}
              error={dadosErrors?.telefone}
              errorText={"Este campo é obrigatório."}
              handleChange={(e) => handleTelefoneField(e.target.value)}
              disabled={disabled} />
          </FormGroup>
          <FormGroup item xs={12} sm={12} md={12} ls={12}>
            <RenderTextField label={"Código CNES"}
              field={dadosTemp.codigoCnes}
              handleChange={(e) => handleChangeField('codigoCnes', e.target.value)}
              disabled={disabled} />
          </FormGroup>
          <FormGroup item xs={12} sm={12} md={12} ls={12}>
            <RenderTextField label={"Email *"}
              field={dadosTemp.email}
              error={dadosErrors?.email}
              errorText={"Este campo é obrigatório."}
              handleChange={(e) => handleEmailField(e.target.value)}
              disabled={disabled} />
          </FormGroup>
        </Grid>
      </Container>
    );

  };

  export default DadosBasicosContratante;

  const Container = styled.div`
  width: 100%;
  padding: 10px 0;
`;

  const FormGroup = styled(Grid)`
  margin-bottom: 10px !important;
`;
