import React from 'react';

import {
	Route,
} from 'react-router-dom';
import ListEntity from '../features/list-entity';
import BaseLayout from '../layout/base-layout';
import {
  EDIT_ENTITY,
  MOVIMENTACAO_FINANCEIRA,
  FECHAMENTO,
  VENDAS,
  CRIAR_VENDA,
  VISUALIZAR_VENDA,
  CREATE_ENTITY
} from './names';
import EditEntity from '../features/edit-entity';
import ApolloInstance from '../apollo/setup';
import ListMovimentacaoFinanceira from '../features/list-movimentacao-financeira';
import ListFechamento from '../features/list-fechamento';
import CriarVenda from '../features/criar-venda';
import ListVendas from '../features/list-vendas';
import VisualizarVenda from '../features/visualizar-venda';

const DashboardRouter = () => (
	<ApolloInstance>
		<BaseLayout hasMenu>
			<Route exact path="/dashboard" >
				<ListEntity />
			</Route>
			<Route path={EDIT_ENTITY} >
				<EditEntity />
			</Route>
			<Route exact path={MOVIMENTACAO_FINANCEIRA}>
				<ListMovimentacaoFinanceira />
			</Route>
			<Route exact path={FECHAMENTO}>
				<ListFechamento />
			</Route>
			<Route exact path={VENDAS}>
				<ListVendas />
			</Route>
			<Route path={CRIAR_VENDA}>
				<CriarVenda />
			</Route>
			<Route path={VISUALIZAR_VENDA}>
				<VisualizarVenda />
			</Route>
      <Route path={CREATE_ENTITY}>
        <EditEntity/>
      </Route>
		</BaseLayout>
	</ApolloInstance>
);

export default DashboardRouter;
