import React from 'react';
import styled from '@emotion/styled';
import List from '../../../components/list/list';
import { useQuery } from 'react-apollo';
import { LIST_BENEFICIARIOS_DA_VENDA } from '../../../graphql/queryes';
import Grid from '@material-ui/core/Grid';

const DetalhesDosBeneficiarios = ({ dados = {} }) => {
  const {
    data: listBeneficiariosData,
    loading: loadingListBeneficiarios,
    error: errorListBeneficiarios,
  } = useQuery(LIST_BENEFICIARIOS_DA_VENDA, {
    variables: {
      entidade: {
        id: dados.vendedor.id,
        tipo: dados.vendedor.tipo,
      },
    },
  });

  return (
    <InfoContainer>
      <ListBeneficiarios item xs={'auto'}>
        <List
          columns={[
            {
              title: 'Nível',
              value: (item) => (item.nivel ? item.nivel.nome || '-' : '-'),
            },
            {
              title: 'Nome',
              value: (item) => item.nome,
            },
          ]}
          items={
            listBeneficiariosData?.findAllEntidadesEntreUsuarioLogadoEEntidade
          }
          loading={loadingListBeneficiarios}
          error={errorListBeneficiarios}
          last
        />
      </ListBeneficiarios>
    </InfoContainer>
  );
};

export default DetalhesDosBeneficiarios;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin: 10px;
`;

const ListBeneficiarios = styled(Grid)`
  max-width: 300px;
  overflow: auto;
`;
