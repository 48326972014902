import React, {useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import RenderTextField from "../../../../components/TextField/RenderTextField";
import styled from "@emotion/styled";
import string from "../../../../utils/string";
import {useLazyQuery} from "react-apollo";
import {CONSULTA_CNPJ, POSSUI_VENDAS_COM_OUTRO_VENDEDOR} from "../../../../graphql/queryes";
import Notification from "../../../../components/notification/Notification";

const notificationDefault = {
  variant: '',
  message: '',
  isOpen: false,
};

const DadosResponsavelContratante = ({dados, dadosErrors, handleAtualizarDadosErrors, handleAtualizarDados, handleDadosInvalidos, disabled}) => {

  const [dadosTemp, setDadosTemp] = useState({
    cnpj: dados.cnpj || '',
    responsavelNome: dados.responsavelNome || '',
    responsavelCargo: dados.responsavelCargo || '',
    responsavelCpf: dados.responsavelCpf || ''
  });

  const [getConsultaCnpj, {data: dadosConsultaCnpj}] = useLazyQuery(CONSULTA_CNPJ);

  const [consultaVendaExistente, {data: dadosConsultaVenda}] = useLazyQuery(POSSUI_VENDAS_COM_OUTRO_VENDEDOR);

  const [notification, setNotification] = useState({
    ...notificationDefault,
  });

  const consultaDadosPeloCnpj = async (cnpj) => {
    await getConsultaCnpj({
      variables: {
        cnpj: cnpj
      }
    });
  };

  const consultaVendaPeloCnpj = async (cnpj) => {
    await consultaVendaExistente({
      variables: {
        cnpj: cnpj
      }
    });
  };

  const handleCnpjField = (value) => {
    let cnpjSemMascara = string.removeSpecialChars(value);
    setDadosTemp({
      ...dadosTemp,
      cnpj: value
    });

    handleAtualizarDadosErrors({
      cnpj: !string.validaCNPJ(value)
    });

    if (cnpjSemMascara.length === 14) {
      consultaDadosPeloCnpj(cnpjSemMascara);
      consultaVendaPeloCnpj(cnpjSemMascara);
    }

    handleAtualizarDados({
      cnpj: value,
    })
  };

  const handleChangeField = (campo, value) => {
    setDadosTemp({
      ...dadosTemp,
      [campo]: value
    });

    handleAtualizarDados({
      [campo]: value
    });

    handleAtualizarDadosErrors({
      [campo]: value === undefined || value === ''
    });
  };

  const handleCpfField = (value) => {
    setDadosTemp({
      ...dadosTemp,
      responsavelCpf: value
    });

    handleAtualizarDadosErrors({
      responsavelCpf: !string.validaCPF(value)
    });

    handleAtualizarDados({
      responsavelCpf: value
    });
  };

  const isCamposPreenchidos = () => {
    return dadosTemp.responsavelNome === '' ||
      dadosTemp.responsavelCargo === '' ||
      string.removeSpecialChars(dadosTemp.responsavelCpf).length < 11 ||
      dadosErrors?.cnpj;
  };

  useEffect(() => {
    handleDadosInvalidos(isCamposPreenchidos());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleAtualizarDados]);

  useEffect(() => {
    if (dadosConsultaCnpj && !disabled) {
      handleAtualizarDados({
        razaoSocial: dadosConsultaCnpj?.consultaDeCnpj?.razaoSocial,
        nomeFantasia: dadosConsultaCnpj?.consultaDeCnpj?.nomeFantasia,
        endereco: {
          cep: dadosConsultaCnpj?.consultaDeCnpj?.cep,
          numero: dadosConsultaCnpj?.consultaDeCnpj?.numeroEndereco
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dadosConsultaCnpj]);

  useEffect(() => {
    let possuiVendasComOutroVendedor = dadosConsultaVenda?.possuiVendasComOutroVendedor;

    if (possuiVendasComOutroVendedor) {
      setNotification({
        variant: 'error',
        message: 'Já existe uma venda com esse CNPJ',
        isOpen: true,
      });

      handleAtualizarDadosErrors({
        cnpj: true
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dadosConsultaVenda]);

  return (
    <Container>
      <Grid container direction="row" spacing={2}>
        <FormGroup item xs={12} sm={12} md={12} ls={12}>
          <RenderTextField label={"CNPJ"}
                           field={dadosTemp.cnpj}
                           mask={'cnpj'}
                           error={dadosErrors?.cnpj}
                           errorText={"CNPJ inválido."}
                           handleChange={(e) => handleCnpjField(e.target.value)}
                           disabled={disabled}/>
        </FormGroup>

        <FormGroup item xs={12} sm={12} md={12} ls={12}>
          <RenderTextField label={"Nome do Responsável *"}
                           field={dadosTemp.responsavelNome}
                           error={dadosErrors?.responsavelNome}
                           errorText={"Este campo é obrigatório."}
                           handleChange={(e) => handleChangeField('responsavelNome', e.target.value)}
                           disabled={disabled}/>
        </FormGroup>
        <FormGroup item xs={12} sm={12} md={12} ls={12}>
          <RenderTextField label={"CPF *"}
                           field={dadosTemp.responsavelCpf}
                           error={dadosErrors?.responsavelCpf}
                           errorText={"CPF inválido."}
                           mask={'cpf'}
                           handleChange={(e) => handleCpfField(e.target.value)}
                           disabled={disabled}/>
        </FormGroup>
        <FormGroup item xs={12} sm={12} md={12} ls={12}>
          <RenderTextField label={"Cargo *"}
                           field={dadosTemp.responsavelCargo}
                           error={dadosErrors?.responsavelCargo}
                           errorText={"Este campo é obrigatório."}
                           handleChange={(e) => handleChangeField('responsavelCargo', e.target.value)}
                           disabled={disabled}/>
        </FormGroup>
      </Grid>
      <Notification
        close={() => {
          setNotification({
            ...notificationDefault,
          });
        }}
        reset={() => {
          setNotification({
            ...notificationDefault,
          });
        }}
        isOpen={notification.isOpen}
        variant={notification.variant}
        message={notification.message}
      />
    </Container>
  );
};

export default DadosResponsavelContratante;

const Container = styled.div`
  width: 100%;
  padding: 10px 0;
`;

const FormGroup = styled(Grid)`
  margin-bottom: 10px !important;
`;
