import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid"
import styled from "@emotion/styled";
import Typography from '@material-ui/core/Typography';
import RenderTextField from "../../../../components/TextField/RenderTextField";
import string from "../../../../utils/string";
import InputDateForm from "../../../../components/input/InputDateForm";
import { DROPDOWN_GROUP_BY_REDE_ID } from "../../../../graphql/queryes";
import TextFieldSearch from '../../../../components/TextField/TextFieldSearch';
import { useApolloClient } from 'react-apollo';
import { obterUsuarioLogadoLocal } from '../../../../services/usuario-service';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const DadosBase = ({ dados, unidade, handleAtualizarDados, handleAtualizarDadosErrors, dadosErrors, handleDadosInvalidos, disabled, criandoProfissional}) => {

  const [dadosTemp, setDadosTemp] = useState({
    nome: dados.nome,
    cpf: dados.cpf,
    cns: dados.cns,
    dataNascimento: dados.dataNascimento,
    email: dados.email,
    confirmarEmail: dados.confirmarEmail,
    grupoPermissoes: dados.grupoPermissoes,
    ativo: dados.ativo,
    utilizaAssinaturaDigital: dados.utilizaAssinaturaDigital,
    utilizaTelemedicina: dados.utilizaTelemedicina,
    utilizaSecretariaVirtual: dados.utilizaSecretariaVirtual
  });

  const client = useApolloClient();
  const usuarioLogado = obterUsuarioLogadoLocal();

  const handleChangeField = (campo, value) => {
    setDadosTemp({
      ...dadosTemp,
      [campo]: value
    });

    handleAtualizarDadosErrors({
      [campo]: !value
    });

    handleAtualizarDados({
      [campo]: value
    });

  };

  const handleCpfField = (value) => {
    setDadosTemp({
      ...dadosTemp,
      cpf: value
    });

    handleAtualizarDadosErrors({
      cpf: !string.validaCPF(value) ||
        string.removeSpecialChars(value) === usuarioLogado.entidadeAtual.responsavelCpf
    });

    handleAtualizarDados({
      cpf: value
    });
  };

  const handleEmailField = (value) => {
    setDadosTemp({
      ...dadosTemp,
      email: value
    });

    handleAtualizarDadosErrors({
      email: !value || !string.validaEmail(value),
      confirmarEmail: dadosTemp.confirmarEmail && value !== dadosTemp.confirmarEmail
    });

    handleAtualizarDados({
      email: value
    });

  };

  const handleConfirmarEmailField = (value) => {
    setDadosTemp({
      ...dadosTemp,
      confirmarEmail: value
    });

    handleAtualizarDadosErrors({
      confirmarEmail: value !== dadosTemp.email
    });

    handleAtualizarDados({
      confirmarEmail: value
    });
  }

  const emailErrorMessage = () => {
    if (dadosTemp.email === '')
      return 'Este campo é obrigatório.';

    if (!string.validaEmail(dadosTemp.email))
      return 'Email inválido.';

    if (dadosTemp.email !== dadosTemp.confirmarEmail)
      return 'O email e confirmação do email devem ser iguais.'

    return ''
  }

  const handleLoadMoreOptions = async ({ variables, query, responseObject, }) => {
    const response = await client.query({ query, variables, });

    const pageNumber = variables?.searchDTO?.pageNumber || 0;

    return {
      options: response?.data?.[responseObject] || [],
      hasMore: false,
      additional: {
        page: pageNumber + 1,
      },
    };
  };

  const handleGrupoPermissoesLoadOptions = async (loadedOptions) => {
    return handleLoadMoreOptions({
      loadedOptions,
      variables: {
        idRede: unidade?.rede?.id,
      },
      responseObject: 'findGroupByRedeId',
      query: DROPDOWN_GROUP_BY_REDE_ID,
    });
  };

  const validarCpfUsuarioLogado = () => {
    return string.removeSpecialChars(dadosTemp.cpf) === usuarioLogado.entidadeAtual.responsavelCpf ? 'CPF igual o do usuário logado' : 'CPF inválido'
  }

  useEffect(() => {
    const isCamposPreenchidos = dadosTemp.nome === '' ||
      dadosTemp.cpf === '' ||
      !string.validaCPF(dadosTemp.cpf) ||
      string.removeSpecialChars(dadosTemp.cpf) === usuarioLogado.entidadeAtual.responsavelCpf ||
      !dadosTemp.dataNascimento ||
      !string.validaEmail(dadosTemp.email) ||
      (criandoProfissional && !dadosTemp.grupoPermissoes) ||
      (criandoProfissional && dadosTemp.confirmarEmail !== dadosTemp.email)

    handleDadosInvalidos(isCamposPreenchidos);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleDadosInvalidos, dadosTemp]);

  return (
    <Container>
      <Grid container direction="row" spacing={2}>

        <FormGroup item xs={12} sm={12} md={12} ls={12}>
          <RenderTextField label={"Nome *"}
            field={dadosTemp.nome}
            error={dadosErrors?.nome}
            errorText={'Este campo é obrigatório!'}
            handleChange={(e) => handleChangeField('nome', e.target.value)}
            disabled={disabled} />
        </FormGroup>
        <FormGroup item xs={6} sm={6} md={6} ls={6}>
          <RenderTextField label={"CPF *"}
            field={dadosTemp.cpf}
            error={dadosErrors?.cpf}
            mask={'cpf'}
            errorText={validarCpfUsuarioLogado()}
            handleChange={(e) => handleCpfField(e.target.value)}
            disabled={disabled} />
        </FormGroup>
        <FormGroup item xs={6}>
          <Typography color="primary" component="label">
            Data de Nascimento *
            </Typography>
          <InputDateForm
            disableFuture
            variant="outlined"
            fullWidth
            value={dadosTemp.dataNascimento}
            onChange={(e) => handleChangeField('dataNascimento', e)}
          />
        </FormGroup>
        <FormGroup item xs={12} sm={12} md={12} ls={12}>
          <RenderTextField label={"CNS"}
            field={dadosTemp.cns}
            handleChange={(e) => handleChangeField('cns', e.target.value)}
            disabled={disabled} />
        </FormGroup>
        <FormGroup item xs={12} sm={12} md={12} ls={12}>
          <RenderTextField label={"Email *"}
            field={dadosTemp.email}
            error={dadosErrors?.email}
            errorText={emailErrorMessage()}
            handleChange={(e) => handleEmailField(e.target.value)}
            disabled={disabled} />
        </FormGroup>
        {criandoProfissional &&
          <FormGroup item xs={12} sm={12} md={12} ls={12}>
            <RenderTextField label={"Confirme o Email *"}
              field={dadosTemp.confirmarEmail}
              error={dadosErrors?.confirmarEmail}
              errorText={emailErrorMessage()}
              handleChange={(e) => handleConfirmarEmailField(e.target.value)}
              disabled={disabled} />
          </FormGroup>
        }
        {!dados.id && <FormGroup item xs={12} sm={12} md={12} ls={12}>
          <>
            <Typography color="primary" component="label">
              {"Grupo de Permissões *"}
            </Typography>
            < TextFieldSearch placeholder=""
              loadOptions={handleGrupoPermissoesLoadOptions}
              withPaginate
              value={dadosTemp.grupoPermissoes}
              onChange={e => handleChangeField('grupoPermissoes', e)}
              debounceTimeout={300}
              additional={{
                page: 0
              }}
            />
          </>
        </FormGroup>}
        {dados.id && <FormGroup item xs={6}>
          <FormControlLabel
            control={
              <Checkbox
                checked={dados.possuiAgenda}
                color="primary"
              />
            }
            disabled
            label="É médico?"
            labelPlacement="start"
            style={{ marginRight: 10 }}
          />
        </FormGroup>}
        {dados.possuiAgenda &&
          <>
            <FormGroup item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={dadosTemp.utilizaAssinaturaDigital}
                    onChange={() => handleChangeField('utilizaAssinaturaDigital', !dadosTemp.utilizaAssinaturaDigital)}
                    color="primary"
                  />
                }
                label="Utiliza Assinatura Digital?"
                labelPlacement="start"
                style={{ marginRight: 10 }}
              />
            </FormGroup>
            <FormGroup item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={dadosTemp.utilizaTelemedicina}
                    onChange={() => handleChangeField('utilizaTelemedicina', !dadosTemp.utilizaTelemedicina)}
                    color="primary"
                  />
                }
                label="Utiliza Telemedicina?"
                labelPlacement="start"
                style={{ marginRight: 10 }}
              />
            </FormGroup>
            <FormGroup item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={dadosTemp.utilizaSecretariaVirtual}
                    onChange={() => handleChangeField('utilizaSecretariaVirtual', !dadosTemp.utilizaSecretariaVirtual)}
                    color="primary"
                  />
                }
                label="Utiliza Secretaria Virtual?"
                labelPlacement="start"
                style={{ marginRight: 10 }}
              />
            </FormGroup>
          </>
        }
        {dados.id && <FormGroup item xs={6}>
          <FormControlLabel
            control={
              <Checkbox
                checked={dadosTemp.ativo}
                onChange={() => handleChangeField('ativo', !dadosTemp.ativo)}
                color="primary"
              />
            }
            label="Ativo?"
            labelPlacement="start"
            style={{ marginRight: 10 }}
          />
        </FormGroup>}
      </Grid>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  padding: 10px 0;
  min-height: 530px;
`;

const FormGroup = styled(Grid)`
  margin-bottom: 10px !important;
`;

export default DadosBase;
