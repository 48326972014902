import React, {useState} from "react";
import DadosBasicosContratante from "./dadosBasicosContratante";
import DadosEnderecoContratante from "./dadosEnderecoContratante";
import DadosImportacaoContratante from "./dadosImportacaoContratante";
import Grid from "@material-ui/core/Grid";
import styled from "@emotion/styled";
import Colors from "../../../../theme/Colors";
import MobileStepper from "@material-ui/core/MobileStepper";
import DadosResponsavelContratante from "./dadosResponsavelContratante";
import DadosEspecialidadesContratante from "./dadosEspecialidadesContratante";

const StepperCriarVenda = ({dados, handleAtualizarDados, handleFinalizarVenda}) => {
  const [passoAtual, setPassoAtual] = useState(0);
  const [dadosInvalidos, setDadosInvalidos] = useState(true);

  const [formDataErrors, setFormDataErrors] = useState({
    responsavelNome: false,
    responsavelCargo: false,
    responsavelCpf: false,
    nomeFantasia: false,
    razaoSocial: false,
    cnpj: false,
    telefone: false,
    email: false,
    cep: false,
    nomeLogradouro: false,
    numero: false,
    bairro: false,
    municipio: false,
    estado: false,
    tempoFuncionamentoEmpresa: false,
    numeroPacientes: false,
    haveraMigracaoSistema: false,
    sistemaASerMigrado: false
  });

  const atualizarDadosErrors = (dadosAtualizadosErrors) => {
    setFormDataErrors({
      ...formDataErrors,
      ...dadosAtualizadosErrors
    });
  };

  let proximoPasso = () => {
    setPassoAtual((prevPassoAtual) => prevPassoAtual + 1)
  };

  let voltar = () => {
    setPassoAtual((prevPassoAtual) => prevPassoAtual - 1)
  };

  const renderButton = (label, handleFunction, disabled) => (
    <>
      <Button type="button" disabled={disabled} onClick={handleFunction}>{label}</Button>
    </>
  );

  const verificaDadosInvalidos = (isDadosValidos) => {
    setDadosInvalidos(isDadosValidos);
  };

  return (
    <FlexGrid container direction="row" spacing={2}>

      <Passos item xs={11} sm={11} md={11} ls={11}>
        {passoAtual === 0 && <DadosResponsavelContratante dados={dados} dadosErrors={formDataErrors}
                                                          handleAtualizarDadosErrors={atualizarDadosErrors}
                                                          handleAtualizarDados={handleAtualizarDados}
                                                          handleDadosInvalidos={verificaDadosInvalidos}
                                                          disabled={false}/>}
        {passoAtual === 1 && <DadosBasicosContratante dados={dados} dadosErrors={formDataErrors}
                                                      handleAtualizarDadosErrors={atualizarDadosErrors}
                                                      handleAtualizarDados={handleAtualizarDados}
                                                      handleDadosInvalidos={verificaDadosInvalidos} disabled={false}/>}
        {passoAtual === 2 && <DadosEnderecoContratante dados={dados} dadosErrors={formDataErrors}
                                                       handleAtualizarDadosErrors={atualizarDadosErrors}
                                                       handleAtualizarDados={handleAtualizarDados}
                                                       handleDadosInvalidos={verificaDadosInvalidos} disabled={false}/>}
        {passoAtual === 3 && <DadosEspecialidadesContratante dados={dados}
                                                             handleAtualizarDados={handleAtualizarDados}
                                                             handleDadosInvalidos={verificaDadosInvalidos}
                                                             disabled={false}/>}
        {passoAtual === 4 && <DadosImportacaoContratante dados={dados} dadosErrors={formDataErrors}
                                                         handleAtualizarDadosErrors={atualizarDadosErrors}
                                                         handleAtualizarDados={handleAtualizarDados}
                                                         handleDadosInvalidos={verificaDadosInvalidos}
                                                         disabled={false}/>}

      </Passos>

      <FormGroup item xs={11}>
        <ButtonsContainer>
          <Guia
            variant="progress"
            steps={5}
            activeStep={passoAtual}
            position={'bottom'}
            backButton={
              renderButton("Voltar", voltar, ((passoAtual - 1) < 0))
            }
            nextButton={
              passoAtual < 4 ?
                renderButton("Próximo", proximoPasso, dadosInvalidos)
                :
                renderButton("Finalizar", handleFinalizarVenda, dadosInvalidos)
            }
          />
        </ButtonsContainer>
      </FormGroup>
    </FlexGrid>
  )
};

export default StepperCriarVenda;

const FlexGrid = styled(Grid)`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

`;

const FormGroup = styled(Grid)`
  margin: 0 auto !important;
`;

const Passos = styled(FormGroup)`
   @media(max-height: 760px) {
     max-height: 500px;
     overflow: hidden scroll;
   }
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

`;

const Button = styled.button`
  border: 0;
  background-color: ${Colors.primary.main};
  color: #fff;
  padding: 5px 10px;
  box-sizing: border-box;
  height: 34px;
  border-radius: 6px;
  margin: 8px 0;
  cursor: pointer;
  &:disabled {
    background-color: ${Colors.commons.gray};
  }
`;

const Guia = styled(MobileStepper)`
    width: 600;
    flex-grow: 1;
    align-self: center;
    justify-content: center;
`;
