import React, { useEffect, useState } from "react";
import { useApolloClient } from 'react-apollo';
import Grid from "@material-ui/core/Grid"
import styled from "@emotion/styled";
import Typography from '@material-ui/core/Typography';
import RenderTextField from "../../../../components/TextField/RenderTextField";
import TextFieldSearch from '../../../../components/TextField/TextFieldSearch';
import { DROPDOWN_UF } from "../../../../graphql/queryes";

const DadosConselho = ({ dados, handleAtualizarDados, handleAtualizarDadosErrors, dadosErrors, handleDadosInvalidos, disabled }) => {

  const [dadosTemp, setDadosTemp] = useState({
    numeroConselho: dados.numeroConselho,
    ufConselho: dados.ufConselho,
  });

  const client = useApolloClient();

  const handleChangeField = (campo, value) => {
    setDadosTemp({
      ...dadosTemp,
      [campo]: value
    });

    handleAtualizarDadosErrors({
      [campo]: !value
    });

    handleAtualizarDados({
      [campo]: value
    });

  };

  const handleLoadMoreOptions = async ({ variables, query, responseObject, }) => {
    const response = await client.query({
      query,
      variables,
    });

    const pageNumber = variables?.searchDTO?.pageNumber || 0;

    return {
      options: response?.data?.[responseObject] || [],
      hasMore: false,
      additional: {
        page: pageNumber + 1,
      },
    };
  };


  const handleUFLoadOptions = async (loadedOptions) => {
    return handleLoadMoreOptions({
      loadedOptions,
      variables: {},
      responseObject: 'findAllEstadoList',
      query: DROPDOWN_UF,
    });
  };

  const isCamposPreenchidos = () => {
    return dadosTemp.numeroConselho === '' ||
      !dadosTemp.ufConselho
  };

  useEffect(() => {
    handleDadosInvalidos(isCamposPreenchidos());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleAtualizarDados]);

  return (
    <Container>
      <Grid container direction="row" spacing={2}>
        <FormGroup item xs={12} sm={12} md={12} ls={12}>
          <RenderTextField label={"Número Conselho *"}
            field={dadosTemp.numeroConselho}
            error={dadosErrors?.numeroConselho}
            errorText={'Este campo é obrigatório!'}
            mask={'numeroSemPontuacao'}
            handleChange={(e) => handleChangeField('numeroConselho', e.target.value)}
            disabled={disabled} />
        </FormGroup>
        <FormGroup item xs={12} sm={12} md={12} ls={12}>
          <>
            <Typography color="primary" component="label">
              {"UF *"}
            </Typography>
            < TextFieldSearch placeholder=""
              loadOptions={handleUFLoadOptions}
              withPaginate
              value={dadosTemp.ufConselho}
              onChange={e => handleChangeField('ufConselho', e)}
              debounceTimeout={300}
              additional={{
                page: 0
              }}
            />
          </>
        </FormGroup>
      </Grid>
    </Container >
  );
};

const Container = styled.div`
  width: 100%;
  padding: 10px 0;
  min-height: 450px;
`;

const FormGroup = styled(Grid)`
  margin-bottom: 10px !important;
`;

export default DadosConselho;
