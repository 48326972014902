import React from 'react';
import { ThemeProvider } from 'emotion-theming';
import { Provider as ProviderRedux } from 'react-redux';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import 'moment/locale/pt-br';

import store from './redux/store';
import Router from './router';
import theme from './theme';
import './theme/CSSReset.css';
import GlobalStyles from './theme/GlobalStyles';
import MuiTheme from './theme/MuiTheme';

const locale = 'pt-br';

const App = () => {
  return (
    <ProviderRedux store={store}>
      <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={locale}>
        <MuiTheme>
          <ThemeProvider theme={theme}>
            <GlobalStyles />
            <Router />
          </ThemeProvider>
        </MuiTheme>
      </MuiPickersUtilsProvider>
    </ProviderRedux>
  );
};

export default App;
