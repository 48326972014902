import React, { useState } from 'react';
import styled from '@emotion/styled';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import Colors from '../../../../theme/Colors';
import { useMutation } from 'react-apollo';
import { CRIAR_PROFISSIONAL_SAUDE } from '../../../../graphql/mutations';
import string from '../../../../utils/string';
import DadosPossuiAgenda from './dadosPossuiAgenda';
import DadosEndereco from './dadosEndereco';
import DadosEspecialidade from './dadosEspecialidade';
import MobileStepper from "@material-ui/core/MobileStepper";
import DadosBase from './dadosBase';
import DadosConselho from './dadosConselho';
import Notification from '../../../../components/notification/Notification';
import Close from '@material-ui/icons/Close';
import { LIST_ESPECIALIDADES_MEDICAS } from '../../../../graphql/queryes';
import { useQuery } from "@apollo/react-hooks";
import DadosBancarios from './dadosBancarios';

const notificationDefault = {
  variant: '',
  message: '',
  isOpen: false,
};

const StepperCriarProfissionalSaude = ({ dados, onClose, onCreate, criandoProfissional}) => {
  const [notification, setNotification] = useState({ ...notificationDefault, });
  const [passoAtual, setPassoAtual] = useState(0);
  const [criando, setCriando] = useState(false);
  const [search, setSearch] = useState('');
  const responseEspecialidades = useQuery(
    LIST_ESPECIALIDADES_MEDICAS, {
      fetchPolicy: 'no-cache',
      variables: {
        search: {
          search: search
        },
        pageable: {
          pageSize: 53,
          pageNumber: 0
        }
      }
    }
  );

  const [formData, setFormData] = useState({
    nome: '',
    cpf: '',
    cns: '',
    dataNascimento: null,
    email: '',
    numeroConselho: '',
    ufConselho: null,
    grupoPermissoes: null,
    especialidades: [],
    possuiAgenda: false,
    utilizaAssinaturaDigital: true,
    utilizaTelemedicina: true,
    utilizaSecretariaVirtual: true,
    endereco: {
      nomeLogradouro: '',
      numero: '',
      bairro: '',
      cep: '',
      complemento: '',
      municipio: null,
      estado: null
    },
    dadoBancario: {
      banco: null,
      tipoConta: null,
      conta: '',
      operacao: '',
      digitoVerificador: '',
      agencia: '',
      digitoAgencia: '',
      documento: ''
    }
  });
  const [criarProfissionalSaudeSemAgenda] = useMutation(CRIAR_PROFISSIONAL_SAUDE);
  const [formDataErrors, setFormDataErrors] = useState({
    possuiAgenda: false,
    cardNumber: false,
    holder: false,
    expirationDate: false,
    securityCode: false,
    nome: false,
    cpf: false,
    dataNascimento: false,
    email: false,
    especialidades: false,
    banco: false,
    tipoConta: false,
    conta: false,
    operacao: false,
    digitoVerificador: false,
    agencia: false,
    digitoAgencia: false,
    documento: false
  });
  const [dadosInvalidos, setDadosInvalidos] = useState(true);

  const atualizarDadosErrors = (dadosAtualizadosErrors) => {
    setFormDataErrors({
      ...formDataErrors,
      ...dadosAtualizadosErrors
    });
  };

  const handleSubmit = async e => {
    e.preventDefault();

    try {
      setCriando(true);
      const dadosProfissional = {
        nome: formData.nome,
        cpf: string.removeSpecialChars(formData.cpf),
        cns: formData.cns,
        dataNascimento: moment(formData.dataNascimento).format(
          'YYYY-MM-DD'
        ),
        tipo: formData.tipo,
        numeroConselho: formData.numeroConselho,
        ufConselho: formData.ufConselho?.value,
        possuiAgenda: formData.possuiAgenda,
        especialidades: formData.especialidades?.map(e => ({ id: e.value })),
        email: formData.email,
        utilizaAssinaturaDigital: formData.utilizaAssinaturaDigital,
        utilizaTelemedicina: formData.utilizaTelemedicina,
        utilizaSecretariaVirtual: formData.utilizaSecretariaVirtual
      };
      const dadosBase = {
        venda: {
          id: dados.id,
        },
        grupoPermissoes: {
          id: formData.grupoPermissoes?.value,
        },
        profissionalSaude: dadosProfissional
      };

        await criarProfissionalSaudeSemAgenda({
          variables: dadosBase
        });

      onCreate && onCreate();
      handleClose();
    } catch ({ graphQLErrors }) {
      setCriando(false);
      if (graphQLErrors) {
        setNotification({
          variant: 'error',
          isOpen: true,
          message: graphQLErrors[0].message,
        });
      }
    }
  };

  const handleLoadEspecialidades = () => {
    return responseEspecialidades?.data?.findAllEspecialidadeMedica?.content;
  };

  const handleEspecialidadesSearch = (search) => {
    setSearch(search);
    responseEspecialidades.refetch()
  };

  const handleClose = () => {
    onClose && onClose();
  };

  const handleAtualizarDados = (dadosAtualizados) => {
    setFormData({
      ...formData,
      ...dadosAtualizados
    });
  };

  const verificaDadosInvalidos = (isDadosValidos) => {
    setDadosInvalidos(isDadosValidos);
  };

  const proximoPasso = () => {
    setPassoAtual((prevPassoAtual) => {
      if (prevPassoAtual === 0 && !formData.possuiAgenda)
        return 1;
      if (prevPassoAtual === 1 && !formData.utilizaTelemedicina)
        return 3;

      return prevPassoAtual + 1
    })
  };

  const voltar = () => {
    setPassoAtual((prevPassoAtual) => {
      if (prevPassoAtual === 2 && !formData.possuiAgenda)
        return 0;
      if (prevPassoAtual === 3 && !formData.utilizaTelemedicina)
        return 1;

      return prevPassoAtual - 1
    })
  };

  const renderButton = (label, handleFunction, disabled) => (
    <>
      <Button type="button" disabled={disabled} onClick={handleFunction}>{label}</Button>
    </>
  );

  const alcancouLimiteMaximoProfissionaisSaude = dados.unidade.profissionaisSaude?.filter((item) => item.possuiAgenda).length >= dados.quantidadeProfissionaisSaudeComAgenda;

  return (
    <FlexGrid direction="row" spacing={2}>
      <Header>
        <CloseStyled onClick={handleClose} />
        <HeaderTitle>Adicionar Profissional de saúde</HeaderTitle>
      </Header>

      <Passos item xs={11} sm={11} md={11} ls={11}>
        {passoAtual === 0 && <DadosPossuiAgenda dados={formData}
          handleDadosInvalidos={verificaDadosInvalidos}
          handleAtualizarDados={handleAtualizarDados}
          disabled={alcancouLimiteMaximoProfissionaisSaude} />}

        {passoAtual === 1 && <DadosBase dados={formData} unidade={dados.unidade} dadosErrors={formDataErrors}
          handleAtualizarDadosErrors={atualizarDadosErrors}
          handleAtualizarDados={handleAtualizarDados}
          criandoProfissional={criandoProfissional}
          handleDadosInvalidos={verificaDadosInvalidos} />}

        {passoAtual === 2 && <DadosBancarios dados={formData}
          unidade={dados.unidade} dadosErrors={formDataErrors}
          handleAtualizarDadosErrors={atualizarDadosErrors}
          handleAtualizarDados={handleAtualizarDados}
          handleDadosInvalidos={verificaDadosInvalidos} />}

        {passoAtual === 3 && <DadosEndereco dados={formData.endereco} campoEndereco={'endereco'}
          unidade={dados.unidade} dadosErrors={formDataErrors}
          handleAtualizarDadosErrors={atualizarDadosErrors}
          handleAtualizarDados={handleAtualizarDados}
          handleDadosInvalidos={verificaDadosInvalidos} />}

        {passoAtual === 4 && <DadosConselho dados={formData} dadosErrors={formDataErrors}
          handleAtualizarDadosErrors={atualizarDadosErrors}
          handleAtualizarDados={handleAtualizarDados}
          handleDadosInvalidos={verificaDadosInvalidos} />}

        {passoAtual === 5 && <DadosEspecialidade dadosEspecialidades={handleLoadEspecialidades()} onSearch={handleEspecialidadesSearch}
          handleAtualizarDados={handleAtualizarDados}
          handleDadosInvalidos={verificaDadosInvalidos} />}
      </Passos>

      <div>
        <ButtonsContainer>
          <Guia
            variant="progress"
            steps={formData.possuiAgenda ? 6 : 2}
            activeStep={passoAtual}
            position={'bottom'}
            backButton={
              renderButton("Voltar", voltar, ((passoAtual - 1) < 0))
            }
            nextButton={

              passoAtual === 5 || (passoAtual === 1 && !formData.possuiAgenda) ?
                renderButton("Finalizar", handleSubmit, dadosInvalidos || criando)
                :
                renderButton("Proximo", proximoPasso, dadosInvalidos)
            }
          />
        </ButtonsContainer>
      </div>
      <Notification
        close={() => {
          setNotification({
            ...notificationDefault,
          });
        }}
        reset={() => {
          setNotification({
            ...notificationDefault,
          });
        }}
        isOpen={notification.isOpen}
        variant={notification.variant}
        message={notification.message}
      />
    </FlexGrid>
  );
};

const FlexGrid = styled(Grid)`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 30px 0;
`;

const FormGroup = styled(Grid)`
  margin: 0 auto !important;
`;

const Passos = styled(FormGroup)`
   @media(max-height: 760px) {
     max-height: 500px;
     overflow: hidden scroll;
   }
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex-basis: 0;
`;

const Button = styled.button`
  border: 0;
  background-color: ${Colors.primary.main};
  color: #fff;
  padding: 5px 10px;
  box-sizing: border-box;
  height: 34px;
  border-radius: 6px;
  margin: 8px 0;
  cursor: pointer;

  &:disabled {
    background-color: ${Colors.commons.gray};
  }
`;

const Guia = styled(MobileStepper)`
    width: 600;
    flex-grow: 1;
    align-self: center;
    justify-content: center;
`;

const Header = styled.div`
  height: 45px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;
  padding-left: 20px;
`;

const HeaderTitle = styled.div`
  font-size: 18px;
  text-align: left;
  color: ${Colors.primary.main};
  margin-top: 4px;
  margin-left: 10px;
`;

const CloseStyled = styled(Close)`
  color: ${Colors.primary.main};
  cursor: pointer;
`;

export default StepperCriarProfissionalSaude;
