import React, {useState} from 'react';
import Grid from "@material-ui/core/Grid";
import InputSearch from '../../../components/input/Input'
import {getVendaStatusList} from '../../../enum/VendaStatus';
import {useQuery} from 'react-apollo';
import {DROPDOWN_ENTIDADES} from '../../../graphql/queryes';
import {SelectSearch} from '../../../components/select/SelectSearch';
import styled from '@emotion/styled';

const Search = ({onDataSearch}) => {
  const [vendaStatusSelected, setVendaStatusSelected] = useState();
  const [entidadeSelected, setEntidadeSelected] = useState();
  const [typingTimeout, setTypingTimeout] = useState();
  const [searchText, setSearchText] = useState('');

  const {data: dropdownEntidadesData} = useQuery(DROPDOWN_ENTIDADES, {
    variables: {
      searchDTO: {},
      pageableDTO: {}
    }
  });

  const handleChangeInput = (field, e) => {
    if (field === 'vendaStatus') {
      setVendaStatusSelected(e);
      onDataSearch({
        vendaStatus: e && e.value ? e.value : undefined,
      });
    }

    if (field === 'entidade') {
      setEntidadeSelected(e);
      onDataSearch({
        entidade: e && e.value ? {id: e.value, tipo: e.tipo || 'FISICA'} : undefined,
      });
    }
  }

  const handleSearchTextChange = (e) => {
    const text = e.target.value;
    setSearchText(text);

    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    setTypingTimeout(setTimeout(() => {
      onDataSearch({
        search: text,
      });
    }, 500));
  }

  const entidades = dropdownEntidadesData?.findAllEntidadeByOauthClient?.content || [];

  return (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <SelectSearchStyled
          placeholder="Status da Venda"
          value={vendaStatusSelected}
          elements={[...getVendaStatusList()]}
          onChange={(e) => handleChangeInput('vendaStatus', e)}
        />
      </Grid>
      <Grid item xs={3}>
        <SelectSearchStyled
          placeholder="Entidade"
          value={entidadeSelected}
          elements={entidades}
          onChange={(e) => handleChangeInput('entidade', e)}
        />
      </Grid>
      <Grid item xs={4}>
        <InputSearch onChange={handleSearchTextChange} value={searchText} placeholder="Pesquisar"/>
      </Grid>
    </Grid>
  );
};

const SelectSearchStyled = styled(SelectSearch)`
	width: 100%;
`;

export default Search;
