import Typography from "@material-ui/core/Typography";
import React from "react";
import styled from "@emotion/styled";
import {SelectSearch} from "../../../components/select/SelectSearch";

const SelectSearchEntidade = ({label, field, value, fieldChange, elements, formEditavel}) => {

  return (
    <>
      <Typography color="primary" component="label">
        {label}
      </Typography>
      <SelectSearchStyled
        placeholder=""
        elements={elements}
        value={value}
        onChange={event => fieldChange(field, event)}
        isDisabled={formEditavel}
      />
    </>
  )
};

const SelectSearchStyled = styled(SelectSearch)`
	width: 100%;
`;

export default SelectSearchEntidade;
