export const colors = {
  transparent: 'transparent',
  current: 'currentColor',
  black: '#000',
  white: '#fff',
  text: '#575757',
  borderDefault: '#e7e7e7',
  bgApp: '#fff',

  primary: {
    300: '#97caeb',
    500: '#0061b0',
    700: '#001f60',
  },

  secondary: {
    300: '#b7ea7f',
    500: '#70a400',
    700: '#707200',
  },

  tertiary: {
    300: '#ff6065',
    500: '#e81c24',
    700: '#b61c24',
  },

};

export const fontWeights = {
  hairline: 100,
  thin: 200,
  light: 300,
  normal: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
  extrabold: 800,
  black: 900,
};

export const lineHeights = {
  normal: 'normal',
  none: '1',
  shorter: '1.25',
  short: '1.375',
  base: '1.5',
  tall: '1.625',
  taller: '2',
};

export const fontSizes = {
  xs: '0.688em', // 11px
  sm: '0.875rem', // 14px
  md: '1rem', // 16px
  lg: '1.125rem', // 18px
  xl: '1.25rem', // 20px
  '2xl': '1.375rem', // 22px
  '3xl': '1.5em', // 24px
  '4xl': '1.875rem', // 30px
  '5xl': '2.25rem', // 36px
  '6xl': '3rem', // 48px
};

const baseSizes = {
  0: '0', // 0
  1: '0.25rem', // 4
  2: '0.5rem', // 8
  3: '0.75rem', // 12
  4: '1rem', // 16
  5: '1.25rem', // 20
  6: '1.5rem', // 24
  8: '2rem', // 32
  10: '2.5rem', // 40
  11: '2.75rem', // 44
  12: '3rem', // 48
  16: '4rem', // 64
  20: '5rem', // 80
  24: '6rem', // 96
  32: '8rem', // 128
  40: '10rem', // 160
  48: '12rem', // 192
  56: '14rem', // 224
  64: '16rem', // 256
};

const largeSizes = {
  full: '100%',
  '3xs': '14rem',
  '2xs': '16rem',
  menu: '17.250em',
  modal: '22.7rem',
  star: '11px',
  xs: '20rem',
  sm: '24rem',
  md: '28rem',
  lg: '32rem',
  xl: '36rem',
  '2xl': '42rem',
  '3xl': '48rem',
  '4xl': '56rem',
  '5xl': '64rem',
  '6xl': '72rem',
};

export const space = [0, 4, 8, 16, 24, 32, 48, 64, 128, 256, 512];

export const sizes = {
  ...baseSizes,
  ...largeSizes,
};

export const radii = {
  full: '100%',
  md: '4px',
};
