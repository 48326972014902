import React, { useEffect, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { DASHBOARD } from './names';

const AwaitLogin = ({ children }) => {
	const history = useHistory();

	const verifyAuthorization = () => {
		const auth = window.localStorage.getItem("token");
		if (!!auth) {
			history.replace(DASHBOARD);
		}
	};

	useEffect(() => {
		verifyAuthorization();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<Fragment>
			{children}
		</Fragment>
	);
};

export default AwaitLogin;