import React, { useState } from 'react';
import styled from '@emotion/styled'
import {useMutation} from 'react-apollo';
import { useParams } from 'react-router-dom';
import moment from "moment";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextFieldCheckout from '../../../components/TextField/TextFieldCheckout';
import { ADICIONAR_CARTAO_CREDITO_VENDA_CLIENTE_PAGAMENTO } from '../../../graphql/mutations';
import Notification from '../../../components/notification/Notification';
import Number from '../../../utils/number';
import string from '../../../utils/string';
import {cpfCnpjFormatted, phoneFormatted, cepFormatted} from '../../../utils/masks';
import enterpriseIcon from '../../../assets/images/enterpriseIcon.svg';
import logoWhite from '../../../assets/images/logoWhite.svg';
import mailIcon from '../../../assets/images/mailIcon.svg';
import phoneIcon from '../../../assets/images/phoneIcon.svg';
import walletIcon from '../../../assets/images/walletIcon.svg';
import adressIcon from '../../../assets/images/adressIcon.svg';
import creditCardIcon from '../../../assets/images/creditCardIcon.svg';

const notificationDefault = {
	variant: '',
	message: '',
	isOpen: false,
};

const VerificarAcesso = ({dadosVenda, handlePagamentoSucesso}) => {
	const [loadingSubmit, setLoadingSubmit] = useState(false);
	const [notification, setNotification] = useState({
		...notificationDefault,
	});

  const [adicionarCartaoCreditoAVendaClientePagamento] = useMutation(ADICIONAR_CARTAO_CREDITO_VENDA_CLIENTE_PAGAMENTO);

  const {vendaId} = useParams();

  const [formData, setFormData] = useState({
    cardNumber: '',
    holder: '',
    expirationDate: null,
    securityCode: ''
  });

  const [dadosErrors, setDadosErrors] = useState({
    cardNumber: '',
    holder: '',
    expirationDate: null,
    securityCode:  ''
  });

  const handleChangeCartaoCreditoField = (campo, value) => {
    setFormData({
      ...formData,
      [campo]: value
    });

    setDadosErrors({
      ...dadosErrors,
      [campo]: campo === "cardNumber" ? !string.validaCartaoCredito(value) : !value
    })
  };

  const verificaCamposPreenchidos = () => {
    return !string.validaCartaoCredito(formData.cardNumber) ||
      !formData.holder ||
      !formData.expirationDate ||
      !formData.securityCode
  };

  const handleSubmitCartaoCredito = async (e) => {
    e.preventDefault();
    if (verificaCamposPreenchidos()) {
      setNotification({
        variant: 'error',
        isOpen: true,
        message: 'Por favor, verifique os campos do cartão de crédito.',
      });
      return
    }

    try {
      setLoadingSubmit(true);
      const cartaoCredito = {
        cardNumber: string.removeSpecialChars(formData.cardNumber),
        holder: formData.holder,
        expirationDate: moment(string.removeSpecialChars(formData.expirationDate),'MMYYYY').startOf('month').format('YYYY-MM-DD'),
        securityCode: formData.securityCode,
      };

      await adicionarCartaoCreditoAVendaClientePagamento({
				variables: {
					cartaoCredito,
					vendaId
				}
			});

      setNotification({
        variant: 'success',
        isOpen: true,
        message: 'Cartão cadastrado com sucesso.',
      });

      handlePagamentoSucesso && handlePagamentoSucesso();
    } catch ({graphQLErrors}) {
      if (graphQLErrors) {
        setNotification({
          variant: 'error',
          isOpen: true,
          message: graphQLErrors[0].message,
        });
      }
    }
    setLoadingSubmit(false);
  };

	return (
		<PageContainer>
      <InformacoesPagamento>
        <Header>
          <Logo src={logoWhite} alt="App Health Logo"/>
        </Header>

        <Content>

          <TituloPrincipal>
            Checkout
          </TituloPrincipal>

          <DadosContent>
            <DadosClinicaEProduto>
              <Title> Dados da clínica </Title>
              <Label> {dadosVenda?.contratante?.nomeFantasia} </Label>
              <Label> <Icon src={enterpriseIcon}/> CNPJ: {cpfCnpjFormatted(dadosVenda?.contratante?.cnpj)}  </Label>
              <Label> <Icon src={phoneIcon}/> {phoneFormatted(dadosVenda?.contratante?.telefone)} </Label>
              <Label> <Icon src={mailIcon}/> {dadosVenda?.contratante?.email} </Label>
              <Label> <Icon src={adressIcon}/>{
                `${dadosVenda?.contratante?.endereco?.nomeLogradouro},
                ${dadosVenda?.contratante?.endereco?.numero},
                ${dadosVenda?.contratante?.endereco?.bairro},
                ${dadosVenda?.contratante?.endereco?.municipio?.nome}-${dadosVenda?.contratante?.endereco?.municipio?.uf},
                ${cepFormatted(dadosVenda?.contratante?.endereco?.cep)}`}
              </Label>
            </DadosClinicaEProduto>

            <DadosClinicaEProduto>
              <Title> Dados do produto </Title>
              <Item>
                Produto:
                <Label> <Icon src={enterpriseIcon}/> {dadosVenda?.plano?.nome} </Label>
              </Item>
              <Item>
                Venda realizada por:
                <Label> {dadosVenda?.vendedor?.nome} </Label>
              </Item>
              <Item>
                Modalidade de pagamento:
                <Label> <Icon src={walletIcon}/> Recorrente </Label>
              </Item>
              <Item>
                Valor do investimento:
                <LabelValorPlano> {Number.currencyFormat(dadosVenda?.valor)} / mês </LabelValorPlano>
              </Item>
              <Item>
                Forma de Pagamento
                <Label> <Icon src={creditCardIcon}/> Cartão de Crédito </Label>
              </Item>

            </DadosClinicaEProduto>
          </DadosContent>

        </Content>
      </InformacoesPagamento>

      <Checkout>
        <Form>
          <TituloCheckout> Pagamento </TituloCheckout>

          <TextFieldCheckout
            label={'Nome igual ao do cartão:'}
            variant="outlined"
            color={"white"}
            field={formData.holder}
            maxLength={50}
            handleChange={(e) => handleChangeCartaoCreditoField('holder', e.target.value)}
          />

          <TextFieldCheckout
            label={'Número do cartão:'}
            variant="outlined"
            color={"white"}
            mask={'creditCard'}
            field={formData.cardNumber}
            handleChange={(e) => handleChangeCartaoCreditoField('cardNumber', e.target.value)}
          />

          <ValidadeECvc>
            <TextFieldCheckout
              label={'Validade:'}
              variant="outlined"
              color={"white"}
              field={formData.expirationDate}
              mask={'dataValidade'}
              handleChange={(e) => handleChangeCartaoCreditoField('expirationDate', e.target.value)}
            />

            <TextFieldCheckout
              label={'CVC:'}
              variant="outlined"
              color={"white"}
              field={formData.securityCode}
              mask={'creditCardSecureCode'}
              handleChange={(e) => handleChangeCartaoCreditoField('securityCode', e.target.value)}
            />
          </ValidadeECvc>

          <SubmitButton onClick={handleSubmitCartaoCredito} disabled={loadingSubmit}>
            Confirmar
            {loadingSubmit && (
              <CircularProgressStyled
                color="inherit"
                size={12}
              />
            )}
          </SubmitButton>
        </Form>
      </Checkout>

      <Notification
				close={() => {
					setNotification({
						...notificationDefault,
					});
				}}
				reset={() => {
					setNotification({
						...notificationDefault,
					});
				}}
				isOpen={notification.isOpen}
				variant={notification.variant}
				message={notification.message}
			/>
		</PageContainer>
	)
};

const PageContainer = styled.div`
	display: flex;
  width: 100%;
  height: 100vh;
  overflow: hidden scroll;
  @media(max-width: 750px) {
    width: 100%;
    flex-direction: column;
    overflow: hidden scroll;
  }
`;

const InformacoesPagamento = styled.div`
  display: flex;
  flex-direction: column;
	width: 70%;
  @media(max-width: 750px) {
    width: 100%;
  }
`;

const Form = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
  height: 100%;
  margin: 0 10% 20% 10%;
  justify-content: center;
`;

const Logo = styled.img`
	width: 180px;
  margin-left: 5%;
`;

const Icon = styled.img`
  max-height: 20px;
  margin-right: 8px;
`;

const Label = styled.p`
  display: flex;
  flex-direction: row;
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 14px;
  color: #505050;
  align-items: center;
`;

const LabelValorPlano = styled(Label)`
  font-size: 24px;
  color: #219A97;
  font-weight: 900;
  margin: 4px;
`;

const Item = styled.h3`
  font-family: 'Poppins';
  font-weight: 700;
  font-size: 14px;
  color: #505050;
  margin: 2px;
`;

const Header = styled.div`
  display: flex;
	width: 100%;
  height: 120px;
  background-color: #34A3A0;
  align-content: center;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
	width: 100%;
  height: 100%;
  margin-left: 8%;
`;

const DadosContent = styled.div`
  display: flex;
  flex-direction: row;
	width: 100%;
  height: 100%;
  @media(max-width: 750px) {
    flex-direction: column;
  }
`;

const DadosClinicaEProduto = styled.div`
  display: flex;
  flex-direction: column;
	width: 80%;
  height: 100%;
  margin-right: 20px;
`;

const ValidadeECvc = styled.div`
  display: flex;
  flex-direction: row;
`;

const Checkout = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  background-color: #505050;
`;

const Title = styled.h1`
  font-family: 'Poppins';
  font-weight: 900;
  font-size: 24px;
  color: #505050;
  margin-bottom: 18px;
`;

const TituloPrincipal = styled(Title)`
  font-size: 54px;
`;

const TituloCheckout = styled(Title)`
  color: #FFF;
`;

const SubmitButton = styled.button`
	margin-top: 12px;
	color: #fff;
	font-size: 14px;
	border: none;
	outline: none;
	cursor: pointer;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  position: static;
  width: 100%;
  height: 56px;
  left: 0px;
  top: 327px;
  background: #219A97;
  box-shadow: 12px 16px 24px rgba(0, 0, 0, 0.1);
  border-radius: 100px;
`;

const CircularProgressStyled = styled(CircularProgress)`
	margin-left: 10px;
`;

export default VerificarAcesso;
