import { createSlice } from '@reduxjs/toolkit';
import { logoutUrl } from '../../services/axios';
import { DASHBOARD, SELECIONA_ENTIDADE } from '../../router/names';
import { obterUsuarioLogadoEEntidadesAtivas } from '../../services/usuario-service';
import { apisauceAuth, RedefinirSenhaApi } from '../../services/apisauce';

const initialState = {
	usuarioLogado: null,
	name: '',
	token: '',
	refreshToken: '',
	entidadesAtivasDoUsuario: [],
	entidadeAtual: null,
	error: null,
	success: null,
	redefinirSenhaToken: null,
};

const loginOrRefreshSuccessReducer = (state, { payload }) => {
	const { access_token, refresh_token, usuarioLogado } = payload;

	const entidadeAtual = usuarioLogado?.entidadeAtual || null;

	state.error = null;
	state.usuarioLogado = usuarioLogado;
	state.token = access_token;
	state.refreshToken = refresh_token;
	state.entidadeAtual = entidadeAtual;

	window.localStorage.setItem("usuarioLogado", JSON.stringify(usuarioLogado));
	window.localStorage.setItem("entidadeAtual", JSON.stringify(entidadeAtual));
	window.localStorage.setItem("token", access_token);
	window.localStorage.setItem("refreshToken", refresh_token);
};

const loginOrRefreshFailedReducer = (state, { payload }) => {
	state.usuarioLogado = null;
	state.entidadeAtual = null;
	state.token = '';
	state.refreshToken = '';
	state.error = payload;
	state.entidadesAtivasDoUsuario = [];
};

const recuperarSenhaFailedReducer = (state, { payload }) => {
	state.error = payload;
};

const recuperarSenhaSuccessReducer = (state, { payload }) => {
	state.success = payload;
};

const redefinirSenhaTokenReducer = (state, { payload }) => {
	state.redefinirSenhaToken = payload;
};

const logoutSuccessReducer = (state) => {
	state.usuarioLogado = null;
	state.entidadeAtual = null;
	state.error = null;
	state.token = '';
	state.refreshToken = '';
	state.entidadesAtivasDoUsuario = [];
	window.localStorage.clear();
};

const hydrateSessionReducer = (state, { payload }) => {
	const { token, refreshToken, usuarioLogado, entidadeAtual, entidadesAtivasDoUsuario } = payload;

	state.usuarioLogado = JSON.parse(usuarioLogado);
	state.entidadeAtual = JSON.parse(entidadeAtual);
	state.entidadesAtivasDoUsuario = JSON.parse(entidadesAtivasDoUsuario) || [];
	state.token = token;
	state.refreshToken = refreshToken;
};

const salvarEntidadesAtivasDoUsuarioReducer = (state, { payload }) => {
	state.entidadesAtivasDoUsuario = payload || [];
	window.localStorage.setItem("entidadesAtivasDoUsuario", JSON.stringify(payload || []));
};

const salvarEntidadeAtualReducer = (state, { payload }) => {
	const entidadeAtual = payload || null;
	state.entidadeAtual = entidadeAtual;
	window.localStorage.setItem("entidadeAtual", JSON.stringify(entidadeAtual));
};

const clearErrorReducer = (state) => {
	state.error = null;
};
const clearSuccessReducer = (state) => {
	state.success = null;
};

const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		hydrateSession: hydrateSessionReducer,
		loginOrRefreshSuccess: loginOrRefreshSuccessReducer,
		loginOrRefreshFailed: loginOrRefreshFailedReducer,
		recuperarSenhaFailed: recuperarSenhaFailedReducer,
		recuperarSenhaSuccess: recuperarSenhaSuccessReducer,
		logoutSuccess: logoutSuccessReducer,
		clearError: clearErrorReducer,
		clearSuccess: clearSuccessReducer,
		salvarEntidadesAtivasDoUsuario: salvarEntidadesAtivasDoUsuarioReducer,
		salvarEntidadeAtual: salvarEntidadeAtualReducer,
		atualizaRedefinirSenhaToken: redefinirSenhaTokenReducer,
	}
});

export const {
	loginOrRefreshSuccess,
	loginOrRefreshFailed,
	recuperarSenhaFailed,
	hydrateSession,
	logoutSuccess,
	salvarEntidadesAtivasDoUsuario,
	salvarEntidadeAtual,
	clearError,
	clearSuccess,
	recuperarSenhaSuccess,
	atualizaRedefinirSenhaToken
} = authSlice.actions;

export const login = ({
	username,
	password,
	replaceRouter
}) => async dispatch => {
	try {
		dispatch(clearSuccess());
		dispatch(clearError());

		const { data, ok } = await apisauceAuth.post('/oauth/token', undefined, {
			params: {
				username,
				password,
				grant_type: "password",
				client_id: process.env.REACT_APP_CLIENT_ID,
			}
		});

		if (!ok) {
			throw new Error(data?.error_description || 'Ops! Ocorreu um erro.');
		}

		const {obterUsuarioLogado, obterEntidadesAtivasDoUsuario} = await obterUsuarioLogadoEEntidadesAtivas(data.access_token);

		dispatch(loginOrRefreshSuccess({
			...data,
			usuarioLogado: obterUsuarioLogado,
		}));

		if (obterUsuarioLogado.entidadeAtual) {
			dispatch(salvarEntidadesAtivasDoUsuario([]));
			replaceRouter(DASHBOARD);
		} else {
			dispatch(salvarEntidadesAtivasDoUsuario(obterEntidadesAtivasDoUsuario));
			replaceRouter(SELECIONA_ENTIDADE);
		}

	} catch (err) {
		dispatch(loginOrRefreshFailed(err?.message));
		window.localStorage.clear();
	}
};

export const logout = () => async dispatch => {
	try {
		const accessToken = window.localStorage.getItem('token');
		dispatch(logoutSuccess());
		await logoutUrl.get(`?token=${accessToken}`, {
			headers: {
				authorization: `bearer ${accessToken}`
			}
		});
	} catch (error) {
		console.log(error);
	}
};

export const recuperarSenha = ({
	username
}) => async dispatch => {
	try {
		dispatch(atualizaRedefinirSenhaToken(false));
		dispatch(clearError());
		dispatch(clearSuccess());

		const { data, ok } = await RedefinirSenhaApi.post('', {
			login: username,
			clientId: process.env.REACT_APP_CLIENT_ID,
		});

		if (ok) {
			dispatch(recuperarSenhaSuccess(`Enviamos para seu e-mail ${data.mensagem} o link para redefinir sua senha.`))
			return;
		}

		if (typeof data.mensagem === 'string') {
			throw new Error(data.mensagem);
		}
	} catch (err) {
		dispatch(recuperarSenhaFailed(err?.message));
	}
};

export const redefinirSenha = ({
	token,
	senha
}) => async dispatch => {
	try {
		dispatch(clearError());
		dispatch(clearSuccess());

		const { data, ok } = await RedefinirSenhaApi.put(`${token}`,
			{
				senha
			}
		);

		if (ok) {
			dispatch(recuperarSenhaSuccess('Sua senha foi redefinida com sucesso.'));
			return data;
		}

		if (typeof data.mensagem === 'string') {
			throw new Error(data.mensagem);
		}
	} catch (err) {
		dispatch(recuperarSenhaFailed(err?.message));
	}
};

export const validarRedefinirSenhaToken = (token) => async dispatch => {
	try {
		dispatch(atualizaRedefinirSenhaToken(null));
		dispatch(clearError());
		dispatch(clearSuccess());

		const { data, ok } = await RedefinirSenhaApi.get(`${token}`);

		if (ok) {
			dispatch(atualizaRedefinirSenhaToken(token));
			return data;
		}

		if (typeof data.mensagem === 'string') {
			throw new Error(data.mensagem);
		}
	} catch (err) {
		dispatch(recuperarSenhaFailed(err?.message));
	}
};

export const reducer = authSlice.reducer;
