import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid"
import styled from "@emotion/styled";
import { withStyles } from "@material-ui/core";
import Typography from '@material-ui/core/Typography';
import {useQuery} from "@apollo/react-hooks";
import {FIND_ALL_TIPO_PROFISSIONAL_SAUDE_LIST, LIST_ESPECIALIDADES_MEDICAS} from "../../../../graphql/queryes";
import {SelectSearch} from "../../../../components/select/SelectSearch";

const DadosPossuiAgenda = ({ dados, handleAtualizarDados, handleDadosInvalidos, disabled }) => {

  const [dadosTemp, setDadosTemp] = useState({
    possuiAgenda: dados.possuiAgenda || false
  });

  const [tipoSelecionado, setTipoSelecionado] = useState({
    label: "",
    value: "",
    descricao: ""
  });

  const {data: tiposProfissionalSaude} = useQuery(FIND_ALL_TIPO_PROFISSIONAL_SAUDE_LIST);

  const handleChangeField = (campo, value) => {
    if(value) {
      let tipoSelecionado = {
        value: value.value,
        label: value.label,
        descricao: tiposProfissionalSaude?.findAllTipoProfissionalSaudeList.filter(tipoProfissionalSaude => tipoProfissionalSaude.value === value.value)
          .map(tipoProfissionalSaude => tipoProfissionalSaude.descricao)
      }

      setTipoSelecionado(tipoSelecionado);

      setDadosTemp({
        [campo]: value.value,
        possuiAgenda: value.value === 1
      });

      handleAtualizarDados({
        [campo]: value.value,
        possuiAgenda: value.value === 1
      });
    }else{
      setTipoSelecionado(value);
    }
  };

  useEffect(() => {
    handleDadosInvalidos(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleAtualizarDados]);

  return (
    <Container>
      <Grid container direction="row" spacing={2}>
        <FormGroup item xs={12} sm={12} md={12} ls={12}>
          <Typography color="primary" component="label">
            O profissional a ser cadastro é atendente?
          </Typography>
        </FormGroup>
        <FormGroup item xs={12} sm={12} md={12} ls={12}>
          <Typography color="primary" component="label">
            Apenas profissionais que são atendentes serão cobrados.
          </Typography>
        </FormGroup>
        <FormGroup item xs={12} sm={12} md={12} ls={12}>
          <Typography color="primary" component="label" style={{marginRight: 20}}>
            Qual o tipo do profissional de saúde?
          </Typography>
        </FormGroup>
      </Grid>
      <Grid container direction="column" spacing={2}>
        <FormGroup item xs={12} sm={12} md={12} ls={12}>
          <SelectSearchStyled
            name="tipo"
            placeholder=""
            value={tipoSelecionado}
            elements={tiposProfissionalSaude?.findAllTipoProfissionalSaudeList || []}
            onChange={(e) => handleChangeField('tipo', e)}/>
        </FormGroup>
        {tipoSelecionado && tiposProfissionalSaude &&
          <>
            <FormGroup item xs={12} sm={12} md={12} ls={12}>
              <Typography color="primary" component="label" style={{marginRight: 20}}>
                Descrição
              </Typography>
            </FormGroup>
            <FormGroup item xs={12} sm={12} md={12} ls={12}>
              <Typography color="primary" component="label" style={{marginRight: 20}}>
                {tipoSelecionado.descricao}
              </Typography>
            </FormGroup>
          </>
        }
      </Grid>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  padding: 10px 0;
`;

const FormGroup = styled(Grid)`
  margin-bottom: 10px !important;
  text-align: center
`;

export default DadosPossuiAgenda;

const SelectSearchStyled = styled(SelectSearch)`
	width: 100%;
`;
