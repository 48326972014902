import React, {useState} from 'react';
import moment from 'moment';
import Grid from "@material-ui/core/Grid";
import InputDateForm from '../../../components/input/InputDateForm';

const Search = ({
	onSearch,
	onDataSearch
}) => {
	const [filterDateSelected, setFilterDateSelected] = useState(moment());

	const handleDateChange = (e) => {
		setFilterDateSelected(e);

		if (e) {
			onDataSearch({
				dataInicial: e.startOf('year').startOf('month').format('YYYY-MM-DD'),
				dataFinal: e.endOf('year').endOf('month').format('YYYY-MM-DD'),
			});
		}
	};

	return (
		<Grid container spacing={8}>
			<Grid item xs={12} sm={11} md={3} lg={2}>
				<InputDateForm
					variant="outlined"
					fullWidth
					openTo="year"
					views={["year"]}
					value={filterDateSelected}
					onChange={handleDateChange}
					format="YYYY"
					placeholder={"____"}
					mask={value => (value ? [/\d/, /\d/, /\d/, /\d/] : [])}
				/>
			</Grid>
		</Grid>
	);
};

export default Search;
