import styled from '@emotion/styled';
import {
	compose,
	layout,
	color,
	space,
	background,
	border,
	grid,
	position,
	shadow,
	typography,
	flexbox
} from 'styled-system'

export const systemProps = compose(
	layout,
	color,
	space,
	background,
	border,
	grid,
	position,
	shadow,
	typography,
	flexbox
);

const Box = styled('div')(systemProps);

export default Box;