import React from "react";
import {FormHeading, FormGroup} from "../constraints/constantesEntidade";
import TextFieldEntidade from "./textFieldEntidade";
import Grid from "@material-ui/core/Grid";

const DadosResponsavel = ({fieldChange, formEditavel, dados}) => {

  return (
    <>
      <Grid container spacing={2} direction="row">
        <Grid item xs={12}>
          <FormHeading>Dados do Responsável</FormHeading>
        </Grid>
        <FormGroup item xs={12} sm={12} md={6} lg={6}>
          <TextFieldEntidade label={'Nome'} field={'responsavelNome'} value={dados?.responsavelNome}
                             fieldChange={fieldChange}
                             formEditavel={formEditavel}/>
        </FormGroup>
        <FormGroup item xs={12} sm={12} md={6} lg={6}>
          <TextFieldEntidade label={'CPF'} field={'responsavelCpf'} value={dados?.responsavelCpf}
                             fieldChange={fieldChange} formEditavel={formEditavel}
                             mask={'cpf'}/>
        </FormGroup>
      </Grid>
    </>
  )

};

export default DadosResponsavel;
