import React from 'react';
import Modal from 'react-modal-slider';
import 'react-modal-slider/lib/main.css';
import { withStyles } from "@material-ui/core/styles";

const styles = () => ({
  overlay: {
    zIndex: '1300'
  }
});

const ModalSlider = ({children, ...props}) => {
  const {classes} = props;

    return (
        <Modal
            overlayClassName={classes.overlay}
            directionFrom={'right'}
            setAppElement={'#root'}
            ariaHideApp={true}
            maxMediaWidth={1024}
            {...props}
        >
            {children}
        </Modal>
    );
};

export default withStyles(styles)(ModalSlider);
