import React from 'react';
import styled from '@emotion/styled';

const ButtonTransparent = ({ children, forwardedRef, ...others }) => (
  <Button
    ref={forwardedRef}
    {...others}
  >
    {children}
  </Button>
);

export default React.forwardRef((props, ref) => {
  return <ButtonTransparent {...props} forwardedRef={ref} />;
});

const Button = styled.button`
    background: transparent;
    cursor: pointer;
    border: 0;
`;
