import React from 'react';
import MaskedInput, {conformToMask} from 'react-text-mask';
import createNumberMask from 'text-mask-addons/src/createNumberMask';
import createAutoCorrectedDatePipe from 'text-mask-addons/src/createAutoCorrectedDatePipe';
import string from './string';

const masks = {
  agencia: [/\d/, /\d/, /\d/, /\d/],
  conta: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
  data: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
  cep: [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/],
  inscricaoEstadual: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
  creditCard: [
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ],
  creditCardSecureCode: [
    /\d/,
    /\d/,
    /\d/,
  ],
  cnpj: [
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    '/',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
  ],
  cpf: [
    /\d/,
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
  ],
  cpfCnpj: [
    /\d/,
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ],
  phone: [
    '(',
    /\d/,
    /\d/,
    ')',
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ],
  cellPhone: [
    '(',
    /\d/,
    /\d/,
    ')',
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ],
  dataValidade: [
    /\d/,
    /\d/,
    '/',
    /\d/,
    /\d/,
    /\d/,
    /\d/
  ],
  loginMedico: [
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/
  ],
  loginSecretario: [
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    's'
  ]
};

export const cpfCnpjFormatted = value => {
  if (typeof value === 'string' && value.length <= 11) {
    return conformToMask(value, masks.cpf, {guide: false}).conformedValue;
  }
  if (typeof value === 'string' && value.length > 11) {
    return conformToMask(value, masks.cnpj, {guide: false}).conformedValue;
  }
  return value;
};

export const phoneFormatted = value => {
  const rawValue = string.removeSpecialChars(value);
  if (typeof rawValue === 'string' && rawValue.length < 11) {
    return conformToMask(rawValue, masks.phone, {guide: false}).conformedValue;
  }

  if (typeof rawValue === 'string' && rawValue.length >= 11) {
    return conformToMask(rawValue, masks.cellPhone, {guide: false}).conformedValue;
  }
  return rawValue;
};

export const cepFormatted = value => {
  if (typeof value === 'string' && value.length >= 8) {
    return conformToMask(value, masks.cep, {guide: false}).conformedValue;
  }

  return value;
};

export const InputMask = props => {
  const {inputRef, mask, ...other} = props;

  return (
    <MaskedInput
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      placeholderChar={'\u2000'}
      mask={getMaskByType(mask, props.value)}
      {...other}
    />
  );
};

export const getMaskByType = (maskType, value) => {
  const rawValue = string.removeSpecialChars(value);
  if (maskType === 'phone' && typeof rawValue === 'string' && rawValue.length < 11) {
    return masks.phone;
  }

  if (maskType === 'phone' && typeof rawValue === 'string' && rawValue.length >= 11) {
    return masks.cellPhone;
  }

  if (maskType === 'cpfCnpj' && typeof rawValue === 'string') {
    if (rawValue.length < 12) {
      return masks.cpfCnpj;
    } else {
      return masks.cnpj;
    }
  }

  if (maskType === 'moeda') {
    return createNumberMask({
      prefix: 'R$ ',
      thousandsSeparatorSymbol: '.',
      decimalSymbol: ',',
      allowDecimal: true,
    });
  }

  if (maskType === 'numero') {
    return createNumberMask({
      prefix: '',
      thousandsSeparatorSymbol: '.',
      decimalSymbol: ',',
      allowDecimal: false,
    });
  }

  if (maskType === 'numeroSemPontuacao') {
    return createNumberMask({
      prefix: '',
      thousandsSeparatorSymbol: '',
      allowDecimal: false,
    });
  }

  if (maskType === 'decimal') {
    return createNumberMask({
      prefix: '',
      thousandsSeparatorSymbol: '.',
      decimalSymbol: ',',
      allowDecimal: true,
    });
  }

  if (maskType === 'agencia') {
    return masks.agencia;
  }

  if (maskType === 'conta') {
    return masks.conta;
  }

  if (maskType === 'data') {
    return {
      mask: masks.data,
      pipe: createAutoCorrectedDatePipe('dd/MM/yyyy'),
    };
  }

  return masks[maskType] || null;
};

export default masks;
