import { apiAuth } from "./axios";

export const obterUsuarioLogadoLocal = () => {
	return JSON.parse(window.localStorage.getItem('usuarioLogado'));
};

export const obterEntidadeAtualLocal = () => {
	return JSON.parse(window.localStorage.getItem('entidadeAtual'));
};

export const obterUsuarioLogado = async (accessToken) => {
    try {
        const { data: obterUsuarioLogadoData } = await apiAuth.post('', {
			query: `
				query {
					obterUsuarioLogado {
						nome
						entidadeAtual {
							id
							nome
							tipo
							nivel{
                id
                nome
              }
						}
						authorities {
							authority
						}
					}
				}
			`,
			variables: {},
		}, {
			headers: {
				authorization: `bearer ${accessToken}`
			}
		});

		return obterUsuarioLogadoData?.data?.obterUsuarioLogado || null;
    } catch (error) {
        return null;
    }
};

export const obterUsuarioLogadoEEntidadesAtivas = async (accessToken) => {
    try {
        const { data } = await apiAuth.post('', {
			query: `
				query {
					obterUsuarioLogado {
						nome
						entidadeAtual {
							id
							nome
							tipo
							responsavelNome
							responsavelCpf
							nivel{
							  id
							  nome
							}
						}
						authorities {
							authority
						}
					}
					obterEntidadesAtivasDoUsuario {
						id
						nome
						documento
						nivel {
							nome
						}
					}
				}
			`,
			variables: {},
		}, {
			headers: {
				authorization: `bearer ${accessToken}`
			}
		});

		return data?.data || null;
    } catch (error) {
        return null;
    }
};

export const alteraContextoAtual = async (idContexto, accessToken) => {
	try {
		const { data } = await apiAuth.post('', {
			query: `
				mutation($idContexto: UUID) {
					alterarContextoAtual(idContexto: $idContexto) {
						nome
						entidadeAtual {
							id
							nome
							tipo
						}
						authorities {
							authority
						}
					}
				}
			`,
			variables: {
				idContexto
			}
		}, {
			headers: {
				authorization: `bearer ${accessToken}`
			}
		});

		return data?.data?.alterarContextoAtual || null;
	} catch (error) {
		return null;
	}
};
