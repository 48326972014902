import React from 'react';
import PropTypes from 'prop-types';

import Box from '../box';

const Link = ({
	href,
	name,
	target,
	children,
	...rest
}) => (
		<Box as="a" href={href} name={name} target={target} {...rest}>
			{children}
		</Box>
	);

Link.prototype = {
	href: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	target: PropTypes.string,

};

Link.defaultProps = {
	target: "__SELF",
}

export default Link;