import Typography from "@material-ui/core/Typography";
import React from "react";
import {FormGroup, FormHeading, TextInputBloqueado, LinkAppHealth} from "../constraints/constantesEntidade";
import Grid from "@material-ui/core/Grid";

const DadosDemonstracao = ({responsavelCpf}) => {

  const link = () => {
    window.open(`${process.env.REACT_APP_APPHEALTH_URL}/#/login?documento=${responsavelCpf || ''}`);
  };

  return (
    <>
      <Grid container spacing={2} direction="row">
        <Grid item xs={12}>
          <FormHeading>Usuários do sistema <LinkAppHealth handleClick={link} label={'AppHealth'}/></FormHeading>
        </Grid>
        <FormGroup item xs={12} sm={12} md={6} lg={6}>
          <Typography color="primary" component="label">
            Login do médico
          </Typography>
          <TextInputBloqueado
            value={responsavelCpf}
            disabled={true}
            mask={'loginMedico'}
          />
        </FormGroup>
        <FormGroup item xs={12} sm={12} md={6} lg={6}>
          <Typography color="primary" component="label">
            Login do secretário
          </Typography>
          <TextInputBloqueado
            value={responsavelCpf}
            disabled={true}
            mask={'loginSecretario'}
          />
        </FormGroup>
      </Grid>
    </>
  )

};

export default DadosDemonstracao;
