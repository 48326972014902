import React, {useState} from 'react';
import styled from '@emotion/styled';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import Grow from '@material-ui/core/Grow';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import ButtonTransparent from '../../components/button/button-transparent';
import {EDIT_ENTITY} from "../../router/names";
import {useHistory} from "react-router-dom";
import {obterUsuarioLogadoLocal} from "../../services/usuario-service";

const Profile = ({username, onLogout}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const {push} = useHistory();

  const entidadeLogada = obterUsuarioLogadoLocal();

  const handleToggleMenu = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenMenu(!openMenu);
  };

  const handleCloseMenu = event => {
    if (anchorEl && anchorEl.contains(event.target)) {
      return;
    }

    setOpenMenu(false);
  };

  const handleClickLogout = () => {
    onLogout && onLogout();
  };

  const handleClickEntidade = () => {
    push(`${EDIT_ENTITY.replace(':idEntity', entidadeLogada.entidadeAtual.id).replace(':tipoEntity', entidadeLogada.entidadeAtual.tipo)}`);
  };

  return (
    <div>
      <ButtonTransparent
        aria-owns={anchorEl ? "menu-profile" : undefined}
        aria-haspopup="true"
        onClick={handleToggleMenu}
      >
        <AvatarContainer>
          <AvatarName>{username}</AvatarName>
        </AvatarContainer>
      </ButtonTransparent>

      <Popper
        open={openMenu}
        anchorEl={anchorEl}
        transition
        disablePortal
        style={{zIndex: 1000}}
      >
        {({TransitionProps, placement}) => (
          <Grow
            {...TransitionProps}
            id="menu-profile"
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom"
            }}
          >
            <Paper style={{minWidth: 150}}>
              <ClickAwayListener onClickAway={handleCloseMenu}>
                <MenuList>
                  <StyledMenuItem onClick={handleClickEntidade}>Perfil</StyledMenuItem>
                  <StyledMenuItem onClick={handleClickLogout}>Logout</StyledMenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}

const AvatarContainer = styled.div`
    height: 100%;
    display: flex;
    align-items: start;
`;

const AvatarName = styled.p`
    font-size: 14px;
    color: #747474;
`;

const StyledMenuItem = styled(MenuItem)`
    color: #747474 !important;
`;

export default Profile;
