import React, {useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid"
import RenderTextField from "../../../../components/TextField/RenderTextField";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import styled from "@emotion/styled";
import Slider from '@material-ui/core/Slider';

const DadosImportacaoContratante = ({dados, handleAtualizarDados, dadosErrors, handleAtualizarDadosErrors, handleDadosInvalidos, disabled}) => {

  const [dadosTemp, setDadosTemp] = useState({
    tempoFuncionamentoEmpresa: dados.tempoFuncionamentoEmpresa || '',
    numeroPacientes: dados.numeroPacientes || '',
    haveraMigracaoSistema: dados.haveraMigracaoSistema || false,
    sistemaASerMigrado: dados.sistemaASerMigrado || ''
  });

  const handleChangeField = (campo, value) => {
    setDadosTemp({
      ...dadosTemp,
      [campo]: value
    });

    handleAtualizarDados({
      [campo]: value
    });

    handleAtualizarDadosErrors({
      [campo]: value === undefined || value === ''
    });
  };

  const handleChangeHaveraMigracaoSistema = () => {
    setDadosTemp({
      ...dadosTemp,
      haveraMigracaoSistema: !dadosTemp.haveraMigracaoSistema,
      sistemaASerMigrado: ''
    });

    handleAtualizarDados({
      haveraMigracaoSistema: !dadosTemp.haveraMigracaoSistema
    });
  };

  const isCamposPreenchidos = () => {
    return dadosTemp.tempoFuncionamentoEmpresa === null ||
      dadosTemp.numeroPacientes === null ||
      (dadosTemp.haveraMigracaoSistema ? dadosTemp.sistemaASerMigrado === '' : false);
  };

  const handleChangeTempoFuncionamento = (event, value) => {
    handleChangeField('tempoFuncionamentoEmpresa', value);
  };

  const handleChangeNumeroPacientes = (event, value) => {
    handleChangeField('numeroPacientes', value);
  };

  useEffect(() => {
    handleDadosInvalidos(isCamposPreenchidos());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleAtualizarDados]);

  return (
    <Container>
      <Grid container direction="row" spacing={2}>
        <Grid item xs={11} sm={11} md={11} ls={11}>
          <Typography color="primary" component="label">
            {"Tempo de funcionamento"}
          </Typography>
          <Slider
            defaultValue={0}
            aria-labelledby="discrete-slider"
            valueLabelDisplay="auto"
            step={5}
            marks={marksTempoFuncionamento}
            min={0}
            max={100}
            value={dadosTemp.tempoFuncionamentoEmpresa}
            onChange={handleChangeTempoFuncionamento}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={11} sm={11} md={11} ls={11}>
          <Typography color="primary" component="label">
            {"Numero de pacientes"}
          </Typography>
          <MySlider
            defaultValue={0}
            aria-labelledby="discrete-slider"
            valueLabelDisplay="auto"
            step={50}
            marks={marksNumeroPacientes}
            min={0}
            max={1000}
            value={dadosTemp.numeroPacientes}
            onChange={handleChangeNumeroPacientes}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} ls={12}>
          <Typography color="primary" component="label">
            {"Haverá migração de outro sistema?"}
          </Typography>
          <Checkbox checked={dadosTemp.haveraMigracaoSistema}
                    onChange={() => handleChangeHaveraMigracaoSistema()}
                    disabled={disabled}/>
        </Grid>
        <Grid item xs={12} sm={12} md={12} ls={12}>

          {dadosTemp.haveraMigracaoSistema === true &&
          <>
            <Typography color="primary" component="label">
              {"Sistema a ser migrado *"}
            </Typography>
            <RenderTextField field={dadosTemp.sistemaASerMigrado}
                             error={dadosErrors?.sistemaASerMigrado}
                             errorText={"Este campo é obrigatório."}
                             handleChange={(e) => handleChangeField('sistemaASerMigrado', e.target.value)}
                             disabled={disabled}/>
          </>
          }
        </Grid>
      </Grid>
    </Container>
  );

};

export default DadosImportacaoContratante;

const marksNumeroPacientes = [
  {
    value: 0,
    label: '0',
  },

  {
    value: 100,
    label: '100',
  },
  {
    value: 250,
    label: '250',
  },
  {
    value: 500,
    label: '500',
  },

  {
    value: 750,
    label: '750',
  },
  {
    value: 1000,
    label: '1000+',
  }
];

const marksTempoFuncionamento = [
  {
    value: 0,
    label: '0',
  },
  {
    value: 5,
    label: '5',
  },
  {
    value: 15,
    label: '15',
  },
  {
    value: 25,
    label: '25',
  },
  {
    value: 50,
    label: '50',
  },
  {
    value: 75,
    label: '75',
  },
  {
    value: 100,
    label: '100+',
  }
];

const Container = styled.div`
  width: 100%;
  padding: 10px 0;
  margin: 0 5px;
`;

const MySlider = styled(Slider)`
  padding-right: 30px;
`;
