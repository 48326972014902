import { create } from 'apisauce';

export const apisauceAuth = create({
	baseURL: process.env.REACT_APP_BASE_URL_AUTH,
	headers: {
		'Content-Type': 'application/x-www-form-urlencoded',
		Authorization: `Basic ${process.env.REACT_APP_OAUTH_TOKEN}`
	},
});

export const RedefinirSenhaApi = create({
	baseURL: `${process.env.REACT_APP_BASE_URL_AUTH}/public/v1/usuario/redefinir-senha`,
	headers: {
		'Content-Type': 'application/json'
	},
});
