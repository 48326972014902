import React from "react";
import { TextField as TextFieldDefault } from "@material-ui/core";
import { InputMask } from "../../utils/masks";

const TextField = props => {
  return (
    <TextFieldDefault
      {...props}
      variant="outlined"
      fullWidth
      InputProps={props.mask ? {
        ...props.InputProps,
        inputProps: {
          mask: props.mask,
        },
        inputComponent: InputMask,
      } : {
        ...props.InputProps,
        inputProps: {
          maxLength: props.maxLength,
        }
      }}
    />
  );
};

export default TextField;
