import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import CircularProgress from "@material-ui/core/CircularProgress";

import logo from '../../assets/images/LogoLogin.png';
import lineLogin from '../../assets/images/lineLogin.png';
import { login } from '../../redux'
import Input from '../../components/input';
import string from '../../utils/string';
import Notification from '../../components/notification/Notification';

const notificationDefault = {
	variant: '',
	message: '',
	isOpen: false,
};

const Signup = () => {
	const [loading, setLoading] = useState(false);
	const [credentials, setCredentials] = useState({
		username: '',
		password: '',
		connected: false,
	});
	const [notification, setNotification] = useState({
		...notificationDefault,
	});
	const {error, success} = useSelector(state => state.Auth);
	const dispatch = useDispatch();
	const history = useHistory();

	const handleChange = (field, value) => {
		setCredentials({
			...credentials,
			[field]: value,
		});
	};

	const handleSubmitLogin = (e) => {
		e.preventDefault();
		setLoading(true);
		dispatch(
			login({
				username: string.removeSpecialChars(credentials.username),
				password: credentials.password,
				replaceRouter: history.replace
			})
		);
	};

	useEffect(() => {
		if (typeof error === 'string') {
			setLoading(false);
			setNotification({
				variant: 'error',
				isOpen: true,
				message: error,
			});
		}
	}, [error]);

	useEffect(() => {
		if (typeof success === 'string') {
			setLoading(false);
			setNotification({
				variant: 'success',
				isOpen: true,
				message: success,
			});
		}
	}, [success]);

	return (
		<PageContainer>
			<Panel>
				<Logo src={logo} alt="App Health Logo" />
			</Panel>
			<VerticalLine />
			<Panel>
				<Form onSubmit={handleSubmitLogin}>
					<FormGroup>
						<InputStyled
							id="login"
							placeholder="CPF / CNPJ"
							mask="cpfCnpj"
							value={credentials.username}
							onChange={(e) => handleChange('username', e.target.value)}
						/>
					</FormGroup>
					<FormGroup>
						<InputStyled
							id="password"
							placeholder="Senha"
							value={credentials.password}
							type="password"
							onChange={(e) => handleChange('password', e.target.value)}
						/>
					</FormGroup>
					<SubmitLogin type="submit" disabled={loading}>
						Entrar
						{loading && (
							<CircularProgressStyled
								color="inherit"
								size={12}
							/>
						)}
					</SubmitLogin>

					<EsqueciMinhaSenhaContainer>
						<EsqueciMinhaSenhaLink to="redefinir-senha">iih! Esqueci minha senha</EsqueciMinhaSenhaLink>
					</EsqueciMinhaSenhaContainer>
				</Form>
			</Panel>
			<LineLogin src={lineLogin} alt="" />
			<Notification
				close={() => {
					setNotification({
						...notificationDefault,
					});
				}}
				reset={() => {
					setNotification({
						...notificationDefault,
					});
				}}
				isOpen={notification.isOpen}
				variant={notification.variant}
				message={notification.message}
			/>
		</PageContainer>
	)
};

const PageContainer = styled.div`
	display: flex;
  	width: 100%;
  	height: 100vh;
	justify-content: center;
	align-items: center;
`;

const Panel = styled.div`
	display: flex;
    flex-direction: column;
    flex: 1 100%;
	justify-content: center;
	align-items: center;
`;

const Logo = styled.img`
	width: 51.6%;
	margin-left: -1.3%;
`;

const VerticalLine = styled.div`
	width: 2px;
  	background-color: #969696;
	height: 57.4%;
`;

const LineLogin = styled.img`
	position: fixed;
	max-height: 46%;
	width: 100%;
	bottom: 0;
	z-index: -1;
`;

const Form = styled.form`
	display: flex;
	flex-direction: column;
	flex: 1 100%;
	width: 58.2%;
`;

const FormGroup = styled.div`
	display: flex;
    width: 100%;
`;
const InputStyled = styled(Input)`
	margin: 10px;
	line-height: normal;
	border: 1px solid #999;
    width: 100%;
	padding: 1rem 0 0.875rem 1.75rem;
	border-radius: 0.75rem;
	margin-bottom: 1.75rem;
	font-size: 1.20rem;
	outline: none;
`;

const SubmitLogin = styled.button`
	margin: 1.75rem 10px;
	font-size: 1rem;
	padding: 1rem 0 0.875rem;
	border-radius: 0.75rem;
	text-transform: uppercase;
	background-color: #00B0AE;
	color: #fff;
	font-size: 14px;
	border: none;
	outline: none;
	cursor: pointer;
`;

const CircularProgressStyled = styled(CircularProgress)`
	margin-left: 10px;
`;

const EsqueciMinhaSenhaContainer = styled.div`
	text-align: right;
`;

const EsqueciMinhaSenhaLink = styled(Link)`
	text-decoration: none;
	color: #969696;
`;

export default Signup;
