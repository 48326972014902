import React from 'react';
import styled from '@emotion/styled';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import {useDispatch, useSelector} from 'react-redux';
import {alteraContextoAtual} from '../../services/usuario-service';
import {useHistory} from 'react-router-dom';
import {DASHBOARD} from '../../router/names';
import colors from '../../theme/Colors';
import {loginOrRefreshSuccess} from '../../redux/auth';
import {cpfCnpjFormatted} from '../../utils/masks';

const SelecionaEntidade = () => {
  const {entidadesAtivasDoUsuario} = useSelector(state => state.Auth);
  const history = useHistory();
  const dispatch = useDispatch();

  const handleClickEntidade = async (entidade) => {
    const accessToken = window.localStorage.getItem('token');
    const refreshToken = window.localStorage.getItem('refreshToken');
    const usuarioLogado = await alteraContextoAtual(entidade.id, accessToken);

    const credentials = {
      access_token: accessToken,
      refresh_token: refreshToken
    };
    dispatch(loginOrRefreshSuccess({
      ...credentials,
      usuarioLogado
    }));

    history.replace(DASHBOARD);
  };

  return (
    <PageContainer>
      <PageTitle>Selecione uma entidade:</PageTitle>

      <Paper style={{width: '40%'}}>
        <List>
          {entidadesAtivasDoUsuario.map(entidade => (
            <ListItem key={entidade.id} onClick={() => handleClickEntidade(entidade)}>
              <div>
                <Typography
                  component="h3"
                  variant="body1"
                  color="textPrimary"
                >
                  {entidade.nome}
                </Typography>
              </div>
              <div>
                <Typography
                  component="span"
                  variant="body2"
                  color="textPrimary"
                >
                  Documento:{' '}
                </Typography>
                {` ${cpfCnpjFormatted(entidade.documento)}`}
              </div>
              <div>
                <Typography
                  component="span"
                  variant="body2"
                  color="textPrimary"
                >
                  Nível:{' '}
                </Typography>
                {`${entidade.nivel?.nome}`}
              </div>
            </ListItem>
          ))}
        </List>
      </Paper>
    </PageContainer>
  )
};

const PageContainer = styled.div`
	display: flex;
	flex-direction: column;
  	width: 100%;
  	height: 100vh;
	justify-content: center;
	align-items: center;
`;

const PageTitle = styled.h3`
	color: ${colors.primary.main}
`;

const List = styled.ul`
	list-style: none;
	padding: 0;
	margin: 0;
`;
const ListItem = styled.li`
	border-bottom: 1px solid #ccc;
    padding: 15px;
	cursor: pointer;

	&:last-child {
		border-bottom: 0;
	}
`;

export default SelecionaEntidade;
