import {getAuthorities} from '.';
import {ROLE_COMERCIAL, ROLE_EXECUTIVO, ROLE_INTERMEDIARIO, ROLE_PLATAFORMA, ROLE_SISTEMA,} from './enum';
import {obterEntidadeAtualLocal} from '../usuario-service';

const canEditarEntidade = entidade => {

  const entidadeAtual = obterEntidadeAtualLocal();
  const filterAuthorities = [
    ROLE_COMERCIAL,
    ROLE_SISTEMA,
    ROLE_EXECUTIVO,
    ROLE_INTERMEDIARIO,
    ROLE_PLATAFORMA,
  ];
  const hasAuthorities = getAuthorities(filterAuthorities).length > 0;
  const entidadeAtualId = entidadeAtual?.id;
  const entidadeId = entidade?.id;

  return entidadeAtualId && entidadeId && entidadeAtualId === entidadeId && hasAuthorities;
};

export default canEditarEntidade;
