import React, {useState} from 'react';
import moment from 'moment';
import {useQuery} from '@apollo/react-hooks';

import Box from '../../components/box';
import {LIST_FECHAMENTO} from '../../graphql/queryes';

import Search from './components/search';
import List from '../../components/list/list';
import Number from '../../utils/number';

const ListFechamento = () => {
  const [searchDTO, setSearchDTO] = useState({
    dataInicial: moment().startOf('year').startOf('month').format('YYYY-MM-DD'),
    dataFinal: moment().endOf('year').endOf('month').format('YYYY-MM-DD'),
  });
  const [pageableDTO] = useState({
    pageNumber: 0,
    pageSize: 150,
  });

  const {data, loading, error, refetch, fetchMore} = useQuery(LIST_FECHAMENTO, {
    variables: {
      searchDTO,
      pageableDTO,
    },
    fetchPolicy: 'no-cache'
  });

  const handleLoadMore = () => {
    if (!data?.findAllFechamentoFinanceiro?.last) {
      const pageable = {
        ...pageableDTO,
        pageNumber: data.findAllFechamentoFinanceiro.number + 1,
      };
      fetchMore({
        variables: {
          pageableDTO: {
            ...pageable,
          },
          searchDTO: {
            ...searchDTO,
          },
        },
        updateQuery: (prev, {fetchMoreResult}) => {
          if (!fetchMoreResult) return prev;

          return ({
            findAllFechamentoFinanceiro: {
              ...prev.findAllFechamentoFinanceiro,
              ...fetchMoreResult.findAllFechamentoFinanceiro,
              content: [
                ...prev.findAllFechamentoFinanceiro.content,
                ...fetchMoreResult.findAllFechamentoFinanceiro.content
              ]
            }
          });
        }
      });
    }
  };

  return (
    <Box>
      <Search onSearch={refetch} onDataSearch={s => setSearchDTO({...searchDTO, ...s})}/>
      <List
        columns={[
          {
            title: 'Dt. Início Período',
            value: (item) => item.dataInicioPeriodo ? moment(item.dataInicioPeriodo).format('DD/MM/YYYY') : '-',
          },
          {
            title: 'Dt. Fim Período',
            value: (item) => item.dataInicioPeriodo ? moment(item.dataInicioPeriodo).format('DD/MM/YYYY') : '-',
          },
          {
            title: 'Valor',
            value: (item) => Number.currencyFormat(item.valor),
          },
          {
            title: 'Dt. Lançamento',
            value: (item) => item.dataLancamento ? moment(item.dataLancamento).format('MM/YYYY') : '-',
          },
          {
            title: 'Dt. Pagamento',
            value: (item) => item.dataPagamento ? moment(item.dataPagamento).format('MM/YYYY') : '-',
          },
        ]}
        items={data?.findAllFechamentoFinanceiro?.content}
        loading={loading}
        error={error}
        last={data?.findAllFechamentoFinanceiro?.last}
        onLoadMore={handleLoadMore}
      />
    </Box>
  )
};

export default ListFechamento;
