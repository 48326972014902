import Grid from "@material-ui/core/Grid";
import {FormGroup, FormHeading} from "../constraints/constantesEntidade";
import TextFieldEntidade from "./textFieldEntidade";
import React from "react";

const CamposEntideJuridica = ({isCodigoPermitido, fieldChange, formEditavel, dados}) => {

  return (

    <>
      <Grid container direction="row" spacing={2}>
        <Grid item xs={12}>
          <FormHeading>Dados da Entidade</FormHeading>
        </Grid>
        {isCodigoPermitido ?
          <FormGroup item xs={12} sm={12} md={6} lg={3}>
            <TextFieldEntidade label={'Código'} field={'codigo'} value={dados?.codigo} fieldChange={fieldChange} formEditavel={formEditavel}/>
          </FormGroup>
          : null
        }
        <FormGroup item xs={12} sm={12} md={6} lg={3}>
          <TextFieldEntidade label={'CNPJ'} field={'cnpj'} value={dados?.cnpj} fieldChange={fieldChange} mask={'cnpj'} formEditavel={formEditavel}/>
        </FormGroup>
        <FormGroup item xs={12} sm={12} md={6} lg={6}>
          <TextFieldEntidade label={'E-mail'} field={'email'} value={dados?.email} fieldChange={fieldChange} formEditavel={formEditavel}/>
        </FormGroup>
        <FormGroup item xs={12} sm={12} md={6} lg={6}>
          <TextFieldEntidade label={'Razão social'} field={'razaoSocial'} value={dados?.razaoSocial} fieldChange={fieldChange} formEditavel={formEditavel}/>
        </FormGroup>

        <FormGroup item xs={12} sm={12} md={6} lg={6}>
          <TextFieldEntidade label={'Nome Fantasia'} field={'nomeFantasia'} value={dados?.nomeFantasia} fieldChange={fieldChange} formEditavel={formEditavel}/>
        </FormGroup>

      </Grid>
    </>

  )

};

export default CamposEntideJuridica;
