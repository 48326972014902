import React, { useEffect, Fragment } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { SIGNUP, UNSAFE_ZONE } from './names';
import { isMatchRoute } from '../utils/router';

const WrappSafety = ({ children }) => {
	let { replace } = useHistory();
	let { pathname } = useLocation();

	const verifyAuthorization = () => {

		const auth = localStorage.getItem("token");

		let isUnsafeZone = false;
		UNSAFE_ZONE.forEach(route => {
			if (isUnsafeZone) return;
			isUnsafeZone = !!isMatchRoute(route, pathname);
		});

		if (!auth && !isUnsafeZone) {
			replace(SIGNUP);
		}
	};

	useEffect(() => {
		verifyAuthorization();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<Fragment>
			{children}
		</Fragment>
	);
};

export default WrappSafety;