export const createOrUpdatePessoa = async (
	data,
	{
		mUptadePessoaFisica,
		mUpdatePessoaJuridica,
		mCreateEntidadeFisicaHawk,
		mCreateEntidadeJuridicaHawk
	},
	codigo
) => {
	const baseOperation = {
		"new": {
			"JURIDICA": () => mCreateEntidadeJuridicaHawk({ variables: { entidadeJuridicaInput: data, codigo } }),
			"FISICA": () => mCreateEntidadeFisicaHawk({ variables: { entidadeFisicaInput: data, codigo } }),
		},
		"update": {
			"FISICA": () => mUptadePessoaFisica({ variables: { entidadeFisicaInput: data } }),
			"JURIDICA": () => mUpdatePessoaJuridica({ variables: { entidadeJuridicaInput: data } }),
		}
	};

	return baseOperation[!!data.id ? 'update' : 'new'][data.tipo]();
};
