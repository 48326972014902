import gql from 'graphql-tag';

export const LIST_UNITS = gql`
	query findAllUnidade($search: String) {
		findAllUnidade(searchDTO: {search: $search}) {
			content {
				id
				nome
				cnpj
			}
		}
	}
`;

export const LIST_ENTITY = gql`
	query findAllEntidade($search: String, $entidadePai: EntidadeInput, $ativo: Boolean, $nivel: EntidadeNivelInput, $page: Int, $sortField: String) {
		findAllEntidade(searchDTO: {search: $search, entidade: $entidadePai, ativo: $ativo, entidadeNivel: $nivel}, pageableDTO: {pageNumber: $page, sortField: $sortField}) {
			last
			totalPages
			size
			number
			content {
				id
				nome
				documento
				ativo
				tipo
				nivel {
					nome
				}
			}
		}
		findAllEntidadeNiveisVisiveisEntidadeLogada {
			id
			value: id
			name: nome
		}
		findAllEntidadesSimple: findAllEntidade(searchDTO: {}, pageableDTO: {pageSize: 9999}) {
			content {
				id
				value: id
				name: nome
				tipo
			}
		}
	}
`;

export const LIST_MOVIMENTACAO_FINANCEIRA = gql`
	query findAllMovimentacaoFinanceira($searchDTO: SearchEntidadeDTOInput, $pageableDTO: PageableDTOInput) {
		findAllMovimentacaoFinanceira(searchDTO: $searchDTO, pageableDTO: $pageableDTO) {
			last
			totalPages
			size
			number
			content {
				id
				dataLancamento
				valorCheio
				valorRepasse
				percentualRepasse
			}
		}
	}
`;

export const LIST_FECHAMENTO = gql`
	query findAllFechamentoFinanceiro($searchDTO: SearchDTOInput, $pageableDTO: PageableDTOInput) {
		findAllFechamentoFinanceiro(searchDTO: $searchDTO, pageableDTO: $pageableDTO) {
			last
			totalPages
			size
			number
			content {
				id
				dataInicioPeriodo
				dataFimPeriodo
				valor
				dataLancamento
				dataPagamento
			}
		}
	}
`;

export const DROPDOWN_ENTIDADES = gql`
	query findAllEntidadeByOauthClient($searchDTO: SearchEntidadeDTOInput, $pageableDTO: PageableDTOInput) {
		findAllEntidadeByOauthClient(searchDTO: $searchDTO, pageableDTO: $pageableDTO) {
			last
			content {
				value: id
				name: nome
			}
		}
	}
`;

export const DROPDOWN_PLANOS = gql`
	query findAllPlanoAtivo($searchDTO: SearchDTOInput) {
		findAllPlanoAtivo(searchPlanoDTO: $searchDTO) {
			value: id
			label: nome
			diasPeriodoExperiencia
		}
	}
`;

export const LIST_PLANOS = gql`
	query findAllPlanoAtivo($searchDTO: SearchDTOInput) {
		findAllPlanoAtivo(searchPlanoDTO: $searchDTO) {
			id
			nome
			diasPeriodoExperiencia
			valor
			ordem
		}
	}
`;

export const DROPDOWN_MUNICIPIOS = gql`
	query findAllMunicipio($searchDTO: SearchDTOInput) {
		findAllMunicipio(searchDTO: $searchDTO) {
			last
			content {
				value: id
				label: nome
				uf
				codigoIBGE
				estado{
          value: id
          label: nome
        }
			}
		}
	}
`;

export const DROPDOWN_ESTADOS = gql`
	query findAllEstadp($searchDTO: SearchDTOInput) {
		findAllEstado(searchDTO: $searchDTO) {
			last
			content {
				value: id
				label: nome
			}
		}
	}
`;

export const DROPDOWN_GROUP_BY_REDE_ID = gql`
	query findGroupByRedeId($idRede: Long) {
		findGroupByRedeId(idRede: $idRede) {
			value: id
			label: nome
		}
	}
`;

export const LIST_VENDAS = gql`
	query findAllVenda($searchDTO: SearchEntidadeDTOInput, $pageableDTO: PageableDTOInput) {
		findAllVenda(searchDTO: $searchDTO, pageableDTO: $pageableDTO) {
			last
			totalPages
			size
			number
			content {
				id
				status
				unidade{
          nome
          dataVencimento
        }
				dataLancamento
				dataUltimoPagamento
				valor
				plano {
					nome
				}
				vendedor {
					nome
				}
				meioPagamentoPlataforma
			}
		}
	}
`;

export const FIND_BY_ID_VENDA = gql`
  query findByIdVenda($id: UUID) {
    findByIdVenda(id: $id) {
      id
      status
      unidade {
        id
        nome
        dataVencimento
      }
      dataLancamento
      dataUltimoPagamento
      dataEncerramento
      valorPorProfissionalSaude
      valor
      diasPeriodoGratis
      plano {
        id
        nome
        diasMaximoPeriodoExperiencia
      }
      vendedor {
        id
        tipo
        nome
        documento
        email
      }
      unidade {
        id
        nome
        nomeFantasia
        codigoCnes
        telefonePrincipal
        endereco {
          bairro
          cep
          complemento
          municipio {
            value: id
            label: nome
          }
          nomeLogradouro
          numero
        }
        rede {
          id
        }
        profissionaisSaude {
          id
          possuiAgenda
          nome
          cpf
          email
          ativo
          cns
          dataNascimento
          dataVencimento
          dataCadastro
          numeroConselho
          ufConselho
          utilizaAssinaturaDigital
          utilizaTelemedicina
          utilizaSecretariaVirtual
          especialidades {
            id
            especialidade
          }
          usuario {
            id
          }
          endereco {
            nomeLogradouro
            numero
            bairro
            cep
            complemento
            municipio {
              value: id
              label: nome
            }
            estado {
              value: id
              label: nome
            }
          }
          dadoBancario {
            banco {
              value: id
              codigo
              label: nome
            }
            tipoConta
            conta
            operacao
            digitoVerificador
            agencia
            digitoAgencia
            documento
          }
        }
      }
      contratante {
        responsavelNome
        responsavelCargo
        responsavelCpf
        nomeFantasia
        razaoSocial
        cnpj
        inscricaoEstadual
        telefone
        codigoCnes
        email
        endereco {
          cep
          nomeLogradouro
          numero
          bairro
          complemento
          municipio {
            value: id
            label: nome
          }
          estado {
            value: id
            label: nome
          }
          tipoLogradouro {
            value: id
            label: descricao
          }
        }
        tempoFuncionamentoEmpresa
        numeroPacientes
        haveraMigracaoSistema
        sistemaASerMigrado
        especialidades {
          value: id
          label: especialidade
        }
      }
	  meioPagamentoPlataforma
    }
  }
`;

export const LIST_BENEFICIARIOS_DA_VENDA = gql`
	query listBeneficiariosDaVenda($entidade: EntidadeInput) {
		findAllEntidadesEntreUsuarioLogadoEEntidade(entidade: $entidade) {
			id
			nivel {
				nome
			}
    		nome
			documento
		}
	}
`;

export const LIST_MOVIMENTACOES_FINANCEIRAS_DA_VENDA = gql`
	query listMovimentacoesFinanceirasDaVenda($venda: VendaInput) {
		findAllMovimentacoesFinanceirasEntreUsuarioLogadoEVendedor(venda: $venda) {
			id
			tipo
			dataLancamento
			valorCheio
			valorRepasse
			percentualRepasse
			beneficiario {
				nome
			}
		}
	}
`;

export const LIST_ENTITY_SIMPLE = gql`
	query findAllEntidadeSimple {
		findAllEntidade(searchDTO: {}, pageableDTO: {pageSize: 9999}) {
			content {
				id
				nome
				tipo
			}
		}
	}
`;

export const DROPDOWN_ENTIDADES_PAI = gql`
	query DropdownEntidadesPai {
		findAllEntidade(searchDTO: {}, pageableDTO: {pageSize: 9999}) {
			content {
				id
				value: id
				name: nome
				tipo
			}
		}
	}
`;

export const DROPDOWN_NIVEIS_ENTIDADE_LOGADA = gql`
	query DropdownNiveisEntidadeLogada {
		findAllEntidadeNiveisVisiveisEntidadeLogada {
			id
			value: id
			name: nome
		}
	}
`;

export const LIST_LEVELS = gql`
  query findAllEntidadeNiveisVisiveisEntidadeLogada {
	  findAllEntidadeNiveisVisiveisEntidadeLogada {
	    id
	    nome
	  }
  }
`;

export const LIST_BANK_SIMPLE = gql`
	query {
		findAllBanco(pageableDTO: {pageSize: 9999}) {
			content {
				id
				nome
				value: id
				codigo
				name: nome
			}
		}
	}
`;

export const LIST_BANK_BUSCA = gql`
	query($searchDTO: SearchDTOInput, $pageableDTO: PageableDTOInput) {
		bancos: findAllBanco(searchDTO: $searchDTO, pageableDTO: $pageableDTO) {
			content {
				id
				nome
				value: id
				codigo
				label: nome
			}
		}
	}
`;

export const GET_ENTITY_ID = gql`
	query findByIdEntidade($id: UUID) {
		findByIdEntidade(id: $id) {
			ativo
			codigo
			documento
			email
			id
			nome
			tipo
			responsavelNome
			responsavelCpf
			usuario {
				id
			}
			dadoBancario {
				agencia
				conta
				documento
				nome
				codigoVerificador
				banco {
					id
					nome
				}
			}
		}
	}
`;

export const GET_ENTITY_FISICA_ID = gql`
	query findByIdEntidade($id: UUID) {
    findByIdEntidadeFisica(id: $id) {
      ativo
      codigo
      cpf
      email
      id
      nome
      tipo
      responsavelNome
      responsavelCpf
      usuario {
        id
        __typename
      }
      dadoBancario {
        agencia
        conta
        documento
        nome
        banco {
          id
          nome
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;

export const GET_VENDA_CLIENTE_PAGAMENTO_ID = gql`
	query findByIdVendaClientePagamento($id: UUID, $cpf: String) {
    findByIdVendaClientePagamento(id: $id, cpf: $cpf) {
      id
      valor
      contratante {
        cnpj
        nomeFantasia
        telefone
        email
        endereco {
          nomeLogradouro
          numero
          cep
          estado {
            nome
          }
          municipio {
            nome
            uf
          }
          bairro
        }

      }
      vendedor {
        nome
        responsavelNome
      }
      plano {
        nome
        valor
      }
      unidade {
        nome
        nomeFantasia
        endereco {
          nomeLogradouro
          numero
          cep
          estado {
            nome
          }
          municipio {
            nome
          }
          bairro
        }
      }
    }
  }
`;

export const GET_ENTITY_JURIDICA_ID = gql`
	query findByIdEntidade($id: UUID) {
    findByIdEntidadeJuridica(id: $id) {
      razaoSocial
      nomeFantasia
      cnpj
      ativo
      codigo
      email
      id
      tipo
      responsavelNome
      responsavelCpf
      usuario {
        id
        __typename
      }
      dadoBancario {
        agencia
        conta
        documento
        nome
        banco {
          id
          nome
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;

export const GET_ENTITY_BY_CODE = gql`
	query findEntidadeNomeByCodigo($codigo: String!) {
		findEntidadeNomeByCodigo(codigo: $codigo)
	}
`;

export const GET_ENTITY_NIVEL_ROLE_BY_CODE = gql`
	query($codigo: String!){
    findEntidadeNivelAuthorityByCodigo(codigo: $codigo)
  }
`;

export const LIST_ESPECIALIDADES_MEDICAS = gql`
  query($search: SearchDTOInput, $pageable:PageableDTOInput){
    findAllEspecialidadeMedica(searchDTO: $search, pageableDTO: $pageable){
      content{
        label: especialidade
        value: id
      }
    }
  }
`;

export const CONSULTA_CNPJ = gql`
  query($cnpj:String){
    consultaDeCnpj(cnpj: $cnpj) {
      cep
      nomeFantasia
      numeroEndereco
      razaoSocial
	  }
  }
`;

export const CONSULTA_CEP = gql`
  query ($cep: String) {
    consultaDeCep(cep: $cep) {
      cep
      bairro
      cidade {
        value: id
        label: nome
        codigoIbge
      }
      municipio {
        value: id
        label: nome
        uf
        estado {
          value: id
          label: nome
        }
      }
      logradouro
      tipoLogradouro {
        label: tipo
        value: id
      }
    }
  }
`;

export const POSSUI_VENDAS_COM_OUTRO_VENDEDOR = gql`
  query($cnpj: String){
    possuiVendasComOutroVendedor(cnpj: $cnpj)
  }
`;

export const DROPDOWN_TIPO_LOGRADOURO = gql`
	query findAllTipoLogradouro($search: SearchDTOInput){
    findAllTipoLogradouro(searchDTO: $search){
      last
      content{
        value: id
        label: descricao
      }
    }
  }
`;

export const DROPDOWN_UF = gql`
  {
      findAllEstadoList {
        value: uf
        label: uf
      }
  }
`;

export const QUANTIDADE_AGENDAMENTOS_PASSADOS = gql`
    query($unidade: UnidadeInput){
      quantidadeAgendamentosPassados(unidade: $unidade)
    }
`;

export const QUANTIDADE_AGENDAMENTOS_FUTUROS = gql`
    query($unidade: UnidadeInput){
      quantidadeAgendamentosFuturos(unidade: $unidade)
    }
`;

export const FIND_ALL_TIPO_PROFISSIONAL_SAUDE_LIST = gql`
  query{
    findAllTipoProfissionalSaudeList{
      value: id
      name:nome
      descricao
    }
  }
`;
