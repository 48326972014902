import React from 'react';
import styled from '@emotion/styled'
import pagamentoSucessoImg from '../../../assets/images/pagamentoSucesso.svg';

const PagamentoSucesso = ({responsavelCpf}) => {

  const handleAcessarSistema = () => {
    window.open(`${process.env.REACT_APP_APPHEALTH_URL}/#/login?documento=${responsavelCpf || ''}`);
  };

	return (
		<PageContainer>
      <BackgroundImage>
        <BackgroundColor>
            <PagamentoSucessoImg src={pagamentoSucessoImg} alt="Pessoas comemorando" />
        </BackgroundColor>
      </BackgroundImage>
      <Panel>
        <Form>
          <Titulo>
            Sucesso
          </Titulo>
          <Subtitulo>
            Método de pagamento recorrente cadastrado com Sucesso!
          </Subtitulo>
          <Subtitulo>
            Agora desfrute do melhor sistema para a gestão da sua clínica.
          </Subtitulo>

          <AcessarSistemaButton onClick={handleAcessarSistema}>
            Acessar meu sistema
          </AcessarSistemaButton>
        </Form>
      </Panel>
		</PageContainer>
	)
};

const PageContainer = styled.div`
	display: flex;
  width: 100%;
  height: 100vh;
	justify-content: center;
	align-items: center;
  @media(max-width: 900px) {
    flex-direction: column;
    overflow: hidden scroll;
  }
`;

const Panel = styled.div`
	display: flex;
  flex-direction: column;
  width: 50%;
  justify-content: center;
  align-items: center;
  margin-bottom: 60px;
`;

const BackgroundImage = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 100%;
  background-color: #EBEBEB;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.03), 0px 7px 25px rgba(38, 172, 169, 0.08), 0px 5px 25px rgba(38, 172, 169, 0.07);
  @media(max-width: 900px) {
    width: 100%;
    flex-direction: column;
    height: 100%;
  }
`;

const BackgroundColor = styled.div`
  display: flex;
  justify-content: center;
  background-color: #219A97;
  width: 100%;
  height: 80vh;
`;

const Form = styled.div`
  max-width: 400px;
  display: flex;
  flex-direction: column;
`;

const Titulo = styled.h1`
  font-family: 'Poppins';
  font-weight: 800;
  font-size: 54px;
  color: #505050;
  margin-bottom: 18px;
`;

const Subtitulo = styled.h2`
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 24px;
  color: #505050;
  margin-bottom: 18px;
`;

const PagamentoSucessoImg = styled.img`
  width: 40vw;
  margin-top: 10vh;
  height: 90vh;
  @media(max-width: 900px) {
    height: 50%;
    width: 100vw;
    margin-top: 30vh;
  }
`;

const AcessarSistemaButton = styled.button`
	margin-top: 12px;
	color: #fff;
	font-size: 14px;
	border: none;
	outline: none;
	cursor: pointer;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  position: static;
  width: 100%;
  height: 56px;
  left: 0px;
  top: 327px;
  background: #219A97;
  box-shadow: 12px 16px 24px rgba(0, 0, 0, 0.1);
  border-radius: 100px;
`;

export default (PagamentoSucesso);
