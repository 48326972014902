import React from 'react';
import styled from '@emotion/styled';

const Table = ({children, columns = []}) => {
  return (
    <TableStyled>
      <thead>
      <tr>
        {columns.map((column, index) => (
          <th key={index}>
            {column.title}
          </th>
        ))}
      </tr>
      </thead>
      <tbody>
      {children}
      </tbody>
    </TableStyled>
  )
};

const TableStyled = styled.table`
	width: 100%;
    display: table;
    border-spacing: 0;
    border-collapse: collapse;

	& th, td {
		color: #969696;
		display: table-cell;
		padding: 4px 16px 4px 24px;
		text-align: left;
		border-bottom: 1px solid rgba(224, 224, 224, 1);
		vertical-align: inherit;
		white-space: nowrap
	}
	& th {
		font-size: 0.75rem;
    font-weight: 500;
		text-transform: uppercase;
	}

	& tr {
		color: inherit;
		height: 48px;
		display: table-row;
		outline: none;
		vertical-align: middle;
	}

    & thead tr {
        height: 56px;
    }
`;

export default Table;
