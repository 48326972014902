import React from "react";
import styled from "@emotion/styled";
import moment from 'moment';
import Colors from '../../../theme/Colors';
import Number from '../../../utils/number';
import string from '../../../utils/string';
import { useQuery } from 'react-apollo';

import {
    QUANTIDADE_AGENDAMENTOS_PASSADOS,
    QUANTIDADE_AGENDAMENTOS_FUTUROS
    } from '../../../graphql/queryes';

const DetalhesDaVenda = ({ dados }) => {

    const quantidadeAgentamentoPassado = useQuery(QUANTIDADE_AGENDAMENTOS_PASSADOS, {
        variables: {
            unidade: {id: dados.unidade.id},
        }
    });

    const quantidadeAgendamentoFuturo = useQuery(QUANTIDADE_AGENDAMENTOS_FUTUROS, {
        variables: {
            unidade: {id: dados.unidade.id},
        }
    });

    return (
        <InfoContainer>
            <InputGroup>
                <Label>Status</Label>
                <InputValue>{dados && dados.status ? string.capitalize(dados.status).replace('_', ' ') : '-'}</InputValue>
            </InputGroup>
            <InputGroup>
                <Label>Lançamento</Label>
                <InputValue>
                    {dados && dados.dataLancamento
                        ? moment(dados.dataLancamento).format('DD/MM/YYYY HH:mm')
                        : '-'}
                </InputValue>
            </InputGroup>
            <InputGroup>
                <Label>Valor por profissional de saúde</Label>
                <InputValue>
                    {dados ? Number.currencyFormat(dados.valorPorProfissionalSaude) : '-'}
                </InputValue>
            </InputGroup>
            <InputGroup>
                <Label>Valor</Label>
                <InputValue>
                    {dados ? Number.currencyFormat(dados.valor) : '-'}
                </InputValue>
            </InputGroup>
            <InputGroup>
                <Label>Dias período grátis</Label>
                <InputValue>{dados ? dados.diasPeriodoGratis : '-'}</InputValue>
            </InputGroup>
            <InputGroup>
                <Label>Plano</Label>
                <InputValue>
                    {dados && dados.plano ? dados.plano.nome || '-' : '-'}
                </InputValue>
            </InputGroup>
            <InputGroup>
                <Label>Data de vencimento: </Label>
                <InputValue>
                    {dados && dados.unidade.dataVencimento
                    ? moment(dados.unidade.dataVencimento).format('DD/MM/YYYY HH:mm')
                    : '-'}
                </InputValue>
            </InputGroup>
            <InputGroup>
                <Label>Meio pagamento </Label>
                <InputValue>
                    {dados.meioPagamentoPlataforma ? string.capitalize(dados.meioPagamentoPlataforma) : '-'}
                </InputValue>
            </InputGroup>
            <InputGroup>
                <Label>Quantidade agendamentos futuros</Label>
                <InputValue>
                    {dados ? quantidadeAgendamentoFuturo.data?.quantidadeAgendamentosFuturos : '-'}
                </InputValue>
            </InputGroup>
            <InputGroup>
                <Label>Agendamentos dos últimos 5 dias</Label>
                <InputValue>
                    {dados ? quantidadeAgentamentoPassado.data?.quantidadeAgendamentosPassados : '-'}
                </InputValue>
            </InputGroup>
          <InputGroup>
            <Label>Id da unidade: </Label>
            <InputValue>
              {dados && dados.unidade.id}
            </InputValue>
          </InputGroup>
        </InfoContainer>

    )
}

export default DetalhesDaVenda;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin: 10px;
`;

const InputGroup = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0px;
  flex-wrap: nowrap;
  align-items: center;
`;

const Label = styled.p`
  font-size: 12px;
  text-align: left;
  color: ${Colors.commons.fontColor};
  padding: 5px;
  margin: 0px;
  flex-wrap: wrap;

`;

const InputValue = styled.p`
  font-size: 14px;
  text-align: left;
  color: ${Colors.primary.main};
  margin-left: 5px;
  margin-rigth: 0px;
  margin-bottom: 0px;
  margin-top: 0px;
  flex-wrap: wrap;
`;