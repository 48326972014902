import React from 'react';
import styled from '@emotion/styled';
import { useHistory } from 'react-router-dom';

import Flex from '../../components/flex';
import { SIGNUP } from '../../router/names';
import Profile from './profile';
import { useSelector, useDispatch } from 'react-redux';
import { logout } from '../../redux';

const Header = () => {
	const dispatch = useDispatch();
	const { replace } = useHistory();
	const { pageTitle } = useSelector(state => state.Page);
	const { usuarioLogado } = useSelector(state => state.Auth);

	const handleLogout = () => {
		dispatch(logout());
		replace(SIGNUP);
	};

	return (
		<Container>
			<PageTitle>{pageTitle}</PageTitle>
			<Profile username={usuarioLogado ? usuarioLogado.nome : ''} onLogout={handleLogout} />
		</Container>
	)
};

const Container = styled(Flex)`
	justify-content: space-between;
	align-items: center;
	padding: 0 3% 0 2.3%;
	height: 100%;
`;

const PageTitle = styled.h3`
	color: #00B0AE;
    font-size: 25px;
    text-align: center;
    font-weight: bold;
`;

export default Header;
