import React from "react";
import styled from "@emotion/styled";
import Colors from '../../../theme/Colors';
import { cpfCnpjFormatted } from '../../../utils/masks';

const DetalhesDoVendedor = ({ dados }) => {

    return (
        <InfoContainer>
            <InputGroup>
                <Label>Nome do vendedor</Label>
                <InputValue>
                    {dados && dados.vendedor ? dados.vendedor.nome || '-' : '-'}
                </InputValue>
            </InputGroup>
            <InputGroup>
                <Label>Documento do vendedor</Label>
                <InputValue>
                    {dados && dados.vendedor ? cpfCnpjFormatted(dados.vendedor.documento) : '-'}
                </InputValue>
            </InputGroup>
            <InputGroup>
                <Label>E-mail do vendedor</Label>
                <InputValue>
                    {dados && dados.vendedor ? dados.vendedor.email || '-' : '-'}
                </InputValue>
            </InputGroup>
        </InfoContainer>

    )
}

export default DetalhesDoVendedor;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin: 10px;
`;

const InputGroup = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0px;
  flex-wrap: nowrap;
  align-items: center;
`;

const Label = styled.p`
  font-size: 12px;
  text-align: left;
  color: ${Colors.commons.fontColor};
  padding: 5px;
  margin: 0px;
  flex-wrap: wrap;

`;

const InputValue = styled.p`
  font-size: 14px;
  text-align: left;
  color: ${Colors.primary.main};
  margin-left: 5px;
  margin-rigth: 0px;
  margin-bottom: 0px;
  margin-top: 0px;
  flex-wrap: wrap;
`;