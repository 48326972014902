import currency from 'currency.js';

const Number = {
  format: (value, locales = 'pt-BR', options = {}) => {
    if (!parseInt(value, 10)) {
      return '0';
    }
    return new Intl.NumberFormat(locales, {
      maximumFractionDigits: 2,
      ...options
    }).format(value);
  },
  currencyFormat: (value, locales = 'pt-BR', options = { currency: 'BRL' }) => {
    let currentValue = value;
    if (!parseInt(value, 10)) {
      currentValue = 0;
    }
    return new Intl.NumberFormat(locales, {
      ...options,
      style: 'currency'
    }).format(currentValue);
  },
  currencyToFloat: value => {
    return currency(value, { separator: '.', decimal: ',' }).value;
  },
  stringToFloat: value => {
    return currency(value, { separator: '.', decimal: ',' }).value;
  }
};

export default Number;
