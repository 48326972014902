import React from 'react';
import styled from '@emotion/styled';
import AttachMoneyIcon from '@material-ui/icons/AttachMoneyOutlined';
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined';
import ReceiptOutlinedIcon from '@material-ui/icons/ReceiptOutlined';
import AccountBalanceWalletOutlinedIcon from '@material-ui/icons/AccountBalanceWalletOutlined';
import {Link} from 'react-router-dom';

import logoMenu from '../../assets/images/logoMenu.png';
import Flex from '../../components/flex';
import { DASHBOARD, MOVIMENTACAO_FINANCEIRA, FECHAMENTO, VENDAS } from '../../router/names';
import ItemMenu from './item-menu';
import {obterEntidadeAtualLocal} from "../../services/usuario-service";

const Menu = ({ hasMenu }) => {

  const usuarioLogado = obterEntidadeAtualLocal();
  const urlSistema = process.env.REACT_APP_APPHEALTH_URL + "/#/login?documento=";

  const documentUsuarioLogado = () => {
    return usuarioLogado.responsavelCpf;
  };

	return (
		<Container flexDirection="column">
      {usuarioLogado?.nivel?.nome === "Comercial" ?
        <a title={"Sistema"} href={urlSistema + documentUsuarioLogado()} target={"_blank"}>
				  <LogoContainer>
				  	<Logo src={logoMenu} alt="logo" />
				  </LogoContainer>
        </a>
        :
        <Link to={!!hasMenu ? DASHBOARD : '#'}>
          <LogoContainer>
            <Logo src={logoMenu} alt="logo" />
          </LogoContainer>
        </Link>
      }
			{
				!!hasMenu && <>
					<ItemMenu title="Entidades" to={DASHBOARD} icon={PeopleAltOutlinedIcon} />
					<ItemMenu title="Movimentação Financeira" to={MOVIMENTACAO_FINANCEIRA} icon={ReceiptOutlinedIcon} />
					<ItemMenu title="Fechamento" to={FECHAMENTO} icon={AccountBalanceWalletOutlinedIcon} />
					<ItemMenu title="Vendas" to={VENDAS} icon={AttachMoneyIcon} />
				</>
			}
		</Container>
	)
};

const Container = styled(Flex)`
	flex-grow: 0;
    max-width: 100%;
    flex-basis: 100%;
`;

const LogoContainer = styled.div`
	display: flex;
    align-items: center;
    justify-content: center;
    margin: 17px 0;
`;
const Logo = styled.img`
	width: 75.7143%;
`;

export default Menu;
