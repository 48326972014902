import React, { useState } from 'react';
import styled from '@emotion/styled'
import ApolloPublicInstance from '../../apollo/setupClientePagamento';
import VerificarAcesso from "./components/verificarAcesso";
import Checkout from "./components/checkout";
import PagamentoSucesso from "./components/pagamentoSucesso";

const PagamentoVenda = () => {
  const [verificarAcesso, setVerificarAcesso] = useState(false);
  const [showPagamentoSucesso, setShowPagamentoSucesso] = useState(false);
  const [responsavelCpf, setResponsavelCpf] = useState('14661945298');
  const [dadosVenda, setDadosVenda] = useState({
    id: '',
    valor: '',
    contratante: {
      cnpj: '',
      nomeFantasia: ''
    }
  });

  const handleIrAoCheckout = () => {
    setVerificarAcesso(true);
  }

  const handlePagamentoSucesso = () => {
    setShowPagamentoSucesso(true);
  }

  const atualizarDados = (dadosAtualizados, loginCpf) => {
    setResponsavelCpf(loginCpf);
    setDadosVenda({
      ...dadosVenda,
      ...dadosAtualizados
    });
  };

	return (
    <Content>
      {showPagamentoSucesso ?
        <PagamentoSucesso
          responsavelCpf={responsavelCpf}
        />
        :
        (!verificarAcesso ?
          <VerificarAcesso
            handleIrAoCheckout={handleIrAoCheckout}
            handleAtualizarDados={atualizarDados}
          />
        :
          <Checkout
            dadosVenda={dadosVenda}
            handlePagamentoSucesso={handlePagamentoSucesso}
          />
        )
      }
    </Content>
	)
};

const HocApolloInstance = (Component) => () => (
  <ApolloPublicInstance>
    <Component/>
  </ApolloPublicInstance>
);

const Content = styled.div`
	display: flex;
  width: 100vw;
  height: 100vh;
`;

export default HocApolloInstance(PagamentoVenda);
