import React, { useState, useEffect } from "react";
import { useMutation, withApollo } from "react-apollo";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import Typography from "@material-ui/core/Typography";

import Flex from "../../components/flex";
import TextFieldSearch from "../../components/TextField/TextFieldSearch";
import { CRIAR_VENDA, CRIAR_VENDA_SEM_CARTAO } from "../../graphql/mutations";
import { DROPDOWN_PLANOS } from "../../graphql/queryes";
import styled from "@emotion/styled";
import { TextField } from "../../components/TextField";
import Colors from "../../theme/Colors";
import { useHistory } from "react-router-dom";
import { VISUALIZAR_VENDA, DASHBOARD } from "../../router/names";
import InputDateForm from "../../components/input/InputDateForm";
import Notification from "../../components/notification/Notification";
import string from "../../utils/string";
import canCriarVenda from "../../services/authority-service/can-criar-venda";

const notificationDefault = {
	variant: '',
	message: '',
	isOpen: false,
};

const CriarVenda = ({ client }) => {
	const history = useHistory();
	const [notification, setNotification] = useState({
		...notificationDefault,
	});
	const [criando, setCriando] = useState(false);
	const [loadingPage, setLoadingPage] = useState(true);
	const [formData, setFormData] = useState({
		plano: null,
		unidadeNome: '',
		expirationDate: null,
	});

	const [formError, setFormError] = useState({});
	const [criarVenda] = useMutation(CRIAR_VENDA);
	const [criarVendaSemCartao] = useMutation(CRIAR_VENDA_SEM_CARTAO);

	const handleSubmit = async e => {
		e.preventDefault();

		if (formError?.diasPeriodoGratis?.error) {
			setNotification({
				variant: 'error',
				isOpen: true,
				message: 'Por favor, corrija o campo Dias Período Grátis.',
			});
			return;
		}

		try {
			setCriando(true);
			let cartaoCredito;
			let cartaoCreditoErrors = {};

			if (!string.isEmpty(formData.cardNumber)) {
				let emptyCreditCard = false;
				if (string.isEmpty(formData.holder) || !formData.expirationDate || string.isEmpty(formData.securityCode)) {
					setNotification({
						variant: 'error',
						isOpen: true,
						message: 'Por favor, preencha todos os campos do cartão de crédito.',
					});
					emptyCreditCard = true;
				}

				if (string.isEmpty(formData.holder)) {
					cartaoCreditoErrors = {
						...cartaoCreditoErrors,
						holder: {
							error: true,
							message: 'Campo obrigatório.'
						}
					};
				}

				if (!formData.expirationDate) {
					cartaoCreditoErrors = {
						...cartaoCreditoErrors,
						expirationDate: {
							error: true,
							message: 'Campo obrigatório.'
						}
					};
				}

				if (string.isEmpty(formData.securityCode)) {
					cartaoCreditoErrors = {
						...cartaoCreditoErrors,
						securityCode: {
							error: true,
							message: 'Campo obrigatório.'
						}
					};
				}

				if (emptyCreditCard) {
					setNotification({
						variant: 'error',
						isOpen: true,
						message: 'Por favor, preencha os campos do cartão de crédito.',
					});
					setFormError({
						...formError,
						...cartaoCreditoErrors,
					});
					setCriando(false);
					return;
				}

				cartaoCredito = {
					cardNumber: string.removeSpecialChars(formData.cardNumber),
					holder: formData.holder,
					expirationDate: formData.expirationDate ? moment(formData.expirationDate).startOf('month').format('YYYY-MM-DD') : undefined,
					securityCode: formData.securityCode,
				};
			}

			if (!cartaoCredito) {
				const { data } = await criarVendaSemCartao({
					variables: {
						unidadeNome: formData.unidadeNome,
						diasPeriodoGratis: formData.diasPeriodoGratis,
						quantidadeProfissionaisSaudeComAgenda: formData.quantidadeProfissionaisSaudeComAgenda,
						planoId: formData.plano.value
					}
				});

				const vendaId = data?.createVenda?.id || 0;

				history.push(`${VISUALIZAR_VENDA.replace(':vendaId', vendaId)}`);
				return;
			}

			const { data } = await criarVenda({
				variables: {
					cartaoCredito,
					unidadeNome: formData.unidadeNome,
					diasPeriodoGratis: formData.diasPeriodoGratis,
					quantidadeProfissionaisSaudeComAgenda: formData.quantidadeProfissionaisSaudeComAgenda,
					planoId: formData.plano.value
				}
			});

			const vendaId = data?.createVenda?.id || 0;

			history.push(`${VISUALIZAR_VENDA.replace(':vendaId', vendaId)}`);
		} catch ({ graphQLErrors }) {
			if (graphQLErrors) {
				setNotification({
					variant: 'error',
					isOpen: true,
					message: graphQLErrors[0].message,
				});
			}
			setCriando(false);
		}
	};

	const handleLoadMoreOptions = async ({ variables, query, responseObject }) => {
		const response = await client.query({
			query,
			variables
		});

		const pageNumber = variables?.searchDTO?.pageNumber || 0;
		if (pageNumber === 0
			&& response?.data?.[responseObject].length > 0
			&& response?.data?.[responseObject].length < 2) {
			setFormData({ ...formData, plano: response?.data?.[responseObject][0] })
		}

		return {
			options: response?.data?.[responseObject] || [],
			hasMore: false,
			additional: {
				page: pageNumber + 1
			}
		};
	};

	useEffect(() => {
		handlePlanoLoadOptions(undefined, undefined, { page: 0 });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handlePlanoLoadOptions = async (search, loadedOptions, { page }) => {
		return handleLoadMoreOptions({
			loadedOptions,
			variables: {
				searchDTO: {
					pageSize: 150,
					pageNumber: page,
					search
				}
			},
			responseObject: 'findAllPlanoAtivo',
			query: DROPDOWN_PLANOS
		});
	};

	const handleChangePlano = (e) => {
		setFormData({
			...formData,
			plano: e,
		});
	};

	const handleChangeDiasPeriodoGratis = (e) => {
		const diasPeriodoExperiencia = formData.plano?.diasPeriodoExperiencia || 0;
		const diasPeriodoGratis = e.target.value;

		setFormError({
			...formError,
			diasPeriodoGratis: null,
		});

		if (diasPeriodoGratis > diasPeriodoExperiencia) {
			setFormError({
				...formError,
				diasPeriodoGratis: {
					error: true,
					message: `Limite máximo para período gratuito é de ${diasPeriodoExperiencia} ${diasPeriodoExperiencia === 1 ? 'dia' : 'dias'}`,
				}
			});
		}

		setFormData({
			...formData,
			diasPeriodoGratis,
		});
	};

	const renderTextField = (label, field, mask) => (
		<>
			<Typography color="primary" component="label">
				{label}
			</Typography>
			<TextField
				value={formData[field]}
				mask={mask}
				error={formError[field]?.error || undefined}
				helperText={formError[field]?.message || undefined}
				onChange={e => {
					setFormData({
						...formData,
						[field]: e.target.value,
					});

					if (!string.isEmpty(e.target.value)) {
						setFormError({
							...formError,
							[field]: null,
						});
					}
				}}
			/>
		</>
	);

	useEffect(() => {
		if (!canCriarVenda()) {
			history.replace(DASHBOARD);
			return;
		}

		setLoadingPage(false);
	}, [history]);

	if (loadingPage) {
		return (
			<Flex flexDirection="column">
				<Grid container direction="row" spacing={2}>
					<Grid item xs={12}>
						<FormHeading>Carregando...</FormHeading>
					</Grid>
				</Grid>
			</Flex>
		);
	}

	return (
		<Flex flexDirection="column">
			<form onSubmit={handleSubmit}>
				<Grid container direction="row" spacing={2}>
					<FormGroup item xs={6}>
						<Typography color="primary" component="label">
							Plano
						</Typography>
						<TextFieldSearch
							placeholder=""
							loadOptions={handlePlanoLoadOptions}
							withPaginate
							value={formData.plano}
							onChange={handleChangePlano}
							debounceTimeout={300}
							additional={{
								page: 0
							}}
						/>
					</FormGroup>
					<FormGroup item xs={6}>
						{formData.plano && (
							<>
								<Typography color="primary" component="label">
									Dias Período Grátis
								</Typography>
								<TextField
									value={formData.diasPeriodoGratis}
									type="number"
									error={formError?.diasPeriodoGratis?.error || undefined}
									helperText={formError?.diasPeriodoGratis?.message || undefined}
									onChange={handleChangeDiasPeriodoGratis}
								/>
							</>
						)}
					</FormGroup>
					<FormGroup item xs={6}>
						{renderTextField('Qtd. Profissionais de Saúde com Agenda', 'quantidadeProfissionaisSaudeComAgenda')}
					</FormGroup>
					<FormGroup item xs={6}>
						{renderTextField('Nome da Unidade', 'unidadeNome')}
					</FormGroup>
					<Grid item xs={12}>
						<FormHeading>Cartão de Crédito</FormHeading>
					</Grid>
					<FormGroup item xs={6}>
						{renderTextField('Nome no Cartão de Crédito', 'holder')}
					</FormGroup>
					<FormGroup item xs={6}>
						{renderTextField('Número do Cartão de Crédito', 'cardNumber', 'creditCard')}
					</FormGroup>
					<FormGroup item xs={6}>
						{renderTextField('Código de Segurança', 'securityCode')}
					</FormGroup>
					<FormGroup item xs={6}>
						<Typography color="primary" component="label">
							Data de Validade
						</Typography>
						<InputDateForm
							variant="outlined"
							fullWidth
							openTo="month"
							views={["year", "month"]}
							value={formData.expirationDate}
							onChange={(e) => {
								setFormData({
									...formData,
									expirationDate: e,
								})
							}}
							error={formError?.expirationDate?.error || undefined}
							helperText={formError?.expirationDate?.message || undefined}
							format="MM/YYYY"
							placeholder={"__/____"}
							mask={value => (value ? [/\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/] : [])}
						/>
					</FormGroup>
				</Grid>
				<FormGroup item xs={12}>
					<ButtonsContainer>
						<Button type="submit" disabled={criando}>{criando ? 'Criando...' : 'Criar'}</Button>
					</ButtonsContainer>
				</FormGroup>
				<Notification
					close={() => {
						setNotification({
							...notificationDefault,
						});
					}}
					reset={() => {
						setNotification({
							...notificationDefault,
						});
					}}
					isOpen={notification.isOpen}
					variant={notification.variant}
					message={notification.message}
				/>
			</form>
		</Flex>
	);
}

const FormGroup = styled(Grid)`
	margin-bottom: 15px !important;
`;
const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const Button = styled.button`
  border: 0;
  background-color: ${Colors.primary.main};
  color: #fff;
  padding: 5px 10px;
  box-sizing: border-box;
  height: 34px;
  min-width: 150px;
  border-radius: 6px;
  margin: 8px 0;
  cursor: pointer;
`;

const FormHeading = styled.h3`
	color: ${Colors.commons.gray5};
	margin: 15px 0 0;
`;

export default withApollo(CriarVenda);
