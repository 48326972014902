import Switch from '@material-ui/core/Switch';
import {green, red} from '@material-ui/core/colors';
import {withStyles} from '@material-ui/core/styles';
import React from "react";
import FormControlLabel from '@material-ui/core/FormControlLabel';

const SwitchAtivo = withStyles({
  switchBase: {
    color: red[100],
    '&$checked': {
      color: green[500],
    },
    '&$checked + $track': {
      backgroundColor: green[700],
    },
  },
  checked: {},
  track: {},
})(Switch);

const AtivoSwitch = (props) => {
  const {ativo, handleClick} = props;

  return (
    <FormControlLabel
      control={
        <SwitchAtivo
          checked={ativo}
          onChange={handleClick}
          value={ativo}
        />
      }
      placeholder={ativo ? "Ativo" : "Inativo"}
    />
  )
};

export default AtivoSwitch;
