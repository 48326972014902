import Typography from "@material-ui/core/Typography";
import {TextField} from "./index";
import React from "react";

const RenderTextField = (props) => {

  const { label, field, mask, error, errorText, handleChange, disabled, maxLength } = props;

  return (
    <>
      <Typography color="primary" component="label">
        {label}
      </Typography>
      <TextField
        value={field}
        mask={mask}
        error={error}
        helperText={error && errorText}
        onChange={handleChange}
        disabled={disabled}
        maxLength={maxLength}
      />
    </>
  )
};

export default RenderTextField;
