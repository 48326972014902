import { obterUsuarioLogadoLocal } from '../usuario-service';

export const getAuthorities = (filterAuthorities = []) => {
  const usuarioLogado = obterUsuarioLogadoLocal();
  const authorities =
    usuarioLogado && usuarioLogado.authorities ? usuarioLogado.authorities : [];

  if (filterAuthorities && filterAuthorities.length > 0) {
    return authorities.filter(a => filterAuthorities.includes(a.authority));
  }

  return authorities;
};
