import React from "react";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core";
import {TextField} from "./index";

const TextFieldCheckout = (props) => {

  const { label, field, mask, error, errorText, handleChange, disabled, maxLength, classes } = props;
  return (
    <>
        <div className={classes.root}>
          <Typography component="h1" className={classes.label}>
            {label}
          </Typography>
          <TextField
            value={field}
            mask={mask}
            error={error}
            helperText={error && errorText}
            onChange={handleChange}
            disabled={disabled}
            maxLength={maxLength}
            className={classes.inputCpf}
            InputProps={{
              ...props.InputProps,
              classes: {
                ...classes,
                notchedOutline: classes.specialOutline,
                input: classes.inputLabel,
                mask: mask
              }
            }}
          />
        </div>
    </>
  )
};

const style = (props) => ({
  root: {
    margin: '8px'
  },
  inputCpf: {
    borderRadius: '100px',
    borderStyle: 'none',
    height: '56px',
  },
  label: {
    color: props => props.color === 'white' ? '#F2F2F2' : '#7C7C7C',
    fontWeight: '600',
    fontSize: '16px',
  },
  inputLabel: {
    color: '#5F6368',
    height: '32px',
    fontSize: '16px',
    background: props => props.color === 'white' ? '#F2F2F2' : 'rgba(38, 172, 169, 0.2)',
    borderRadius: '100px',

  },
  specialOutline: {
    borderRadius: '100px',
    borderStyle: 'none',
  }
});

export default withStyles(style)(TextFieldCheckout);
