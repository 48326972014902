import styled from '@emotion/styled';

import Box from '../box';

const Flex = styled(Box)``;

Flex.defaultProps = {
	display: "flex"
};

export default Flex;