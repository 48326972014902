import React from 'react';
import {ApolloProvider} from 'react-apollo';
import {ApolloClient} from 'apollo-client';
import {createHttpLink} from 'apollo-link-http';
import {defaultDataIdFromObject, InMemoryCache} from 'apollo-cache-inmemory';
import {axiosAuthClientePagamento} from '../services/axios';
import {setContext} from 'apollo-link-context';

const ApolloPublicInstance = ({children}) => {
  const [client, setClient] = React.useState(null);

  const configureApollo = async () => {
    try {
      const {data} = await axiosAuthClientePagamento.post('/oauth/token', undefined, {
        params: {
          grant_type: "client_credentials",
          client_id: "CLIENTE_PAGAMENTO"
        }
      });

      const authLink = setContext((_, {headers}) => ({
        headers: {
          ...headers,
          authorization: `Bearer ${data?.access_token}`,
        }
      }));

      const httpLink = createHttpLink({
        uri: process.env.REACT_APP_BASE_URL_GQL,
      });

      const cache = new InMemoryCache({
        dataIdFromObject: object => {
          switch (object.__typename) {
            case 'Review':
              return object.reviewId;
            default:
              return defaultDataIdFromObject(object)
          }
        }
      });

      const client = new ApolloClient({
        cache,
        link: authLink.concat(httpLink),
      });

      setClient(client);

    } catch (err) {
      alert('Tivemos um problema, contate o suporte.')
    }

  };

  React.useEffect(() => {
    configureApollo();
  }, []);

  return (
    client &&
      <ApolloProvider client={client}>
        {children}
      </ApolloProvider>
  )
};

export default ApolloPublicInstance;
