import gql from 'graphql-tag';

export const NOVA_UNIDADE = gql`
	mutation NovaUnidade(
			$nome: String,
			$cnpj: String,
		) {
		createUnidade(unidade: {
			nome:$nome,
			cnpj:$cnpj
		}){
				id
		}
	}
`;

export const CRIAR_VENDA = gql`
	mutation($planoId: UUID, $contratante: ContratanteInput){
    createVenda(contratante: $contratante, planoId: $planoId){
      id
    }
  }
`;

export const ALTERAR_VENDA = gql`
	mutation($venda: VendaInput){
		updateVenda(venda: $venda){
    		id
				plano{
					id
				}
    }
  }
`;

export const FINALIZAR_VENDA = gql`
	mutation($vendaId: UUID){
		finalizarVenda(vendaId: $vendaId){
    	id
    }
  }
`;

export const CRIAR_VENDA_SEM_CARTAO = gql`
	mutation CriarVendaSemCartao(
			$unidadeNome: String,
			$diasPeriodoGratis: Long,
			$quantidadeProfissionaisSaudeComAgenda: BigInteger,
			$planoId: UUID
		) {
		createVenda(
			unidadeNome: $unidadeNome,
			diasPeriodoGratis: $diasPeriodoGratis,
			quantidadeProfissionaisSaudeComAgenda: $quantidadeProfissionaisSaudeComAgenda,
			planoId: $planoId
		) {
			id
		}
	}
`;

export const CRIAR_UNIDADE = gql`
	mutation CriarUnidade(
			$venda: VendaInput,
			$unidade: UnidadeInput
		) {
		createUnidade(
			venda: $venda,
			unidade: $unidade
		) {
			id
		}
	}
`;

export const ADICIONAR_CARTAO_CREDITO = gql`
	mutation AdicionarCartaoCredito(
			$vendaId: UUID,
			$cartaoCredito: CartaoCreditoInput
		) {
		adicionarCartaoCreditoAVenda(vendaId: $vendaId, cartaoCredito: $cartaoCredito) {
			id
		}
	}
`;

export const ADICIONAR_BOLETO = gql`
	mutation AdicionarBoleto($vendaId: UUID, $quantidadeParcelas: Long) {
		adicionarBoletoAVenda(vendaId: $vendaId, quantidadeParcelas: $quantidadeParcelas) {
			id
		}
	}
`;

export const ADICIONAR_CARTAO_CREDITO_VENDA_CLIENTE_PAGAMENTO = gql`
	mutation adicionarCartaoCreditoAVendaClientePagamento(
			$vendaId: UUID,
			$cartaoCredito: CartaoCreditoInput
		) {
    adicionarCartaoCreditoAVendaClientePagamento(vendaId: $vendaId, cartaoCredito: $cartaoCredito) {
			id
		}
	}
`;

export const CRIAR_PROFISSIONAL_SAUDE = gql`
	mutation CriarProfissionalSaude(
		$profissionalSaude: ProfissionalSaudeInput
		$venda: VendaInput
		$grupoPermissoes: GroupInput
	) {
		createProfissionalSaude(
			profissionalSaude: $profissionalSaude,
			venda: $venda,
			grupoPermissoes: $grupoPermissoes,
		) {
			id
		}
	}
`;

export const UPDATE_ENTIDADE_FISICA = gql`
	mutation updateEntidadeFisica($entidadeFisicaInput: EntidadeFisicaInput) {
		updateEntidadeFisica(entidadeFisica: $entidadeFisicaInput) {
			id
		}
	}
`;

export const CREATE_ENTIDADE_FISICA_HAWK = gql`
	mutation createEntidadeFisicaHawk($entidadeFisicaInput: EntidadeFisicaInput!) {
		createEntidadeFisicaHawk(entidadeFisica: $entidadeFisicaInput) {
			id
		}
	}
`;

export const CREATE_ENTIDADE_JURIDICA_HAWK = gql`
	mutation createEntidadeJuridicaHawk($entidadeJuridicaInput: EntidadeJuridicaInput) {
		createEntidadeJuridicaHawk(entidadeJuridica: $entidadeJuridicaInput) {
			id
		}
	}
`;

export const CREATE_ENTIDADE_FISICA_PUBLIC = gql`
	mutation createEntidadeFisica($entidadeFisicaInput: EntidadeFisicaInput!, $codigo: String) {
		createEntidadeFisica(entidadeFisica: $entidadeFisicaInput, codigo: $codigo) {
			id
		}
	}
`;

export const CREATE_ENTIDADE_JURIDICA_PUBLIC = gql`
	mutation createEntidadeJuridica($entidadeJuridicaInput: EntidadeJuridicaInput!, $codigo: String) {
		createEntidadeJuridica(entidadeJuridica: $entidadeJuridicaInput, codigo: $codigo) {
			id
		}
	}
`;

export const UPDATE_ENTIDADE_JURIDICA = gql`
	mutation updateEntidadeJuridica($entidadeJuridicaInput: EntidadeJuridicaInput) {
		updateEntidadeJuridica(entidadeJuridica: $entidadeJuridicaInput) {
			id
		}
	}
`;

export const UPDATE_ENTIDADE_STATUS = gql`
	mutation alterarEntidadeAtivo($entidade: EntidadeInput, $ativo: Boolean!) {
    alterarEntidadeAtivo(entidade: $entidade, ativo: $ativo)
  }
`;

export const INICIAR_PERIODO_GRATIS = gql`
  mutation ($vendaId: UUID) {
    iniciarTesteGratisVenda(vendaId: $vendaId) {
      id
    }
  }
`;

export const DEFINIR_TESTE_GRATIS_VENDA = gql`
  mutation($vendaId: UUID, $dias: Long) {
    definirTesteGratisVenda(vendaId: $vendaId, dias: $dias) {
      id
	  diasPeriodoGratis
    }
  }
`;

export const UPDATE_UNIDADE = gql`
  mutation ($unidade: UnidadeInput) {
    updateUnidade(unidade: $unidade) {
      id
    }
  }
`;

export const UPDATE_PROFISSIONAL_SAUDE = gql`
  mutation ($profissionalSaude: ProfissionalSaudeInput) {
    updateProfissionalSaude(profissionalSaude: $profissionalSaude) {
      id
    }
  }
`;

export const UPDATE_VENDA_PLANO = gql`
  mutation ($vendaId: UUID, $planoId: UUID) {
    updateVendaPlano(vendaId: $vendaId, planoId: $planoId)
  }
`;

export const UPDATE_EXTENDER_PERIODO_GRATIS = gql`
  mutation ($vendaId: UUID) {
    extenderTesteGratisVenda(vendaId: $vendaId) {
      id
    }
  }
`;
