import React from 'react';
import moment from 'moment';
import styled from '@emotion/styled';
import Grid from '@material-ui/core/Grid';
import ArrowBack from '@material-ui/icons/ArrowBack';
import List from '../../../components/list/list';
import Colors from '../../../theme/Colors';
import { useQuery } from 'react-apollo';
import { LIST_MOVIMENTACOES_FINANCEIRAS_DA_VENDA } from '../../../graphql/queryes';
import Number from '../../../utils/number';

const MovimentacoesFinanceiras = ({ dadosDaVenda = {}, onClose }) => {
  const {
    data: listMovimentacoesData,
    loading: loadingListMovimentacoes,
    error: errorListMovimentacoes,
  } = useQuery(LIST_MOVIMENTACOES_FINANCEIRAS_DA_VENDA, {
    variables: {
      venda: {
        id: dadosDaVenda.id,
      },
    },
  });

  const handleClose = () => {
    onClose && onClose();
  };

  return (
    <Container>
      <Header>
        <ArrowBackStyled onClick={handleClose} />
        <HeaderTitle>Movimentações Financeiras</HeaderTitle>
      </Header>
      <Grid container>
        <ListMovimentacoes item xs={12}>
          <List
            columns={[
              {
                title: 'Tipo',
                value: item => item.tipo,
              },
              {
                title: 'Data Lançamento',
                value: item =>
                  item.dataLancamento
                    ? moment(item.dataLancamento).format('MM/YYYY')
                    : '-',
              },
              {
                title: 'Valor Cheio',
                value: item => Number.currencyFormat(item.valorCheio),
              },
              {
                title: 'Valor Repasse',
                value: item => Number.currencyFormat(item.valorRepasse),
              },
              {
                title: '% Repasse',
                value: item => Number.format(item.percentualRepasse),
              },
              {
                title: 'Beneficiário',
                value: item =>
                  item.beneficiario ? item.beneficiario.nome || '-' : '-',
              },
            ]}
            items={
              listMovimentacoesData?.findAllMovimentacoesFinanceirasEntreUsuarioLogadoEVendedor
            }
            loading={loadingListMovimentacoes}
            error={errorListMovimentacoes}
            last
          />
        </ListMovimentacoes>
      </Grid>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  padding: 10px 20px;
`;

const Header = styled.div`
  height: 45px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;
`;

const HeaderTitle = styled.div`
  font-size: 18px;
  text-align: left;
  color: ${Colors.primary.main};
  margin-top: 4px;
  margin-left: 10px;
`;

const ArrowBackStyled = styled(ArrowBack)`
  color: ${Colors.primary.main};
  cursor: pointer;
`;

const ListMovimentacoes = styled(Grid)`
  max-width: 300px;
  overflow: auto;
`;

export default MovimentacoesFinanceiras;
