import React, {useEffect, useState} from 'react';
import styled from '@emotion/styled';
import Grid from '@material-ui/core/Grid';
import { useQuery, useMutation } from 'react-apollo';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Colors from '../../theme/Colors';
import { FIND_BY_ID_VENDA } from '../../graphql/queryes';
import {FINALIZAR_VENDA, INICIAR_PERIODO_GRATIS, UPDATE_EXTENDER_PERIODO_GRATIS} from '../../graphql/mutations'
import { useParams } from 'react-router-dom';
import ModalSlider from '../../components/modal-slider/modal-slider';
import StepperCriarProfissionalSaude from './components/criar-profissional-saude/stepperCriarProfissionalSaude';
import DetalhesDosBeneficiarios from './components/detalhes-dos-beneficiarios';
import DefinirPeriodoDiasGratis from './components/definir-periodo-dias-gratis'
import MovimentacoesFinanceiras from './components/movimentacoes-financeiras';
import MeioDeCobranca from './components/meio-de-cobranca';
import { cpfCnpjFormatted } from '../../utils/masks';
import List from '../../components/list/list';
import DadosResponsavelContratante from '../list-vendas/components/criar-venda/dadosResponsavelContratante'
import DadosBasicosContratante from '../list-vendas/components/criar-venda/dadosBasicosContratante'
import DadosEnderecoContratante from '../list-vendas/components/criar-venda/dadosEnderecoContratante'
import DadosEspecialidadesContratante from '../list-vendas/components/criar-venda/dadosEspecialidadesContratante'
import DadosImportacaoContratante from '../list-vendas/components/criar-venda/dadosImportacaoContratante'
import { Checkbox, Fab } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Dialog from '../../components/Dialog/dialog'
import DialogHeader from '../../components/Dialog/dialogHeader'
import DetalhesDaVenda from './components/detalhes-da-venda'
import DetalhesDoVendedor from './components/detalhes-do-vendedor'
import EditarUnidade from './components/editar-unidade';
import EditarProfissionalSaude from './components/editar-profissional-saude';
import { withStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import moment from 'moment';
import Notification from '../../components/notification/Notification';
import EditarContratante from './components/editar-contratante'
import Plano from "../list-vendas/components/criar-venda/plano";
import MeioDeCobrancaBoleto from "./components/meio-de-cobranca-boleto";

const VisualizarVenda = ({ classes }) => {

  const notificationDefault = {
    variant: '',
    message: '',
    isOpen: false,
  };

  const [isOpenMeioDeCobrancaModal, setIsOpenMeioDeCobrancaModal] = useState(false);
  const [isOpenMovimentacoesFinanceirasModal, setIsOpenMovimentacoesFinanceirasModal] = useState(false);
  const [isOpenCriarProfissionalSaudeModal, setIsOpenCriarProfissionalSaudeModal] = useState(false);
  const [isOpenAlterarPlanoModal, setIsOpenAlterarPlanoModal] = useState(false);
  const [isOpenVerificarProfissionaisDeSaudeDialog, setIsOpenVerificarProfissionaisDeSaudeDialog] = useState(false);
  const [isOpenEditarDadosUnidadeModal, setIsOpenEditarDadosUnidadeModal] = useState(false);
  const [isOpenEditarDadosProfissinalSaudeModal, setIsOpenEditarDadosProfissinalSaudeModal] = useState(false);
  const [urlPagamentoVenda, setUrlPagamentoVenda] = useState('');
  const [linkPagamentoCopiado, setLinkPagamentoCopiado] = useState(false);
  const [isOpenDefinirTesteGratisVendaModal, setIsOpenDefinirTesteGratisVendaModal] = useState(false);
  const [isOpenEditarContratante, setIsOpenEditarContratante] = useState(false);
  const [notification, setNotification] = useState({
		...notificationDefault,
	});
  const [isOpenPagamentoBoletoDialog, setIsOpenPagamentoBoletoDialog] = useState(false);
  const [loadingExtenderPeriodoGratis, setLoadingExtenderPeriodoGratis] = useState(false);
  const [showButtonExtenderPeriodoGratis, setShowButtonExtenderPeriodoGratis] = useState(false);

  const params = useParams();

  const vendaQuery = useQuery(FIND_BY_ID_VENDA, {
    variables: {
      id: params.vendaId,
    }
  });

  const [finalizarVenda] = useMutation(FINALIZAR_VENDA);
  const [iniciarPeriodoGratis] = useMutation(INICIAR_PERIODO_GRATIS);
  const [extenderPeriodoGratis] = useMutation(UPDATE_EXTENDER_PERIODO_GRATIS);

  const handleClickFinalizarVenda = async() => {
    try{
      await finalizarVenda({
        variables: {
          vendaId: vendaQuery.variables?.id,
      }});
    } catch ({ graphQLErrors }) {
      if (graphQLErrors) {
        setNotification({
          variant: 'error',
          isOpen: true,
          message: graphQLErrors[0].message,
        })
      };
  }}

  const handleClickAdicionarMeioDeCobranca = () => {
    const newURL = window.location.protocol + "//" + window.location.host + "/pagamentos/" + params.vendaId
    setUrlPagamentoVenda(newURL);
    setIsOpenVerificarProfissionaisDeSaudeDialog(true);
  };

  const handleClickPagamentoCartaoDeCredito = () => {
    setIsOpenVerificarProfissionaisDeSaudeDialog(false);
    setLinkPagamentoCopiado(false);
    setIsOpenMeioDeCobrancaModal(true);
  };

  const handleClickPagamentoBoleto = () => {
    setIsOpenVerificarProfissionaisDeSaudeDialog(false);
    setIsOpenPagamentoBoletoDialog(true);
  };

  const handleClickMovimentacoes = () => {
    setIsOpenMovimentacoesFinanceirasModal(true);
  };

  const handleClickCriarProfissionalSaude = () => {
    setIsOpenCriarProfissionalSaudeModal(true);
  };

  const handleClickAlterarPlanoModal = () => {
    setIsOpenAlterarPlanoModal(true);
  };

  const handleClickCloseAlterarPlanoModal = () => {
    setIsOpenAlterarPlanoModal(false);
    vendaQuery.refetch();
  };

  const handleCloseCriarProfissionalSaudeModal = () => {
    setIsOpenCriarProfissionalSaudeModal(false);
    vendaQuery.refetch();
  };

  const handleCloseMeioDeCobrancaModal = () => {
    setIsOpenMeioDeCobrancaModal(false);
    vendaQuery.refetch();
  };

  const handleCloseVerificarProfissionaisSaude = () => {
    setLinkPagamentoCopiado(false);
    setIsOpenVerificarProfissionaisDeSaudeDialog(false);
  }

  const handleCloseMovimentacoesFinanceirasModal = () => {
    setIsOpenMovimentacoesFinanceirasModal(false);
  };

  const handleClickEditarUnidade = () => {
    setIsOpenEditarDadosUnidadeModal(true);
  };

  const handleCloseEditarUnidade = () => {
    setIsOpenEditarDadosUnidadeModal(false);
    vendaQuery.refetch();
  };

  const handleClickEditarContratante = () => {
    setIsOpenEditarContratante(true);
  };

  const handleCloseEditarContratante = () => {
    setIsOpenEditarContratante(false);
    vendaQuery.refetch();
  };

  const handleClickEditarProfissionalSaude = () => {
    setIsOpenEditarDadosProfissinalSaudeModal(true);
  };

  const handleCloseEditarProfissionalSaude = () => {
    setIsOpenEditarDadosProfissinalSaudeModal(false);
    vendaQuery.refetch();
  };

  const handleClosePagamentoBoleto = () => {
    setIsOpenPagamentoBoletoDialog(false);
    vendaQuery.refetch();
  }

  const handleClickIniciarPeriodoGratis = async () => {
    await iniciarPeriodoGratis({
      variables: {
        vendaId: params.vendaId,
      },
    }).then((response) => {
      vendaQuery.refetch();
    });
  };

  const handleClickExtenderPeriodoGratis = async () => {
    setLoadingExtenderPeriodoGratis(true);

    await extenderPeriodoGratis({
      variables: {
        vendaId: params.vendaId,
      },
    }).then((response) => {
      setShowButtonExtenderPeriodoGratis(false);
      vendaQuery.refetch();
    }).finally(() => {
      setLoadingExtenderPeriodoGratis(false);
    });
  };

  const handleClickDefinirTesteGratisVenda = () => {
    setIsOpenDefinirTesteGratisVendaModal(true);
  };

  const handleCloseDefinirTesteGratisVenda = () => {
    setIsOpenDefinirTesteGratisVendaModal(false);
    vendaQuery.refetch();
  };

  const onCopyLinkPagamento = () => {
    setLinkPagamentoCopiado(true);
  };

  const dadosDaVenda = vendaQuery.data?.findByIdVenda || {};

  const handleEditProfissionalSaude = (profissional) => {
    dadosDaVenda.profissionalSaude = profissional;
    handleClickEditarProfissionalSaude()
  };

  const verificaAtivo = "ATIVA" !== dadosDaVenda.status;

  const showButtonIniciarTesteGratis = "CADASTRO_PARCIAL" === dadosDaVenda.status;

  useEffect(() => {
    let dataVencimentoUnidade = moment(dadosDaVenda.unidade?.dataVencimento).subtract(3, "days");

    setShowButtonExtenderPeriodoGratis(moment() > dataVencimentoUnidade);
  }, [dadosDaVenda]);

  const editActionButton = (action) => (
    <Fab
      className={classes.editIcon}
      onClick={action}>
      <EditIcon fontSize='small' />
    </Fab>
  );

  if (vendaQuery.loading) {
    return (
      <Container>
        <Header>
          <HeaderTitle>Carregando...</HeaderTitle>
        </Header>
      </Container>
    );
  }

  const error = vendaQuery.error;

  if (error) {
    return (
      <Container>
        <Header>
          <HeaderTitle>Erro: {error.message}</HeaderTitle>
        </Header>
      </Container>
    );
  }

  const showButtonAdicionarProfissionalSaude = !!dadosDaVenda.unidade;

  return (
    <Container>
      <Grid container>
        <ButtonsContainer>
          <Button onClick={handleClickMovimentacoes}>
            Movimentações financeiras
          </Button>
          {showButtonAdicionarProfissionalSaude && (
            <Button onClick={handleClickCriarProfissionalSaude}>
              Adicionar profissional de saúde
            </Button>
          )}
          {verificaAtivo && (<Button onClick={handleClickDefinirTesteGratisVenda}>
            Mudar período gratís
          </Button>)
          }
          <Button onClick={handleClickAdicionarMeioDeCobranca}>
            Adicionar meio de cobrança
          </Button>
          {showButtonAdicionarProfissionalSaude && (
            <Button onClick={handleClickEditarUnidade}>
              Alterar dados da unidade
            </Button>
          )}
          <Button onClick={handleClickEditarContratante}>
            Alterar contratante
          </Button>
          <Button onClick={handleClickFinalizarVenda}>
            Finalizar Venda
          </Button>
          {verificaAtivo && (<Button onClick={handleClickAlterarPlanoModal}>
            Mudar plano
          </Button>)}
          {showButtonIniciarTesteGratis && (<Button onClick={handleClickIniciarPeriodoGratis}>
            Iniciar teste grátis
          </Button>)}
          {showButtonExtenderPeriodoGratis &&
          ("ATIVA" === dadosDaVenda.status || "PERIODO_GRATIS" === dadosDaVenda.status) &&
          (<Button onClick={handleClickExtenderPeriodoGratis} disabled={loadingExtenderPeriodoGratis}>
            {loadingExtenderPeriodoGratis ? 'Carregando...' : 'Extender data de vencimento'}
          </Button>)}
        </ButtonsContainer>
      </Grid>
      <div>

        <Detalhes>
          <DadosVenda>
            <TypographyTituloDados color="primary" component="label" variant="h6">
              {"Dados da venda"}
            </TypographyTituloDados>
            <Box sm={true}>
              <DetalhesDaVenda dados={dadosDaVenda} />
            </Box>
          </DadosVenda>
          <DadosVenda>
            <TypographyTituloDados color="primary" component="label" variant="h6">
              {"Dados do vendedor"}
            </TypographyTituloDados>
            <Box sm={true}>
              <DetalhesDoVendedor dados={dadosDaVenda} />
            </Box>
          </DadosVenda>
          <DadosVenda>
            <TypographyTituloDados color="primary" component="label" variant="h6">
              {"Beneficiarios"}
            </TypographyTituloDados>
            <Box sm={true}>
              <DetalhesDosBeneficiarios dados={dadosDaVenda} />
            </Box>
          </DadosVenda>
        </Detalhes>


        <DadosVenda>
          <TypographyTituloDados color="primary" component="label" variant="h6">
            {"Dados do contratante"}
          </TypographyTituloDados>
          <CardGroup>
            <Card sm={true}>
              <DadosResponsavelContratante dados={dadosDaVenda.contratante} handleAtualizarDados={() => { }} handleDadosInvalidos={() => {
              }} disabled={true} />
            </Card>
            <Card sm={true}>
              <DadosBasicosContratante dados={dadosDaVenda.contratante} handleAtualizarDados={() => { }} handleDadosInvalidos={() => {
              }} disabled={true} />
            </Card>
            <Card sm={true}>
              <DadosEnderecoContratante dados={dadosDaVenda.contratante} handleAtualizarDados={() => { }} handleDadosInvalidos={() => {
              }} disabled={true} />
            </Card>
            <Card sm={true}>
              <DadosEspecialidadesContratante dados={dadosDaVenda.contratante} handleAtualizarDados={() => { }} handleDadosInvalidos={() => {
              }} disabled={true} />
            </Card>
            <Card sm={true}>
              <DadosImportacaoContratante dados={dadosDaVenda.contratante} handleAtualizarDados={() => { }} handleDadosInvalidos={() => {
              }} disabled={true} />
            </Card>
          </CardGroup>
        </DadosVenda>

        <DadosVenda>
        <TypographyTituloDados color="primary" component="label" variant="h6">
          {"Profissionais de saúde"}
        </TypographyTituloDados>

        <ListProfissionais
          columns={[
            {
              title: '',
              value: (item) => editActionButton(() => handleEditProfissionalSaude(item)),
            },
            {
              title: 'Status',
              value: (item) => item.ativo ? 'Ativo' : 'Inativo',
            },
            {
              title: 'Nome',
              value: (item) => item.nome,
            },
            {
              title: 'CPF',
              value: (item) => cpfCnpjFormatted(item.cpf),
            },
            {
              title: 'É médico',
              value: (item) => <Checkbox color="primary" checked={item.possuiAgenda} />,
            },
            {
              title: 'Data de cadastro',
              value: (item) => item.dataCadastro ? moment(item.dataCadastro).format('DD/MM/YYYY') : '-',
            },
            {
              title: 'Data de vencimento',
              value: (item) => item.dataVencimento ? moment(item.dataVencimento).format('DD/MM/YYYY') : '-',
            },
          ]}
          items={dadosDaVenda?.unidade?.profissionaisSaude}
          loading={false}
          last={true}
        />

        </DadosVenda>
      </div>

      <ModalSlider
        isOpen={isOpenMovimentacoesFinanceirasModal}
        shouldCloseOnOverlayClick={handleCloseMovimentacoesFinanceirasModal}
        width="30%"
      >
        {isOpenMovimentacoesFinanceirasModal && (
          <MovimentacoesFinanceiras
            onClose={handleCloseMovimentacoesFinanceirasModal}
            dadosDaVenda={dadosDaVenda}
          />
        )}
      </ModalSlider>

      <ModalSlider
        isOpen={isOpenEditarDadosUnidadeModal}
        shouldCloseOnOverlayClick={handleCloseEditarUnidade}
        width="30%"
      >
        {isOpenEditarDadosUnidadeModal && (
          <EditarUnidade
            onClose={handleCloseEditarUnidade}
            venda={dadosDaVenda}
          />
        )}
      </ModalSlider>

      <ModalSlider
        isOpen={isOpenEditarContratante}
        shouldCloseOnOverlayClick={handleCloseEditarContratante}
        width="30%"
      >
        {isOpenEditarContratante && (
          <EditarContratante
            onClose={handleCloseEditarContratante}
            venda={dadosDaVenda}
          />
        )}
      </ModalSlider>

      <ModalSlider
        isOpen={isOpenEditarDadosProfissinalSaudeModal}
        shouldCloseOnOverlayClick={handleCloseEditarProfissionalSaude}
        width="30%"
      >
        {isOpenEditarDadosProfissinalSaudeModal && (
          <EditarProfissionalSaude
            onClose={handleCloseEditarProfissionalSaude}
            dados={dadosDaVenda.profissionalSaude}
            unidade={dadosDaVenda.unidade}
          />
        )}
      </ModalSlider>

      <ModalSlider
        isOpen={isOpenCriarProfissionalSaudeModal}
        shouldCloseOnOverlayClick={handleCloseCriarProfissionalSaudeModal}
        width="30%"
      >
        {isOpenCriarProfissionalSaudeModal && (
          <StepperCriarProfissionalSaude
            onClose={handleCloseCriarProfissionalSaudeModal}
            dados={dadosDaVenda}
            criandoProfissional={isOpenCriarProfissionalSaudeModal}
          />
        )}
      </ModalSlider>

      <ModalSlider
        isOpen={isOpenDefinirTesteGratisVendaModal}
        shouldCloseOnOverlayClick={handleCloseDefinirTesteGratisVenda}
        width="30%"
      >
        {isOpenDefinirTesteGratisVendaModal && (
          <DefinirPeriodoDiasGratis
            onClose={handleCloseDefinirTesteGratisVenda}
            dados={dadosDaVenda}
          />
        )}
      </ModalSlider>

      <ModalSlider
        isOpen={isOpenAlterarPlanoModal}
        shouldCloseOnOverlayClick={handleClickCloseAlterarPlanoModal}
        width="30%"
      >
        {isOpenAlterarPlanoModal && (
          <Plano
            onClose={handleClickCloseAlterarPlanoModal}
            dados={dadosDaVenda.plano}
            vendaId={dadosDaVenda.id}
          />
        )}
      </ModalSlider>

      <Dialog
        open={isOpenVerificarProfissionaisDeSaudeDialog}
        shouldCloseOnOverlayClick={handleCloseVerificarProfissionaisSaude}
        maxWidth={"md"}
      >
        <Grid container direction={"column"} wrap={"wrap"}>
          <DialogHeader
            title=""
            closeButton={true}
            actionClose={handleCloseVerificarProfissionaisSaude}
          />

          <GridVerificarProfissionalSaude>
            <Mensagem>Certifique-se de adicionar todos profissionais de saúde antes de adicionar o meio de pagamento.</Mensagem>
            <ButtonContainerVerificarProfissionalSaude>
              <ButtonVerificarProfissionalSaude onClick={handleClickPagamentoCartaoDeCredito}> Cartão de crédito</ButtonVerificarProfissionalSaude>
              <CopyToClipboard
                onCopy={onCopyLinkPagamento}
                text={urlPagamentoVenda}>
                  <ButtonVerificarProfissionalSaude>
                      Link de pagamento
                  </ButtonVerificarProfissionalSaude>
              </CopyToClipboard>

              <ButtonVerificarProfissionalSaude onClick={handleClickPagamentoBoleto}>Boleto</ButtonVerificarProfissionalSaude>

              {linkPagamentoCopiado && (
                <div>
                  <MensagemLinkPagamento>
                    Link copiado para a área de transferência.
                  </MensagemLinkPagamento>
                </div>
              )}

            </ButtonContainerVerificarProfissionalSaude>
          </GridVerificarProfissionalSaude>
        </Grid>

      </Dialog>

      <ModalSlider
        isOpen={isOpenPagamentoBoletoDialog}
        shouldCloseOnOverlayClick={handleClosePagamentoBoleto}
        width="30%"
      >
        {isOpenPagamentoBoletoDialog && (
          <MeioDeCobrancaBoleto
            onClose={handleClosePagamentoBoleto}
            venda={dadosDaVenda}
          />
        )}
      </ModalSlider>

      <ModalSlider
        isOpen={isOpenMeioDeCobrancaModal}
        shouldCloseOnOverlayClick={handleCloseMeioDeCobrancaModal}
        width="30%"
      >
        {isOpenMeioDeCobrancaModal && (
          <MeioDeCobranca
            onClose={handleCloseMeioDeCobrancaModal}
            venda={dadosDaVenda}
          />
        )}
      </ModalSlider>

      <Notification
        close={() => {
          setNotification({
            ...notificationDefault,
          });
        }}
        reset={() => {
          setNotification({
            ...notificationDefault,
          });
        }}
        isOpen={notification.isOpen}
        variant={notification.variant}
        message={notification.message}
      />

    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  margin-bottom: 40px;
`;

const Header = styled.div`
  height: 45px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const HeaderTitle = styled.div`
  font-size: 18px;
  text-align: left;
  color: ${Colors.primary.main};
  margin-top: 4px;
`;

const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  overflow: auto;
  position: relative;
  background-color: #fff;
  padding: 15px 0;
  box-shadow: 0 2px 4px -2px rgba(30, 60, 90, 0.1);
  z-index: 100;
`;

const Button = styled.button`
  border: 0;
  background-color: ${Colors.primary.main};
  color: #fff;
  padding: 10px 15px;
  box-sizing: border-box;
  min-height: 34px;
  min-width: 150px;
  border-radius: 30px;
  margin: 10px;
  cursor: pointer;
  font-weight: bold;
  flex-shrink: 0;
`;

const Detalhes = styled.div`
  display: flex;
  flex-direction: row;
  overflow: auto;
  margin-top: 12px;
`;

const DadosVenda = styled.div`
  flex-direction: column;
  margin-top: 35px;
  overflow-y: auto;
`;

const Box = styled.div`
  background-color:#fff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  padding: 10px;
  margin: 5px 10px 10px 10px;
`;

const TypographyTituloDados = styled(Typography)`
  padding-left: 20px;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  background-color:#fff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  margin-left: 30px;
  min-width: 250px;
  max-width: 250px;
  margin: 5px 10px 10px 10px;
  padding: 0 20px;
`;

const CardGroup = styled.div`
  display: flex;
  flex-direction: row;
  overflow: auto;
  margin-bottom: 10px;
`;

const MensagemLinkPagamento = styled.div`
    color: ${Colors.primary.main};
    justify-content: center;
    font-size: 1 rem;
`;

const Mensagem = styled.div`
    color: black;
    margin: 20px;
    justify-content: center;
    font-size: 1.2 rem;
    font-weight: bold
`;

const ButtonContainerVerificarProfissionalSaude = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 10px;

`;

const ButtonVerificarProfissionalSaude = styled(Button)`
  min-height: 30px;
  min-width: 100px;
`;

const GridVerificarProfissionalSaude = styled.div`
  padding: 10px;
  min-height: 150px;
  max-width: 375px;
  text-align: center;
`;

const ListProfissionais = styled(List)`
  overflow: auto;
  max-height: 800px;
`;

export default withStyles(() => ({
  editIcon: {
    color: 'white',
    backgroundColor: Colors.primary.main,
    fontSize: '12px',
    minWidth: 25,
    minHeight: 25,
    width: 25,
    height: 25,
  }
}))(VisualizarVenda);
