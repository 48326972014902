import styled from "@emotion/styled";
import Grid from "@material-ui/core/Grid";
import Colors from "../../../theme/Colors";
import {TextField} from "../../../components/TextField";
import {ToggleButton} from "@material-ui/lab";
import withStyles from "@material-ui/core/styles/withStyles";
import React from "react";

export const FormGroup = styled(Grid)`
	margin-bottom: 15px !important;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const Button = styled.button`
  border: 0;
  background-color: ${Colors.primary.main};
  color: #fff;
  padding: 5px 10px;
  box-sizing: border-box;
  height: 34px;
  min-width: 150px;
  border-radius: 6px;
  margin: 8px 0;
  cursor: pointer;
`;

export const FormHeading = styled.h3`
	color: ${Colors.commons.gray5};
	margin: 15px 0 0;
`;

export const TextInputBloqueado = styled(TextField)`
  background-color: ${Colors.commons.gray}
`;

export const styles = {
  root: {

  },
  direita: {
    border: 0,
    backgroundColor: Colors.commons.grayLight,
    color: Colors.primary.main,
    padding: '5px 10px',
    boxSizing: 'border-box',
    height: '34px',
    minWidth: '150px',
    borderRadius: '6px',
    margin: '8px 0',
    cursor: 'pointer',
    borderBottomLeftRadius: '0px',
    borderTopLeftRadius: '0px',
    borderBottomRightRadius: '15px',
    borderTopRightRadius: '15px'
  },
  esquerda: {
    border: 0,
    backgroundColor: Colors.commons.grayLight,
    color: Colors.primary.main,
    padding: '5px 10px',
    boxSizing: 'border-box',
    height: '34px',
    minWidth: '150px',
    borderRadius: '6px',
    margin: '8px 0',
    cursor: 'pointer',
    borderBottomLeftRadius: '15px',
    borderTopLeftRadius: '15px',
    borderBottomRightRadius: '0px',
    borderTopRightRadius: '0px'
  },
  selected: {
    color: 'white !important',
    backgroundColor: 'lightseagreen !important',
    fontWeight: 'bold'
  },
  link: {
    color: Colors.primary.main,
    cursor: 'pointer'
  }
};

export const ToggleButtonEsquerdo = withStyles(styles)(({classes, key, value, label, selected, handleClick, disabled}) => (

    <ToggleButton classes={{root: classes.esquerda, selected: classes.selected}} key={key} value={value} selected={selected} onClick={handleClick}
                  disabled={disabled}>
      {label}
    </ToggleButton>
));

export const ToggleButtonDireito = withStyles(styles)(({classes, key, value, label, selected, handleClick, disabled}) => (
    <ToggleButton classes={{root: classes.direita, selected: classes.selected}} key={key} value={value} selected={selected} onClick={handleClick}
                  disabled={disabled}>
      {label}
    </ToggleButton>
));

export const LinkAppHealth = withStyles(styles)(({classes, label, handleClick}) => (
  <text className={classes.link} onClick={handleClick}>{label}</text>
));

