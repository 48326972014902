import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	pageTitle: '',
};

const updatePageTitleReducer = (state, { payload }) => {
	state.pageTitle = payload;
};

const pageSlice = createSlice({
	name: 'page',
	initialState,
	reducers: {
		updatePageTitle: updatePageTitleReducer,
	}
});

export const { updatePageTitle } = pageSlice.actions;

export const reducer = pageSlice.reducer;