import React, { useState } from 'react';
import { useMutation } from 'react-apollo';

import { DEFINIR_TESTE_GRATIS_VENDA } from '../../../graphql/mutations';

import Colors from '../../../theme/Colors';
import { Slider, Typography, Grid } from '@material-ui/core';
import styled from '@emotion/styled';
import ArrowBack from '@material-ui/icons/ArrowBack';
import Notification from '../../../components/notification/Notification';

const DefinirPeriodoDiasGratis = ({ dados, disabled, onClose }) => {
  const notificationDefault = {
    variant: '',
    message: '',
    isOpen: false,
  };

  const [notification, setNotification] = useState({
    ...notificationDefault,
  });

  const [dadosTemp, setDadosTemp] = useState({
    vendaId: dados.id,
    dias: dados?.diasPeriodoGratis || 0,
  });

  const [definirDiasPeriodoGratis] = useMutation(DEFINIR_TESTE_GRATIS_VENDA);

  const submitDiasPeriodoGratis = async () => {
    try {
      await definirDiasPeriodoGratis({
        variables: dadosTemp,
      });
      handleClose();
    } catch ({ graphQLErrors }) {
      if (graphQLErrors) {
        setNotification({
          variant: 'error',
          isOpen: true,
          message: graphQLErrors[0].message,
        });
      }
    }
  };

  const handleChangeField = (campo, value) => {
    setDadosTemp({
      ...dadosTemp,
      [campo]: value,
    });
  };

  const handleDiasPeriodoGratis = (event, value) => {
    handleChangeField('dias', value);
  };

  const handleClose = () => {
    onClose && onClose();
  };

  return (
    <Container>
      <Header>
        <ArrowBackStyled onClick={handleClose} />
        <HeaderTitle>Mudar período grátis</HeaderTitle>
      </Header>
      <Grid item xs={11} sm={11} md={11} ls={11}>
        <Typography color="primary" component="label">
          {'Periodo grátis'}
        </Typography>
        <Slider
          defaultValue={dadosTemp.dias}
          aria-labelledby="discrete-slider"
          valueLabelDisplay="auto"
          step={3}
          marks={marksDiasGratis}
          min={0}
          max={30}
          value={dadosTemp.dias}
          onChange={handleDiasPeriodoGratis}
          disabled={disabled}
        />
      </Grid>
      <ButtonsContainer>
        <Button onClick={submitDiasPeriodoGratis}>Salvar</Button>
      </ButtonsContainer>
      <Notification
        close={() => {
          setNotification({
            ...notificationDefault,
          });
        }}
        reset={() => {
          setNotification({
            ...notificationDefault,
          });
        }}
        isOpen={notification.isOpen}
        variant={notification.variant}
        message={notification.message}
      />
    </Container>
  );
};

const marksDiasGratis = [
  {
    value: 0,
    label: '0',
  },
  {
    value: 3,
    label: '3',
  },
  {
    value: 6,
    label: '6',
  },
  {
    value: 9,
    label: '9',
  },
  {
    value: 15,
    label: '15',
  },
  {
    value: 21,
    label: '21',
  },
  {
    value: 30,
    label: '30',
  },
];

const Container = styled.div`
  width: 100%;
  padding: 10px 20px;
`;

const Header = styled.div`
  height: 45px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;
`;

const HeaderTitle = styled.div`
  font-size: 18px;
  text-align: left;
  color: ${Colors.primary.main};
  margin-top: 4px;
  margin-left: 10px;
`;

const ArrowBackStyled = styled(ArrowBack)`
  color: ${Colors.primary.main};
  cursor: pointer;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 20px 48px;
`;

const Button = styled.button`
  border: 0;
  background-color: ${Colors.primary.main};
  color: #fff;
  padding: 5px 10px;
  box-sizing: border-box;
  height: 34px;
  min-width: 150px;
  border-radius: 6px;
  margin: 8px 0;
  cursor: pointer;
`;

export default DefinirPeriodoDiasGratis;
