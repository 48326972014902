import Typography from "@material-ui/core/Typography";
import {TextField} from "../../../components/TextField";
import React from "react";

const TextFieldEntidade = ({label, field, value, mask, fieldChange, formEditavel}) => {

  return (
    <>
      <Typography color="primary" component="label">
        {label}
      </Typography>
      <TextField
        value={value || undefined}
        mask={mask}
        onChange={event => fieldChange(field, event)}
        disabled={formEditavel}
      />
    </>
  )

};

export default TextFieldEntidade;
