import React, { useEffect, useState } from "react";
import { useApolloClient, useQuery } from "react-apollo";
import { withStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid"
import Typography from '@material-ui/core/Typography';
import string from "../../../../utils/string";
import { LIST_BANK_BUSCA } from "../../../../graphql/queryes";
import RenderTextField from "../../../../components/TextField/RenderTextField";
import { SelectSearch, SelectSearchAsync } from "../../../../components/select/SelectSearch";

const DadosBancarios = ({ dados, classes, handleAtualizarDados, handleAtualizarDadosErrors, dadosErrors, handleDadosInvalidos }) => {
  const tipoContaBancaria = [
    { value: 'CONTA_CORRENTE', name: 'Conta Corrente' },
    { value: 'POUPANCA', name: 'Conta Poupança' }
  ]

  const client = useApolloClient();

  const [dadosTemp, setDadosTemp] = useState({
    banco: dados?.dadoBancario?.banco || null,
    tipoConta: dados?.dadoBancario?.tipoConta || null,
    conta: dados?.dadoBancario?.conta || '',
    operacao: 0,
    digitoVerificador: dados?.dadoBancario?.digitoVerificador || '',
    agencia: dados?.dadoBancario?.agencia || '',
    digitoAgencia: dados?.dadoBancario?.digitoAgencia || '',
    documento: dados?.dadoBancario?.documento || ''
  });
  const [bancosSearch, setBancosSearch] = useState({
    search: '',
    typing: false,
    typingTimeout: 0
  });

  const { data: bancos } = useQuery(LIST_BANK_BUSCA, {
    variables: {
      pageableDTO: {
        pageNumber: 0,
        pageSize: 30,
        sortField: 'codigo'
      }
    }
  });

  const initialBancos = bancos?.bancos?.content?.map(b => ({ ...b, label: `${('00'.concat(b.codigo)).slice(-3)} - ${b.nome}` }))

  const handleChangeField = (campo, value) => {
    setDadosTemp({
      ...dadosTemp,
      [campo]: value
    });

    handleAtualizarDadosErrors({
      ...dadosErrors,
      [campo]: !value
    });

    handleAtualizarDados({ dadoBancario: dadosTemp });
  };

  const handleTipoContaChange = (campo, tipo) => {
    setDadosTemp({
      ...dadosTemp,
      tipoConta: {
        value: tipo.value,
        label: tipo.label
      }
    });

    handleAtualizarDadosErrors({
      ...dadosErrors,
      tipoConta: !tipo
    });

    handleAtualizarDados({ dadoBancario: {...dadosTemp, tipoConta: {value: tipo.value, label: tipo.label}}});
  };

  const handleDocumentoField = (value) => {
    setDadosTemp({
      ...dadosTemp,
      documento: value
    });

    handleAtualizarDadosErrors({
      documento: !string.validaCPF(value)
    });

    handleAtualizarDados({ dadoBancario: { ...dadosTemp, documento: value } });
  };

  const isCamposPreenchidos = () => {
    return dadosTemp.banco === null ||
      dadosTemp.tipoConta === null ||
      dadosTemp.conta === '' ||
      dadosTemp.digitoVerificador === '' ||
      dadosTemp.agencia === '' ||
      dadosTemp.digitoAgencia === '' ||
      !string.validaCPF(dadosTemp.documento)
  };

  const loadBancosOptions = async (query, callback) => {
    const response = await client.query({
      query: LIST_BANK_BUSCA,
      variables: {
        searchDTO: {
          search: query
        },
        pageableDTO: {
          pageNumber: 0,
          pageSize: 30
        }
      }
    });

    callback(response?.data?.bancos?.content?.map(b => ({ ...b, label: `${('00'.concat(b.codigo)).slice(-3)} - ${b.nome}` })));
  };

  const loadBancos = (query, callback) => {
    if (bancosSearch.typingTimeout) {
      clearTimeout(bancosSearch.typingTimeout);
    }

    setBancosSearch({
      search: query,
      typing: false,
      typingTimeout: setTimeout(function () {
        loadBancosOptions(query, callback);
      }, 1000)
    });
  };

  useEffect(() => {
    handleDadosInvalidos(isCamposPreenchidos());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleAtualizarDados]);

  return (
    <div className={classes.container}>
      <Grid container direction="row" spacing={2}>
        <Grid className={classes.gridItem} item xs={12} sm={12} md={12} ls={12}>
          <Typography color="primary" component="label">
            Dados Bancários para Recebimento
          </Typography>
        </Grid>
        <Grid className={classes.gridItem} item xs={12} sm={12} md={12} ls={12}>
          <Typography color="primary" component="label">
            Banco *
          </Typography>
          <SelectSearchAsync
            className={classes.inputSearch}
            name="banco"
            placeholder=""
            value={dadosTemp.banco}
            loadOptions={(query, callback) => loadBancos(query, callback)}
            onChange={(e) => handleChangeField('banco', e)}
            defaultOptions={initialBancos || []} />
        </Grid>
        <Grid className={classes.gridItem} item xs={12} sm={12} md={12} ls={12}>
          <Typography color="primary" component="label">
            Tipo da Conta *
          </Typography>
          <SelectSearch
            className={classes.inputSearch}
            name="tipoConta"
            placeholder=""
            value={dadosTemp.tipoConta}
            elements={tipoContaBancaria}
            onChange={(e) => handleTipoContaChange('tipoConta', e)}
          />
        </Grid>
        <Grid className={classes.gridItem} item xs={8} sm={8} md={8} ls={8}>
          <RenderTextField label={"Número da Conta *"}
            field={dadosTemp.conta}
            error={dadosErrors.conta}
            maxLength={50}
            errorText={"Campo obrigatório."}
            handleChange={(e) => handleChangeField('conta', e.target.value)} />
        </Grid>
        <Grid className={classes.gridItem} item xs={4} sm={4} md={4} ls={4}>
          <RenderTextField
            label={"Digito *"}
            maxLength={1}
            field={dadosTemp.digitoVerificador}
            error={dadosErrors.digitoVerificador}
            errorText={"Campo obrigatório."}
            handleChange={(e) => handleChangeField('digitoVerificador', e.target.value)} />
        </Grid>
        <Grid className={classes.gridItem} item xs={8} sm={8} md={8} ls={8}>
          <RenderTextField label={"Agência *"}
            field={dadosTemp.agencia}
            error={dadosErrors.agencia}
            maxLength={50}
            errorText={"Campo obrigatório."}
            handleChange={(e) => handleChangeField('agencia', e.target.value)} />
        </Grid>
        <Grid className={classes.gridItem} item xs={4} sm={4} md={4} ls={4}>
          <RenderTextField
            label={"Digito *"}
            maxLength={1}
            field={dadosTemp.digitoAgencia}
            error={dadosErrors.digitoAgencia}
            errorText={"Campo obrigatório."}
            handleChange={(e) => handleChangeField('digitoAgencia', e.target.value)} />
        </Grid>
        <Grid className={classes.gridItem} item xs={12} sm={12} md={12} ls={12}>
          <RenderTextField label={"CPF *"}
            field={dadosTemp.documento}
            error={dadosErrors.documento}
            mask={'cpf'}
            errorText={"CPF inválido."}
            handleChange={(e) => handleDocumentoField(e.target.value)} />
        </Grid>
      </Grid>
    </div>
  );
};

const style = () => ({
  container: {
    width: '100%',
    padding: '10px 0'
  },
  inputSearch: {
    width: '100%'
  },
  gridItem: {
    marginBottom: '10px'
  }
});

export default withStyles(style)(DadosBancarios);
