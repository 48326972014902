import Axios from "axios";
import axiosAuth from "../services/axios";
import {alteraContextoAtual, obterEntidadeAtualLocal, obterUsuarioLogado} from "../services/usuario-service";

const timeOut = () => new Promise((resolve) => {
  setTimeout(() => resolve(), 1000)
});

const customFetch = (token, refresh_token, saveNewToken = () => {
}, onLogout = () => {
}) => async (uri, options) => {

  var customHeaders = {
    "accept": "*/*",
    "content-type": "application/json",
    authorization: `Bearer ${token}`
  };

  const finalResult = (data) => {
    var result = {};
    result.ok = true;
    result.json = () => new Promise(function (resolve) {
      resolve(data);
    });
    result.text = () => new Promise(function (resolve) {
      resolve(JSON.stringify(data));
    });
    return result
  };

  window.numberRefresh = 0;

  const refreshToken = async () => {
    await timeOut();
    return axiosAuth.post('/oauth/token', undefined, {
      params: {
        refresh_token,
        grant_type: "refresh_token",
        client_id: process.env.REACT_APP_CLIENT_ID,
      }
    })
      .then(async ({data}) => {
        const newCredential = data;
        const entidadeAtual = obterEntidadeAtualLocal();
        let usuarioLogado = await obterUsuarioLogado(newCredential.access_token);

        if (!usuarioLogado.entidadeAtual && entidadeAtual && entidadeAtual.id) {
          usuarioLogado = await alteraContextoAtual(entidadeAtual.id, newCredential.access_token);
        }

        saveNewToken({...newCredential, usuarioLogado});

        return fetchAxios({
          ...customHeaders,
          authorization: `Bearer ${newCredential.access_token}`
        }, true)
      }).catch(err => {
        if (err?.response?.data?.error === 'invalid_grant') {
          onLogout();
          return;
        }

        if (err.response && err.response.state === 401)
          window.localStorage.clear();
        else return refreshToken()
      })
  };

  const fetchAxios = (headers, refetch) => {
    return Axios.post(uri, options.body, {headers})
      .then(({data}) => {
        if (refetch && window.numberRefresh === 0) {
          return fetchAxios(headers)
        }
        setTimeout(() => {
          window.numberRefresh = 0;
        }, 2000);

        return finalResult(data)
      }).catch(err => {
        if (err.response && err.response.status === 401) {
          window.numberRefresh += 1;
          return refreshToken();
        }
      })

  };

  return fetchAxios(customHeaders);
};

export default customFetch;
