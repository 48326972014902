import React, { useState } from 'react';
import styled from '@emotion/styled';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import Colors from '../../../theme/Colors';
import { useMutation } from 'react-apollo';
import { UPDATE_PROFISSIONAL_SAUDE } from '../../../graphql/mutations';
import string from '../../../utils/string';
import DadosEspecialidade from './criar-profissional-saude/dadosEspecialidade';
import MobileStepper from "@material-ui/core/MobileStepper";
import DadosBase from './criar-profissional-saude/dadosBase';
import DadosConselho from './criar-profissional-saude/dadosConselho';
import Notification from '../../../components/notification/Notification';
import Close from '@material-ui/icons/Close';
import { LIST_ESPECIALIDADES_MEDICAS } from '../../../graphql/queryes';
import { useQuery } from "@apollo/react-hooks";
import DadosEndereco from './criar-profissional-saude/dadosEndereco';
import DadosBancarios from './criar-profissional-saude/dadosBancarios';

const notificationDefault = {
  variant: '',
  message: '',
  isOpen: false,
};

const EditarProfissionalSaude = ({ dados, unidade, onClose, onCreate, }) => {
  const [notification, setNotification] = useState({ ...notificationDefault, });
  const [passoAtual, setPassoAtual] = useState(0);
  const [criando, setCriando] = useState(false);
  const [formData, setFormData] = useState({
    id: dados.id,
    nome: dados.nome,
    cpf: dados.cpf,
    cns: dados.cns,
    dataNascimento: dados.dataNascimento,
    email: dados.email,
    numeroConselho: dados.numeroConselho,
    ufConselho: dados.ufConselho ? {value: dados.ufConselho, label: dados.ufConselho } : null,
    grupoPermissoes: dados.grupoPermissoes,
    especialidades: dados.especialidades.map((e) => ({ value: e.id, label: e.especialidade })),
    ativo: dados.ativo,
    utilizaAssinaturaDigital: dados.utilizaAssinaturaDigital,
    possuiAgenda: dados.possuiAgenda,
    utilizaTelemedicina: dados.utilizaTelemedicina,
    utilizaSecretariaVirtual: dados.utilizaSecretariaVirtual,
    endereco: {
      nomeLogradouro: dados.endereco?.nomeLogradouro,
      numero: dados.endereco?.numero,
      bairro: dados.endereco?.bairro,
      cep: dados.endereco?.cep,
      complemento: dados.endereco?.complemento,
      municipio: dados.endereco?.municipio,
      estado: dados.endereco?.estado
    },
    dadoBancario: {
      banco: dados.dadoBancario?.banco,
      tipoConta: {
        value: dados.dadoBancario?.tipoConta || 'POUPANCA',
        label: dados.dadoBancario?.tipoConta === 'POUPANCA' ? 'Conta Poupança' : 'Conta Corrente'
      },
      conta: dados.dadoBancario?.conta,
      operacao: dados.dadoBancario?.operacao,
      digitoVerificador: dados.dadoBancario?.digitoVerificador,
      agencia: dados.dadoBancario?.agencia,
      digitoAgencia: dados.dadoBancario?.digitoAgencia,
      documento: dados.dadoBancario?.documento
    }
  });
  const [editarProfissionalSaude] = useMutation(UPDATE_PROFISSIONAL_SAUDE);
  const [search, setSearch] = useState('');
  const responseEspecialidades = useQuery(
    LIST_ESPECIALIDADES_MEDICAS,
    {
      variables: {
        search: {
          search: search
        },
        pageable: {
          pageSize: 53,
          pageNumber: 0
        }
      }
    }
  );

  const [formDataErrors, setFormDataErrors] = useState({
    cardNumber: false,
    holder: false,
    expirationDate: false,
    securityCode: false,
    nome: false,
    cpf: false,
    cns: false,
    dataNascimento: false,
    email: false,
    especialidades: false,
    banco: false,
    tipoConta: false,
    conta: false,
    operacao: false,
    digitoVerificador: false,
    agencia: false,
    digitoAgencia: false,
    documento: false
  });
  const [dadosInvalidos, setDadosInvalidos] = useState(true);

  const atualizarDadosErrors = (dadosAtualizadosErrors) => {
    setFormDataErrors({
      ...formDataErrors,
      ...dadosAtualizadosErrors
    });
  };

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      setCriando(true);

      const dadosBase = {
        id: dados.id,
        nome: formData.nome,
        cpf: string.removeSpecialChars(formData.cpf),
        dataNascimento: moment(formData.dataNascimento).format(
          'YYYY-MM-DD'
        ),
        cns: formData.cns,
        email: formData.email,
        possuiAgenda: dados.possuiAgenda,
        ativo: formData.ativo,
        utilizaAssinaturaDigital: formData.utilizaAssinaturaDigital,
        utilizaTelemedicina: formData.utilizaTelemedicina,
        utilizaSecretariaVirtual: formData.utilizaSecretariaVirtual,
        usuario: {
          id: dados.usuario?.id,
          email: formData.email
        }
      }
      if (!dados.possuiAgenda) {
        await editarProfissionalSaude({
          variables: {
            profissionalSaude: dadosBase
          }
        });
      } else {
        await editarProfissionalSaude({
          variables: {
            profissionalSaude: {
              ...dadosBase,
              numeroConselho: formData.numeroConselho,
              ufConselho: formData.ufConselho?.value,
              especialidades: formData.especialidades?.map(e => ({ id: e.value })),
              endereco: {
                nomeLogradouro: formData.endereco?.nomeLogradouro,
                numero: formData.endereco?.numero,
                bairro: formData.endereco?.bairro,
                cep: string.removeSpecialChars(formData.endereco?.cep),
                complemento: formData.endereco?.complemento,
                municipio: formData.endereco?.municipio ? { id: formData.endereco?.municipio?.value } : null,
                estado: formData.endereco?.estado ? { id: formData.endereco?.estado?.value } : null
              },
              dadoBancario: {
                banco: formData.dadoBancario?.banco ? { id: formData.dadoBancario?.banco?.value } : null,
                tipoConta: formData.dadoBancario?.tipoConta?.value,
                conta: formData.dadoBancario?.conta,
                operacao: formData.dadoBancario?.operacao,
                digitoVerificador: formData.dadoBancario?.digitoVerificador,
                agencia: formData.dadoBancario?.agencia,
                digitoAgencia: formData.dadoBancario?.digitoAgencia,
                documento: string.removeSpecialChars(formData.dadoBancario?.documento)
              }
            },
          }
        });
      }

      onCreate && onCreate();
      handleClose();
    } catch ({ graphQLErrors }) {
      setCriando(false);
      if (graphQLErrors) {
        setNotification({
          variant: 'error',
          isOpen: true,
          message: graphQLErrors[0].message,
        });
      }
    }
  };

  const handleClose = () => {
    onClose && onClose();
  };

  const handleAtualizarDados = (dadosAtualizados) => {
    setFormData({
      ...formData,
      ...dadosAtualizados
    });
  };

  const handleLoadEspecialidades = () => {
    return responseEspecialidades?.data?.findAllEspecialidadeMedica?.content?.map(mapEspecialidadesSalvas)
  };

  const handleEspecialidadesSearch = (search) => {
    setSearch(search);
    responseEspecialidades.refetch()
  };

  const mapEspecialidadesSalvas = (especialidade) => {
    if (formData.especialidades?.some(e => e.value === especialidade.value))
      especialidade.isInvisivel = true;

    return especialidade;
  };

  const verificaDadosInvalidos = (isDadosValidos) => {
    setDadosInvalidos(isDadosValidos);
  };

  let proximoPasso = () => {
    setPassoAtual((prevPassoAtual) => {
      if (prevPassoAtual === 0 && !formData.utilizaTelemedicina)
        return 3;

      return prevPassoAtual + 1
    })
  };

  let voltar = () => {
    setPassoAtual((prevPassoAtual) => {
      if (prevPassoAtual === 3 && !formData.utilizaTelemedicina)
        return 0;

      return prevPassoAtual - 1
    })
  };

  const renderButton = (label, handleFunction, disabled) => (
    <>
      <Button type="button" disabled={disabled} onClick={handleFunction}>{label}</Button>
    </>
  );

  return (
    <FlexGrid container direction="row" spacing={2}>
      <Header>
        <CloseStyled onClick={handleClose} />
        <HeaderTitle>Editar Profissional de Saúde</HeaderTitle>
      </Header>

      <Passos item xs={11} sm={11} md={11} ls={11}>
        {passoAtual === 0 &&
          <DadosBase
            dados={formData}
            unidade={unidade}
            dadosErrors={formDataErrors}
            handleAtualizarDadosErrors={atualizarDadosErrors}
            handleAtualizarDados={handleAtualizarDados}
            handleDadosInvalidos={verificaDadosInvalidos}
            disabled={false}
          />}

        {passoAtual === 1 &&
          <DadosBancarios
            dados={formData}
            unidade={dados.unidade}
            dadosErrors={formDataErrors}
            handleAtualizarDadosErrors={atualizarDadosErrors}
            handleAtualizarDados={handleAtualizarDados}
            handleDadosInvalidos={verificaDadosInvalidos}
          />}

        {passoAtual === 2 &&
          <DadosEndereco
            dados={formData.endereco}
            campoEndereco={'endereco'}
            unidade={dados.unidade}
            dadosErrors={formDataErrors}
            handleAtualizarDadosErrors={atualizarDadosErrors}
            handleAtualizarDados={handleAtualizarDados}
            handleDadosInvalidos={verificaDadosInvalidos}
          />}

        {passoAtual === 3 &&
          <DadosConselho
            dados={formData}
            dadosErrors={formDataErrors}
            handleAtualizarDadosErrors={atualizarDadosErrors}
            handleAtualizarDados={handleAtualizarDados}
            handleDadosInvalidos={verificaDadosInvalidos}
            disabled={false}
          />}

        {passoAtual === 4 &&
          <DadosEspecialidade
            dadosEspecialidades={handleLoadEspecialidades()}
            onSearch={handleEspecialidadesSearch}
            handleAtualizarDados={handleAtualizarDados}
            handleDadosInvalidos={verificaDadosInvalidos}
            disabled={false}
          />}

      </Passos>

      <FormGroup item xs={11}>
        <ButtonsContainer>
          <Guia
            variant="progress"
            steps={dados.possuiAgenda ? 5 : 1}
            activeStep={passoAtual}
            position={'bottom'}
            backButton={
              renderButton("Voltar", voltar, ((passoAtual - 1) < 0) || !dados.possuiAgenda)
            }
            nextButton={
              passoAtual === 4 || (passoAtual === 0 && !dados.possuiAgenda) ?
                renderButton("Finalizar", handleSubmit, dadosInvalidos || criando)
                :
                renderButton("Proximo", proximoPasso, dadosInvalidos)
            }
          />
        </ButtonsContainer>
      </FormGroup>

      <Notification
        close={() => {
          setNotification({
            ...notificationDefault,
          });
        }}
        reset={() => {
          setNotification({
            ...notificationDefault,
          });
        }}
        isOpen={notification.isOpen}
        variant={notification.variant}
        message={notification.message}
      />
    </FlexGrid>
  );
};

const FlexGrid = styled(Grid)`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 30px 0;
`;

const FormGroup = styled(Grid)`
  margin: 0 auto !important;
`;

const Passos = styled(FormGroup)`
   @media(max-height: 760px) {
     max-height: 500px;
     overflow: hidden scroll;
   }
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

const Button = styled.button`
  border: 0;
  background-color: ${Colors.primary.main};
  color: #fff;
  padding: 5px 10px;
  box-sizing: border-box;
  height: 34px;
  border-radius: 6px;
  margin: 8px 0;
  cursor: pointer;

  &:disabled {
    background-color: ${Colors.commons.gray};
  }
`;

const Guia = styled(MobileStepper)`
  width: 600;
  flex-grow: 1;
  align-self: center;
  justify-content: center;
`;

const Header = styled.div`
  height: 45px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;
  padding-left: 20px;
`;

const HeaderTitle = styled.div`
  font-size: 18px;
  text-align: left;
  color: ${Colors.primary.main};
  margin-top: 4px;
  margin-left: 10px;
`;

const CloseStyled = styled(Close)`
  color: ${Colors.primary.main};
  cursor: pointer;
`;

export default EditarProfissionalSaude;
