import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import { LIST_ESPECIALIDADES_MEDICAS } from "../../../../graphql/queryes";
import Grid from "@material-ui/core/Grid";
import Chip from '@material-ui/core/Chip';
import styled from "@emotion/styled";
import InputSearch from "../../../../components/input/Input";
import SmallTable from "../../../../components/Table/SmallTable/SmallTable";
import Colors from "../../../../theme/Colors";

const DadosEspecialidadesContratante = ({ dados, handleAtualizarDados, handleDadosInvalidos, disabled }) => {

  const [especialidades, setEspecialidades] = useState(dados.especialidades || []);

  const [search, setSearch] = useState('');

  const { data } = useQuery(
    LIST_ESPECIALIDADES_MEDICAS,
    {
      variables: {
        search: {
          search: search
        },
        pageable: {
          pageSize: 53,
          pageNumber: 0
        }
      }
    }
  );

  const handleClickTableItem = (item) => {
    if (!especialidades.includes(item) && item) {
      setEspecialidades(
        [...especialidades, item]
      );

      item.isInvisivel = true;
    }
  };

  const handleDelete = (especialidade) => {
    setEspecialidades(
      especialidades.filter(item => item.value !== especialidade.value)
    )
    especialidade.isInvisivel = false;
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  useEffect(() => {
    handleAtualizarDados({
      especialidades: especialidades
    });

    handleDadosInvalidos(!(especialidades.length > 0))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [especialidades]);

  const renderEspecilidade = especialidade => (
    !especialidade.isInvisivel &&
    <SmallTable.Row key={especialidade.value} onClick={() => handleClickTableItem(especialidade)}>
      <SmallTable.Data size={9} value={especialidade.label} noBorder />
    </SmallTable.Row>
  );

  return (
    <Container>
      <Grid container direction={"row"}>
        <Header item xs={12} sm={12} md={12} ls={12}>
          Especialidade médica *
        </Header>
        <Grid item xs={12} sm={12} md={12} ls={12}>
          {!disabled && <InputSearch placeholder="Buscar por nome..." onChange={event => handleSearch(event)} value={search} />}
        </Grid>
        <Grid2 item xs={12} sm={12} md={12} ls={12}>
          {
            especialidades.map(especialidade =>
              <EspecialidadeSelecionada key={especialidade.value} label={especialidade.label} onClick={() => handleDelete(especialidade)} onDelete={() => handleDelete(especialidade)}
                color={"primary"} disabled={disabled} />
            )
          }
        </Grid2>
        {!disabled && <Grid item xs={12} sm={12} md={12} ls={12}>
          <SmallTable>
            <SmallTable.Head>
              <SmallTable.HeadData size={12} value="Especialidade" noBorder />
            </SmallTable.Head>
            <SmallTable.Body>
              {data?.findAllEspecialidadeMedica?.content?.map(renderEspecilidade)}
            </SmallTable.Body>
          </SmallTable>
        </Grid>}
      </Grid>
    </Container>
  )

};

export default DadosEspecialidadesContratante;

const Container = styled.div`
   width: 100%;
   padding: 10px 0;
   `;

const EspecialidadeSelecionada = styled(Chip)`
  margin: 1px 2px !important;
`;

const Header = styled.div`
  color: ${Colors.primary.main};
  margin-bottom: 10px;
  font-size: 16px;
`;


const Grid2 = styled(Grid)`
  margin: 10px 0 !important;

`;
