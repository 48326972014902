import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
	BrowserRouter,
	Route,
	Redirect,
} from 'react-router-dom';

import { SIGNUP, SELECIONA_ENTIDADE, PUBLIC_CREATE_ENTITY, DASHBOARD, RECUPERAR_SENHA, PAGAMENTO_VENDA } from './names';

import NotFound from '../features/notfound';
import Signup from '../features/signup';
import WrappSafety from './wrapp-safety';
import DashboardRouter from './dashboard';
import sessionVerify from '../services/session-verify';
import AwaitLogin from './await-login';
import UpdatePageTitle from './update-page-title';
import SelecionaEntidade from '../features/seleciona-entidade';
import NewAccountPublic from '../features/public/create-entity';
import RecuperarSenha from '../features/recuperar-senha';
import PagamentoVenda from '../features/pagamento-venda';

const Router = () => {
	const dispatch = useDispatch();
	useEffect(() => {
		sessionVerify(dispatch);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<BrowserRouter>
			<UpdatePageTitle />
			<Route exact path="/">
				<Redirect from="/" to={DASHBOARD} />
			</Route>
			<Route exact path={SIGNUP} >
				<AwaitLogin>
					<Signup />
				</AwaitLogin>
			</Route>
			<Route exact path={RECUPERAR_SENHA} >
				<RecuperarSenha />
			</Route>
      <Route exact path={PAGAMENTO_VENDA} >
				<PagamentoVenda />
			</Route>
			<Route exact path={PUBLIC_CREATE_ENTITY} >
				<NewAccountPublic />
			</Route>
			<Route exact path={SELECIONA_ENTIDADE}>
				<SelecionaEntidade />
			</Route>
			<WrappSafety>
				<Route path='/dashboard'>
					<DashboardRouter />
				</Route>
				<Route exact path="/notfound">
					<NotFound />
				</Route>
			</WrappSafety>
		</BrowserRouter>
	)
};

export default Router;
