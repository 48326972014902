import React, {useEffect} from 'react';
import {useParams} from 'react-router-dom';

import EditEntity from "../edit-entity";
import BaseLayout from '../../layout/base-layout';
import ApolloPublicInstance from '../../apollo/setupPublic';
import {useQuery} from 'react-apollo';
import {GET_ENTITY_BY_CODE} from '../../graphql/queryes';
import Flex from '../../components/flex';


const NewAccountPublic = () => {
  const {code} = useParams();

  useEffect(() => {

  });

  const {data, loading} = useQuery(GET_ENTITY_BY_CODE, {
    variables: {
      codigo: code
    }
  });

  return (
    <BaseLayout>
      <Flex
        bg="#00ACA9"
        color="#fff"
        p="2"
        borderRadius="6px"
        justifyContent="center"
        fontSize="4xl"
        fontWeight="bold">
        <span> Você se cadastrará em {!!loading && '...'}{data?.findEntidadeNomeByCodigo}</span>
      </Flex>
      <EditEntity codeEntity={code}/>
    </BaseLayout>
  );
};

const HocApolloInstance = (Component) => () => (
  <ApolloPublicInstance>
    <Component/>
  </ApolloPublicInstance>
);

export default HocApolloInstance(NewAccountPublic);
