import Grid from "@material-ui/core/Grid";
import React from "react";
import TextFieldEntidade from "./textFieldEntidade";
import {
  FormHeading,
  FormGroup
} from "../constraints/constantesEntidade";

const CamposEntidadeFisica = ({isCodigoPermitido, fieldChange, formEditavel, dados}) => {

  return (
    <>
      <Grid container direction="row" spacing={2}>
        <Grid item xs={12}>
          <FormHeading>Dados da Entidade</FormHeading>
        </Grid>
        {isCodigoPermitido ?
          <FormGroup item xs={12} sm={12} md={6} lg={2}>
            <TextFieldEntidade label={'Código'} field={'codigo'} value={dados?.codigo} fieldChange={fieldChange}
                               formEditavel={formEditavel}/>
          </FormGroup>
          : null
        }
        <FormGroup item xs={12} sm={12} md={6} lg={2}>
          <TextFieldEntidade label={'CPF'} field={'cpf'} value={dados?.cpf} fieldChange={fieldChange} mask={'cpf'}
                             formEditavel={formEditavel}/>
        </FormGroup>
        <FormGroup item xs={12} sm={12} md={6} lg={3}>
          <TextFieldEntidade label={'E-mail'} field={'email'} value={dados?.email} fieldChange={fieldChange}
                             formEditavel={formEditavel}/>
        </FormGroup>
        <FormGroup item xs={12} sm={12} md={6} lg={5}>
          <TextFieldEntidade label={'Nome'} field={'nome'} value={dados?.nome} fieldChange={fieldChange}
                             formEditavel={formEditavel}/>
        </FormGroup>
      </Grid>
    </>
  )

};

export default CamposEntidadeFisica;
