import React from 'react';
import Box from '../../components/box';
import Link from '../../components/link';

const NotFound = () => (
  <Box>
    404 - Página não encontrada
    <br/>
    <Link>
      Voltar
    </Link>
  </Box>
);

export default NotFound;
