import React, { useState } from 'react';
import styled from '@emotion/styled';
import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography";
import ArrowBack from '@material-ui/icons/ArrowBack';
import Colors from '../../../theme/Colors';
import { TextField } from "../../../components/TextField";
import { useMutation } from 'react-apollo';
import { ADICIONAR_CARTAO_CREDITO } from '../../../graphql/mutations';
import moment from "moment";
import Notification from '../../../components/notification/Notification';
import string from '../../../utils/string';
import InputDateForm from '../../../components/input/InputDateForm';

const notificationDefault = {
	variant: '',
	message: '',
	isOpen: false,
};

const MeioDeCobranca = ({ venda, onClose, onCreate }) => {
  const [notification, setNotification] = useState({
		...notificationDefault,
	});
  const [formData, setFormData] = useState({
    expirationDate: null,
  });
	const [formError, setFormError] = useState({});
  const [salvando, setSalvando] = useState(false);
	const [adicionarCartaoCredito] = useMutation(ADICIONAR_CARTAO_CREDITO);

	const handleSubmit = async e => {
    e.preventDefault();
		try {
      setSalvando(true);

      let cartaoCredito;
			let cartaoCreditoErrors = {};
      let emptyCreditCard = false;
      if (string.isEmpty(formData.cardNumber) || string.isEmpty(formData.holder) || !formData.expirationDate || string.isEmpty(formData.securityCode)) {
        emptyCreditCard = true;

      }

      if (string.isEmpty(formData.cardNumber)) {
        cartaoCreditoErrors = {
          ...cartaoCreditoErrors,
          cardNumber: {
            error: true,
            message: 'Campo obrigatório.'
          }
        };
      }

      if (string.isEmpty(formData.holder)) {
        cartaoCreditoErrors = {
          ...cartaoCreditoErrors,
          holder: {
            error: true,
            message: 'Campo obrigatório.'
          }
        };
      }

      if (!formData.expirationDate) {
        cartaoCreditoErrors = {
          ...cartaoCreditoErrors,
          expirationDate: {
            error: true,
            message: 'Campo obrigatório.'
          }
        };
      }

      if (string.isEmpty(formData.securityCode)) {
        cartaoCreditoErrors = {
          ...cartaoCreditoErrors,
          securityCode: {
            error: true,
            message: 'Campo obrigatório.'
          }
        };
      }

      if (emptyCreditCard) {
        setNotification({
          variant: 'error',
          isOpen: true,
          message: 'Por favor, preencha os campos do cartão de crédito.',
        });
        setFormError({
          ...formError,
          ...cartaoCreditoErrors,
        });
        setSalvando(false);
        return;
      }

      cartaoCredito = {
        cardNumber: string.removeSpecialChars(formData.cardNumber),
        holder: formData.holder,
        expirationDate: formData.expirationDate ? moment(formData.expirationDate).startOf('month').format('YYYY-MM-DD') : undefined,
        securityCode: formData.securityCode,
      };

      await adicionarCartaoCredito({
				variables: {
					cartaoCredito,
					vendaId: venda.id
				}
			});

      onCreate && onCreate();
      handleClose();
    } catch ({graphQLErrors}) {
      setSalvando(false);
      if (graphQLErrors) {
				setNotification({
					variant: 'error',
					isOpen: true,
					message: graphQLErrors[0].message,
				});
			}
    }
	};

  const handleClose = () => {
    onClose && onClose();
  }

  const renderTextField = (label, field, mask) => (
    <>
      <Typography color="primary" component="label">
        {label}
      </Typography>
      <TextField
        value={formData[field]}
        mask={mask}
				error={formError[field]?.error || undefined}
				helperText={formError[field]?.message || undefined}
        onChange={e => {
					setFormData({
						...formData,
						[field]: e.target.value,
					});

					if (!string.isEmpty(e.target.value)) {
						setFormError({
							...formError,
							[field]: null,
						});
					}
				}}
      />
    </>
  );

  return (
    <Container>
      <Header>
        <ArrowBackStyled onClick={handleClose} />
        <HeaderTitle>Adicionar Meio de Cobrança</HeaderTitle>
      </Header>
      <Form onSubmit={handleSubmit}>
				<Grid container direction="row" spacing={2}>
          <Grid item xs={12}>
						<FormHeading>Cartão de Crédito</FormHeading>
					</Grid>
					<FormGroup item xs={6}>
						{renderTextField('Nome no Cartão de Crédito', 'holder')}
					</FormGroup>
					<FormGroup item xs={6}>
						{renderTextField('Número do Cartão de Crédito', 'cardNumber', 'creditCard')}
					</FormGroup>
					<FormGroup item xs={6}>
						{renderTextField('Código de Segurança', 'securityCode')}
					</FormGroup>
					<FormGroup item xs={6}>
						<Typography color="primary" component="label">
							Data de Validade
						</Typography>
						<InputDateForm
							variant="outlined"
							fullWidth
							openTo="month"
							views={["year", "month"]}
							value={formData.expirationDate}
							onChange={(e) => {
								setFormData({
									...formData,
									expirationDate: e,
                });

                if (e) {
                  setFormError({
                    ...formError,
                    expirationDate: null,
                  });
                }
              }}
              error={formError?.expirationDate?.error || undefined}
							helperText={formError?.expirationDate?.message || undefined}
							format="MM/YYYY"
							placeholder={"__/____"}
							mask={value => (value ? [/\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/] : [])}
						/>
					</FormGroup>
          <FormGroup item xs={12}>
            <ButtonsContainer>
              <Button type="submit" disabled={salvando}>{salvando ? 'Salvando...' : 'Salvar'}</Button>
            </ButtonsContainer>
          </FormGroup>
        </Grid>
      </Form>
      <Notification
					close={() => {
						setNotification({
							...notificationDefault,
						});
					}}
					reset={() => {
						setNotification({
							...notificationDefault,
						});
					}}
					isOpen={notification.isOpen}
					variant={notification.variant}
					message={notification.message}
      />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  padding: 10px 20px;
`;

const Header = styled.div`
  height: 45px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;
`;

const HeaderTitle = styled.div`
  font-size: 18px;
  text-align: left;
  color: ${Colors.primary.main};
  margin-top: 4px;
  margin-left: 10px;
`;

const ArrowBackStyled = styled(ArrowBack)`
  color: ${Colors.primary.main};
  cursor: pointer;
`;

const Form = styled.form`
  width: 96%;
  display: flex;
`;

const FormHeading = styled.h3`
	color: ${Colors.commons.gray5};
	margin: 15px 0 0;
`;

const FormGroup = styled(Grid)`
	margin-bottom: 15px !important;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const Button = styled.button`
  border: 0;
  background-color: ${Colors.primary.main};
  color: #fff;
  padding: 5px 10px;
  box-sizing: border-box;
  height: 34px;
  min-width: 150px;
  border-radius: 6px;
  margin: 8px 0;
  cursor: pointer;
`;

export default MeioDeCobranca;
