import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled'
import {useLazyQuery} from 'react-apollo';
import { useParams } from 'react-router-dom';
import CircularProgress from "@material-ui/core/CircularProgress";
import Notification from '../../../components/notification/Notification';
import TextFieldCheckout from '../../../components/TextField/TextFieldCheckout';
import pessoaFazendoLogin from '../../../assets/images/pessoaFazendoLogin.svg';
import {GET_VENDA_CLIENTE_PAGAMENTO_ID} from '../../../graphql/queryes';
import string from '../../../utils/string';

const notificationDefault = {
	variant: '',
	message: '',
	isOpen: false,
};

const VerificarAcesso = ({handleIrAoCheckout, handleAtualizarDados }) => {
	const [notification, setNotification] = useState({
		...notificationDefault,
	});

  const handleSubmitVerificarAcesso = async () => {
    await getVendaClientePagamentoId(vendaId, loginCpf);
  };

  const {vendaId} = useParams();
  const [loginCpf, setLoginCpf] = useState('');

  const [loadGetVendaClientePagamentoId, {loading, error, data: dadosVendaPagamento}] = useLazyQuery(GET_VENDA_CLIENTE_PAGAMENTO_ID);

  const getVendaClientePagamentoId = async (vendaId, loginCpf) => {
    await loadGetVendaClientePagamentoId({
      variables: {
        id: vendaId,
        cpf: string.removeSpecialChars(loginCpf)
      }
    });
  }

  const handleChangeCpfLoginField = (value) => {
    setLoginCpf(value);
  };

  useEffect(() => {
    if (dadosVendaPagamento) {
      handleAtualizarDados(dadosVendaPagamento?.findByIdVendaClientePagamento,  string.removeSpecialChars(loginCpf));
      handleIrAoCheckout && handleIrAoCheckout();
    }
    // eslint-disable-next-line
  }, [dadosVendaPagamento, loading]);

  useEffect(() => {
    if (error) {
      setNotification({
        variant: 'error',
        isOpen: true,
        message: 'Venda não encontrada, verifique o CPF ou contate o suporte.',
      });
    }
    // eslint-disable-next-line
  }, [error]);

	return (
		<PageContainer>
      <BackgroundImage>
        <BackgroundColor>
          <PessoaFazendoLogin src={pessoaFazendoLogin} alt="Pessoa fazendo login" />
        </BackgroundColor>
      </BackgroundImage>
      <Panel>
        <Form>
          <Titulo>
            Login
          </Titulo>
          <Subtitulo>
            Para proceder ao pagamento utilize o seu CPF para conceder acesso ao pagamento.
          </Subtitulo>

          <TextFieldCheckout
            label={'Coloque o CPF:'}
            field={loginCpf}
            mask={'cpf'}
            maxLength={5}
            handleChange={(e) => handleChangeCpfLoginField(e.target.value)}
          />

          <SubmitForm onClick={handleSubmitVerificarAcesso} disabled={loading}>
            Ir ao Checkout
            {loading && (
              <CircularProgressStyled
                color="inherit"
                size={12}
              />
            )}
          </SubmitForm>

        </Form>
      </Panel>
      <Notification
				close={() => {
					setNotification({
						...notificationDefault,
					});
				}}
				reset={() => {
					setNotification({
						...notificationDefault,
					});
				}}
				isOpen={notification.isOpen}
				variant={notification.variant}
				message={notification.message}
			/>
		</PageContainer>
	)
};

const PageContainer = styled.div`
	display: flex;
  width: 100%;
  height: 100vh;
	justify-content: center;
	align-items: center;
  @media(max-width: 900px) {
    flex-direction: column;
    overflow: hidden scroll;
  }
`;

const Panel = styled.div`
	display: flex;
  flex-direction: column;
  width: 50%;
  justify-content: center;
  align-items: center;
`;

const BackgroundImage = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 100%;
  background-color: #EBEBEB;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.03), 0px 7px 25px rgba(38, 172, 169, 0.08), 0px 5px 25px rgba(38, 172, 169, 0.07);
  @media(max-width: 900px) {
    width: 100%;
    flex-direction: column;
    height: 80%
  }
`;

const BackgroundColor = styled.div`
  display: flex;
  justify-content: center;
  background-color: #219A97;
  width: 100%;
  height: 80vh;
`;

const Form = styled.div`
  max-width: 400px;
  display: flex;
  flex-direction: column;
`;

const Titulo = styled.h1`
  font-family: 'Poppins';
  font-weight: 800;
  font-size: 54px;
  color: #505050;
  margin-bottom: 18px;
`;

const Subtitulo = styled.h2`
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 24px;
  color: #505050;
  margin-bottom: 18px;
`;

const PessoaFazendoLogin = styled.img`
  width: 40vw;
  margin-top: 10vh;
  height: 90vh;
  @media(max-width: 900px) {
    height: 50%;
    width: 100vw;
    margin-top: 30vh;
  }
`;

const SubmitForm = styled.button`
	color: #fff;
	border: none;
	outline: none;
	cursor: pointer;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;

  width: 285px;
  height: 56px;
  background: #219A97;

  box-shadow: 12px 16px 24px rgba(0, 0, 0, 0.1);
  border-radius: 100px;
  margin: 0px 18px 18px 18px;
  @media(max-width: 900px) {
    width: 220px;
  }
`;

const CircularProgressStyled = styled(CircularProgress)`
	margin-left: 10px;
`;

export default (VerificarAcesso);
