import Grid from "@material-ui/core/Grid";
import React from "react";
import TextFieldEntidade from "./textFieldEntidade";
import SelectSearchEntidade from "./selectSearchEntidade";
import {
  FormHeading,
  FormGroup
} from "../constraints/constantesEntidade";
import {useQuery} from "react-apollo";
import {LIST_BANK_SIMPLE} from "../../../graphql/queryes";

const DadosBancarios = ({formEditavel, textFieldChange, selectFieldChange, dados}) => {

  const {data: dataBanks} = useQuery(LIST_BANK_SIMPLE);

  return (
    <>
      <Grid container direction="row" spacing={2}>
        <Grid item xs={12}>
          <FormHeading>Dados Bancários</FormHeading>
        </Grid>
        <FormGroup item xs={12} sm={12} md={4} lg={4}>
          <SelectSearchEntidade label={"Banco"} field={'banco'} value={dados?.banco} fieldChange={selectFieldChange}
                                elements={dataBanks?.findAllBanco?.content || []} formEditavel={formEditavel}/>
        </FormGroup>
        <FormGroup item xs={12} sm={12} md={3} lg={2}>
          <TextFieldEntidade label={'CNPJ'} field={'documentoBancario'} value={dados?.documentoBancario}
                             fieldChange={textFieldChange} mask={'cnpj'} formEditavel={formEditavel}/>
        </FormGroup>
        <FormGroup item xs={12} sm={12} md={2} lg={2}>
          <TextFieldEntidade label={'Agência'} field={'agencia'} value={dados?.agencia}
                             fieldChange={textFieldChange} mask={'agencia'} formEditavel={formEditavel}/>
        </FormGroup>
        <FormGroup item xs={12} sm={12} md={3} lg={4}>
          <TextFieldEntidade label={'Conta'} field={'conta'} value={dados?.conta}
                             fieldChange={textFieldChange} mask={'conta'} formEditavel={formEditavel}/>
        </FormGroup>
      </Grid>
    </>
  )
};

export default DadosBancarios;
