import React, { useState, useEffect } from 'react';

import {
  useMutation,
  useApolloClient,
  useLazyQuery,
  useQuery,
} from 'react-apollo';
import {
  DROPDOWN_MUNICIPIOS,
  CONSULTA_CEP,
  DROPDOWN_TIPO_LOGRADOURO,
  LIST_ESPECIALIDADES_MEDICAS,
} from '../../../graphql/queryes';

import Colors from '../../../theme/Colors';

import styled from '@emotion/styled';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Close from '@material-ui/icons/Close';
import MobileStepper from '@material-ui/core/MobileStepper';
import Slider from '@material-ui/core/Slider';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';

import RenderTextField from '../../../components/TextField/RenderTextField';
import TextFieldSearch from '../../../components/TextField/TextFieldSearch';
import { TextField } from '../../../components/TextField';
import { ALTERAR_VENDA } from '../../../graphql/mutations';
import InputSearch from '../../../components/input/Input';
import SmallTable from '../../../components/Table/SmallTable/SmallTable';
import string from '../../../utils/string';

const EditarContratante = ({ venda, onClose, onSave }) => {
  const [passoAtual, setPassoAtual] = useState(0);
  const [formData, setFormData] = useState({
    codigoCnes: venda.contratante?.codigoCnes,
    cnpj: venda.contratante?.cnpj,
    email: venda.contratante?.email,
    haveraMigracaoSistema: venda.contratante?.haveraMigracaoSistema,
    inscricaoEstadual: venda.contratante?.inscricaoEstadual,
    nomeFantasia: venda.contratante?.nomeFantasia,
    numeroPacientes: venda.contratante?.numeroPacientes,
    razaoSocial: venda.contratante?.razaoSocial,
    responsavelCargo: venda.contratante?.responsavelCargo,
    responsavelCpf: venda.contratante?.responsavelCpf,
    responsavelNome: venda.contratante?.responsavelNome,
    sistemaASerMigrado: venda.contratante?.sistemaASerMigrado,
    telefone: venda.contratante?.telefone,
    tempoFuncionamentoEmpresa: venda.contratante?.tempoFuncionamentoEmpresa,
    especialidades: venda.contratante?.especialidades,
    cep: venda.contratante?.endereco?.cep,
    bairro: venda.contratante?.endereco?.bairro,
    municipio: venda.contratante?.endereco?.municipio,
    numero: venda.contratante?.endereco?.numero,
    nomeLogradouro: venda.contratante?.endereco?.nomeLogradouro,
    complemento: venda.contratante?.endereco?.complemento,
    tipoLogradouro: venda.contratante?.endereco?.tipoLogradouro,
    estado: venda.contratante?.endereco?.estado,
  });
  const client = useApolloClient();
  const [especialidades, setEspecialidades] = useState(
    formData.especialidades || []
  );
  const [updateContratante] = useMutation(ALTERAR_VENDA);
  const [getConsultaCep, { data: dadosConsultaCep }] = useLazyQuery(
    CONSULTA_CEP
  );
  const [search, setSearch] = useState('');

  const { data } = useQuery(LIST_ESPECIALIDADES_MEDICAS, {
    variables: {
      search: {
        search: search,
      },
      pageable: {
        pageSize: 53,
        pageNumber: 0,
      },
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    await updateContratante({
      variables: {
        venda: {
          id: venda.id,
          plano: {id: venda.plano?.id},
          contratante: {
            codigoCnes: formData.codigoCnes,
            cnpj: string.removeSpecialChars(formData.cnpj),
            email: formData.email,
            haveraMigracaoSistema: formData.haveraMigracaoSistema,
            inscricaoEstadual: formData.inscricaoEstadual,
            nomeFantasia: formData.nomeFantasia,
            numeroPacientes: formData.numeroPacientes,
            razaoSocial: formData.razaoSocial,
            responsavelCargo: formData.responsavelCargo,
            responsavelNome: formData.responsavelNome,
            responsavelCpf: string.removeSpecialChars(formData.responsavelCpf),
            sistemaASerMigrado: formData.sistemaASerMigrado,
            tempoFuncionamentoEmpresa: formData.tempoFuncionamentoEmpresa,
            especialidades: formData.especialidades.map((e) => ({
              id: e.value,
            })),
            telefone: string.removeSpecialChars(formData.telefone),
            endereco: {
              cep: string.removeSpecialChars(formData.cep),
              bairro: formData.bairro,
              municipio: {
                id: formData.municipio.value,
              },
              numero: formData.numero,
              estado: {
                id: formData.estado.value,
              },
              nomeLogradouro: formData.nomeLogradouro,
              complemento: formData.complemento,
              tipoLogradouro: {
                id: formData.tipoLogradouro.value,
              },
            },
          },
        },
      },
    });

    onSave && onSave();
    handleClose();
  };

  let proximoPasso = () => {
    setPassoAtual((prevPassoAtual) => prevPassoAtual + 1);
  };

  let voltar = () => {
    setPassoAtual((prevPassoAtual) => prevPassoAtual - 1);
  };

  const handleDelete = (especialidade) => {
    setEspecialidades(
      especialidades.filter((item) => item.value !== especialidade.value)
    );
    especialidade.isInvisivel = false;
  };

  const handleChangeHaveraMigracaoSistema = () => {
    setFormData({
      ...formData,
      haveraMigracaoSistema: !formData.haveraMigracaoSistema,
      sistemaASerMigrado: '',
    });
  };

  const handleClickTableItem = (item) => {
    if (!especialidades.includes(item) && item) {
      setEspecialidades([...especialidades, item]);

      item.isInvisivel = true;
    }
  };

  const handleClose = () => {
    onClose && onClose();
  };

  const handleLoadMoreOptions = async ({
    variables,
    query,
    responseObject,
  }) => {
    const response = await client.query({
      query,
      variables,
    });

    const pageNumber = variables?.searchDTO?.pageNumber || 0;

    let options = response?.data?.[responseObject]?.content || [];

    if (responseObject === 'findAllMunicipio') {
      options = options.map((o) => ({
        ...o,
        label: `${o.label} - ${o.uf}`,
      }));
    }

    return {
      options,
      hasMore: !response?.data?.[responseObject]?.last,
      additional: {
        page: pageNumber + 1,
      },
    };
  };

  const handleMunicipiosLoadOptions = async (
    search,
    loadedOptions,
    { page }
  ) => {
    return handleLoadMoreOptions({
      loadedOptions,
      variables: {
        searchDTO: {
          pageSize: 10,
          pageNumber: page,
          search,
        },
      },
      responseObject: 'findAllMunicipio',
      query: DROPDOWN_MUNICIPIOS,
    });
  };

  const handleTipoLogradouroLoadOptions = async (
    search,
    loadedOptions,
    { page }
  ) => {
    return handleLoadMoreOptions({
      loadedOptions,
      variables: {
        search: {
          pageSize: 10,
          pageNumber: page,
          search,
        },
      },
      responseObject: 'findAllTipoLogradouro',
      query: DROPDOWN_TIPO_LOGRADOURO,
    });
  };

  const handleChangeField = (campo, value) => {
    setFormData({
      ...formData,
      [campo]: value,
    });
  };

  const handleChangeTempoFuncionamento = (event, value) => {
    handleChangeField('tempoFuncionamentoEmpresa', value);
  };

  const handleChangeNumeroPacientes = (event, value) => {
    handleChangeField('numeroPacientes', value);
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  const consultaCep = async (cep) => {
    await getConsultaCep({
      variables: {
        cep: cep,
      },
    });
  };

  const isDadoContratanteInvalidos = () => {
    return (
      !formData.responsavelNome ||
      !string.validaTelefone(formData.telefone) ||
      formData.cep === undefined ||
      string.removeSpecialChars(formData.cep).length < 8 ||
      !formData.bairro ||
      !formData.nomeLogradouro ||
      !formData.municipio ||
      !formData.responsavelCargo ||
      !formData.responsavelCpf ||
      !formData.email ||
      formData.numeroPacientes === undefined ||
      !formData.nomeFantasia ||
      !formData.razaoSocial ||
      !formData.tipoLogradouro
    );
  };

  const renderTextField = (label, field, required) => {
    const error = required && !formData[field];
    return (
      <>
        <Typography color="primary" component="label">
          {required ? label + '*' : label}
        </Typography>
        <TextField
          value={formData[field]}
          onChange={(e) =>
            setFormData({
              ...formData,
              [field]: e.target.value,
            })
          }
          error={error}
          helperText={error && 'Campo obrigatório'}
        />
      </>
    );
  };

  const renderEspecilidade = (especialidade) =>
    !especialidade.isInvisivel && (
      <SmallTable.Row
        key={especialidade.value}
        onClick={() => handleClickTableItem(especialidade)}
      >
        <SmallTable.Data size={9} value={especialidade.label} noBorder />
      </SmallTable.Row>
    );

  const renderPhoneField = (label, field, required) => {
    const error = required && !string.validaTelefone(formData[field]);
    return (
      <>
        <Typography color="primary" component="label">
          {required ? label + '*' : label}
        </Typography>
        <TextField
          value={formData[field]}
          mask={'phone'}
          onChange={(e) =>
            setFormData({
              ...formData,
              [field]: e.target.value,
            })
          }
          error={error}
          helperText={error && 'Telefone inválido'}
        />
      </>
    );
  };

  const renderCEPField = (label, field, required) => {
    const error =
      required && string.removeSpecialChars(formData.cep)?.length < 8;

    const handleChangeCep = (e) => {
      const cpfSemMascara = string.removeSpecialChars(e.target.value);
      setFormData({
        ...formData,
        [field]: e.target.value,
      });

      if (cpfSemMascara.length === 8) {
        consultaCep(cpfSemMascara);
      }
    };

    return (
      <>
        <Typography color="primary" component="label">
          {required ? label + '*' : label}
        </Typography>
        <TextField
          value={formData[field]}
          mask={'cep'}
          onChange={(e) => handleChangeCep(e)}
          error={error}
          helperText={error && 'CEP inválido'}
        />
      </>
    );
  };

  const renderButton = (label, handleFunction, disabled) => (
    <>
      <Button type="button" disabled={disabled} onClick={handleFunction}>
        {label}
      </Button>
    </>
  );

  const renderTextFieldSearch = (label, field, loadOptions) => (
    <>
      <Typography color="primary" component="label">
        {label}
      </Typography>
      <TextFieldSearch
        placeholder=""
        loadOptions={loadOptions}
        withPaginate
        value={formData[field]}
        onChange={(e) =>
          setFormData({
            ...formData,
            [field]: e,
          })
        }
        debounceTimeout={300}
        additional={{
          page: 0,
        }}
      />
    </>
  );

  useEffect(() => {
    if (dadosConsultaCep?.consultaDeCep) {
      setFormData({
        ...formData,
        nomeLogradouro: dadosConsultaCep?.consultaDeCep?.logradouro,
        bairro: dadosConsultaCep?.consultaDeCep?.bairro,
        tipoLogradouro: {
          ...dadosConsultaCep?.consultaDeCep?.tipoLogradouro,
          label: `${
            dadosConsultaCep.consultaDeCep?.tipoLogradouro?.label || '-'
          }`,
        },
        municipio: {
          ...dadosConsultaCep.consultaDeCep?.municipio,
          label: `${
            dadosConsultaCep.consultaDeCep?.municipio?.label || '-'
          } - ${dadosConsultaCep.consultaDeCep?.municipio?.uf || '-'}`,
        },
        estado: {
          ...dadosConsultaCep.consultaDeCep?.municipio.estado,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dadosConsultaCep]);

  useEffect(() => {
    proximoPasso();
    voltar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passoAtual]);

  return (
    <FlexGrid direction="row" spacing={2}>
      <Header>
        <CloseStyled onClick={handleClose} />
        <HeaderTitle>Alterar contratante</HeaderTitle>
      </Header>
      <Passos item xs={11} sm={11} md={11} ls={11}>
        {passoAtual === 0 && (
          <Grid container direction="row" spacing={2}>
            <FormGroup item xs={12}>
              {renderTextField('CNPJ', 'cnpj', false)}
            </FormGroup>
            <FormGroup item xs={12}>
              {renderTextField(
                'Nome do Responsável *',
                'responsavelNome',
                true
              )}
            </FormGroup>
            <FormGroup item xs={12}>
              {renderTextField('CPF', 'responsavelCpf', true)}
            </FormGroup>
            <FormGroup item xs={12}>
              {renderTextField('Cargo', 'responsavelCargo', true)}
            </FormGroup>
          </Grid>
        )}
        {passoAtual === 1 && (
          <Grid container direction="row" spacing={2}>
            <FormGroup item xs={12}>
              {renderTextField('Razão Social', 'razaoSocial', true)}
            </FormGroup>
            <FormGroup item xs={12}>
              {renderTextField('Nome Fantasia', 'nomeFantasia', true)}
            </FormGroup>
            <FormGroup item xs={12}>
              {renderTextField(
                'Inscrição Estadual',
                'inscricaoEstadual',
                false
              )}
            </FormGroup>
            <FormGroup item xs={12}>
              {renderPhoneField('Telefone', 'telefone', true)}
            </FormGroup>
            <FormGroup item xs={12}>
              {renderTextField('Código CNES', 'codigoCnes', false)}
            </FormGroup>
            <FormGroup item xs={12}>
              {renderTextField('Email', 'email', true)}
            </FormGroup>
          </Grid>
        )}
        {passoAtual === 2 && (
          <Grid container direction="row" spacing={2}>
            <FormGroup item xs={12}>
              {renderCEPField('CEP', 'cep', true)}
            </FormGroup>
            <FormGroup item xs={12}>
              {renderTextField('Número', 'numero', true)}
            </FormGroup>
            <FormGroup item xs={12}>
              {renderTextFieldSearch(
                'Tipo Logradouro',
                'tipoLogradouro',
                handleTipoLogradouroLoadOptions
              )}
            </FormGroup>
            <FormGroup item xs={12}>
              {renderTextField('Logradouro', 'nomeLogradouro', true)}
            </FormGroup>
            <FormGroup item xs={12}>
              {renderTextField('Bairro', 'bairro', true)}
            </FormGroup>
            <FormGroup item xs={12}>
              {renderTextField('Complemento', 'complemento', false)}
            </FormGroup>
            <FormGroup item xs={12}>
              {renderTextFieldSearch(
                'Municipio',
                'municipio',
                handleMunicipiosLoadOptions
              )}
            </FormGroup>
          </Grid>
        )}
        {passoAtual === 3 && (
          <Grid container direction={'row'}>
            <Header item xs={12} sm={12} md={12} ls={12}>
              Especialidade médica *
            </Header>
            <Grid item xs={12} sm={12} md={12} ls={12}>
              <InputSearch
                placeholder="Buscar por nome..."
                onChange={(event) => handleSearch(event)}
                value={search}
              />
            </Grid>
            <Grid2 item xs={12} sm={12} md={12} ls={12}>
              {especialidades.map((especialidade) => (
                <EspecialidadeSelecionada
                  key={especialidade.value}
                  label={especialidade.label}
                  onClick={() => handleDelete(especialidade)}
                  onDelete={() => handleDelete(especialidade)}
                  color={'primary'}
                />
              ))}
            </Grid2>

            <Grid item xs={12} sm={12} md={12} ls={12}>
              <SmallTable>
                <SmallTable.Head>
                  <SmallTable.HeadData
                    size={12}
                    value="Especialidade"
                    noBorder
                  />
                </SmallTable.Head>
                <SmallTable.Body>
                  {data?.findAllEspecialidadeMedica?.content?.map(
                    renderEspecilidade
                  )}
                </SmallTable.Body>
              </SmallTable>
            </Grid>
          </Grid>
        )}
        {passoAtual === 4 && (
          <Grid container direction="row" spacing={2}>
            <Grid item xs={11} sm={11} md={11} ls={11}>
              <Typography color="primary" component="label">
                {'Tempo de funcionamento'}
              </Typography>
              <Slider
                defaultValue={0}
                aria-labelledby="discrete-slider"
                valueLabelDisplay="auto"
                step={5}
                marks={marksTempoFuncionamento}
                min={0}
                max={100}
                value={formData.tempoFuncionamentoEmpresa}
                onChange={handleChangeTempoFuncionamento}
              />
            </Grid>
            <Grid item xs={11} sm={11} md={11} ls={11}>
              <Typography color="primary" component="label">
                {'Numero de pacientes'}
              </Typography>
              <MySlider
                defaultValue={0}
                aria-labelledby="discrete-slider"
                valueLabelDisplay="auto"
                step={50}
                marks={marksNumeroPacientes}
                min={0}
                max={1000}
                value={formData.numeroPacientes}
                onChange={handleChangeNumeroPacientes}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} ls={12}>
              <Typography color="primary" component="label">
                {'Haverá migração de outro sistema?'}
              </Typography>
              <Checkbox
                checked={formData.haveraMigracaoSistema}
                onChange={() => handleChangeHaveraMigracaoSistema()}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} ls={12}>
              {formData.haveraMigracaoSistema === true && (
                <>
                  <Typography color="primary" component="label">
                    {'Sistema a ser migrado *'}
                  </Typography>
                  <RenderTextField
                    field={formData.sistemaASerMigrado}
                    errorText={'Este campo é obrigatório.'}
                    handleChange={(e) =>
                      handleChangeField('sistemaASerMigrado', e.target.value)
                    }
                  />
                </>
              )}
            </Grid>
          </Grid>
        )}
      </Passos>

      <FormGroup item xs={11}>
        <ButtonsContainer>
          <Guia
            variant="progress"
            steps={5}
            activeStep={passoAtual}
            position={'bottom'}
            backButton={renderButton('Voltar', voltar, passoAtual - 1 < 0)}
            nextButton={
              passoAtual < 4
                ? renderButton(
                    'Próximo',
                    proximoPasso,
                    isDadoContratanteInvalidos()
                  )
                : renderButton(
                    'Finalizar',
                    handleSubmit,
                    isDadoContratanteInvalidos()
                  )
            }
          />
        </ButtonsContainer>
      </FormGroup>
    </FlexGrid>
  );
};

export default EditarContratante;

const FlexGrid = styled(Grid)`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 10px 10px;
`;

const FormGroup = styled(Grid)`
  margin: 0 auto !important;
`;

const Passos = styled(FormGroup)`
  @media (max-height: 760px) {
    max-height: 500px;
    overflow: hidden scroll;
  }
`;

const Header = styled.div`
  height: 45px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;
`;

const HeaderTitle = styled.div`
  font-size: 18px;
  text-align: left;
  color: ${Colors.primary.main};
  margin-top: 4px;
  margin-left: 10px;
`;

const CloseStyled = styled(Close)`
  color: ${Colors.primary.main};
  cursor: pointer;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

const Button = styled.button`
  border: 0;
  background-color: ${Colors.primary.main};
  color: #fff;
  padding: 5px 10px;
  box-sizing: border-box;
  height: 34px;
  border-radius: 6px;
  margin: 8px 0;
  cursor: pointer;
  &:disabled {
    background-color: ${Colors.commons.gray};
  }
`;

const Guia = styled(MobileStepper)`
  width: 600;
  flex-grow: 1;
  align-self: center;
  justify-content: center;
`;

const EspecialidadeSelecionada = styled(Chip)`
  margin: 1px 2px !important;
`;

const Grid2 = styled(Grid)`
  margin: 10px 0 !important;
`;

const marksNumeroPacientes = [
  {
    value: 0,
    label: '0',
  },

  {
    value: 100,
    label: '100',
  },
  {
    value: 250,
    label: '250',
  },
  {
    value: 500,
    label: '500',
  },

  {
    value: 750,
    label: '750',
  },
  {
    value: 1000,
    label: '1000+',
  },
];

const marksTempoFuncionamento = [
  {
    value: 0,
    label: '0',
  },
  {
    value: 5,
    label: '5',
  },
  {
    value: 15,
    label: '15',
  },
  {
    value: 25,
    label: '25',
  },
  {
    value: 50,
    label: '50',
  },
  {
    value: 75,
    label: '75',
  },
  {
    value: 100,
    label: '100+',
  },
];

const MySlider = styled(Slider)`
  padding-right: 30px;
`;
