import React from 'react';
import styled from '@emotion/styled';
import Table from '../Table/table';

const List = ({
    columns = [],
    loading = true,
    items = [],
    last = false,
    error = false,
    onLoadMore,
    loadMoreText = 'Ver mais',
    loadingText = 'Carregando...',
    notFoundText = 'Nenhum item encontrado',
    onClickItem,
}) => {
    return (
        <>
            <Table columns={columns}>
                {
                    !loading && !error && items.map((item, itemIndex) => (
                        <tr
                            key={itemIndex}
                            style={{ cursor: onClickItem ? 'pointer' : undefined }}
                        >
                            {columns.map((column, index) => (
                                <td key={index} onClick={(e) => (column.onClick && column.onClick(item, e)) || (onClickItem && onClickItem(item))}>
                                    {typeof column.value === 'function' ? column.value(item) : column.value}
                                </td>
                            ))}
                        </tr>
                    ))
                }
            </Table>
            {
                loading && items && (
                    <LoadingContainer>
                        <LoadingText>{loadingText}</LoadingText>
                    </LoadingContainer>
                )
            }
            {
                !loading && items.length === 0 && (
                    <NotFoundContainer>
                        <NotFoundText>{notFoundText}</NotFoundText>
                    </NotFoundContainer>
                )
            }
            {
                !last && items.length > 0 && (
                    <LoadMoreContainer>
                        <LoadMoreButton onClick={onLoadMore}>
                            {loadMoreText}
                        </LoadMoreButton>
                    </LoadMoreContainer>
                )
            }
        </>
    );
};

const NotFoundContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;
const NotFoundText = styled.h3`
    color: #00B0AE;
    font-size: 1rem;
    font-weight: normal;
`;
const LoadingContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;
const LoadingText = styled.h3`
    color: #00B0AE;
    font-size: 1rem;
    font-weight: normal;
`;

const LoadMoreContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 15px 0;
`;

const LoadMoreButton = styled.button`
    background-color: #00C1BF;
    color: #ffffff;
    border: 0;
    padding: 0 15px;
    cursor: pointer;
    border-radius: 6px;
    height: 34px;
    min-width: 150px;
`;

export default List;
